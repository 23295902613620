import React, { Component, Fragment } from 'react'
import {
  Form,Nav, TabContent, TabPane, NavItem, Label, Button, FormGroup, Input, CustomInput, Modal, ModalBody, CardHeader, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, UncontrolledCollapse, Card
} from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import Loading from '../../../../components/Loading'
import config from '../../../../config.json';
import { ToastContainer,toast } from 'react-toastify';
import http from '../../../../httpService/httpService';
import autosize from 'autosize';

const initialState = {
    toggleBtn: '',
    conditionItem : [],
    apc:[],
    epc:[],
    loader :false,
    toggleFullPayment: false,
    toggleInhance:false,
    toggleAdvancePayment:false,
    type:'full_payment_conditions',
    token:localStorage.getItem('cccAuthToken'),
    checkedBtn : [],
    full_payemnt_data:[],
    cData:{ title:'',event_description:'',event_category_id:'',gender:'',status:'',id:''},
    errors :  { errTitle : ''},
    eventcategorytitle: 'Select Category',
    eventCategoryItem: []
};
class EditFullPaymentCondition extends Component {
  
  constructor(props) {
   super(props);
   }  

  state = initialState;

  componentWillMount() {
     
   if(this.props.fpd){
      this.setState({ initialState });
      const fpd = this.props.fpd;
      let full_payemnt_data  = [];
      const checkedBtn = [];
      const apc = [];
      const epc = [];

      fpd.map(f => 
          { full_payemnt_data.push({ 
            fid : f.condition_id , 
            advanced_payment : f.advanced_payment ,
            contract_id :f.contract_id,
            defination:f.defination,
            enhanced_max_age:f.enhanced_max_age,
            enhanced_max_percentage:f.enhanced_max_percentage,
            enhanced_max_pound:f.enhanced_max_pound,
            enhanced_payment:f.enhanced_payment,
            specified_cover:f.specified_cover,
            other_cover:f.other_cover,
            claim_payment_basis:f.claim_payment_basis,
            severity_payment_from:f.severity_payment_from,
            severity_payment_to:f.severity_payment_to,
            partial_payment_amount_percentage:f.partial_payment_amount_percentage,
            partial_payment_amount_pound:f.partial_payment_amount_pound,
            is_capped:f.is_capped,
            additional_payment_amount_percentage:f.additional_payment_amount_percentage,
            additional_payment_amount_pound:f.additional_payment_amount_pound,
            additional_is_capped:f.additional_is_capped,
            id:f.id
          })

          checkedBtn.push(parseInt(f.condition_id));
          if(f.advanced_payment == 'y'){
            apc.push(f.condtions.title);
          }
          if(f.enhanced_payment == 'y'){
            epc.push(f.condtions.title);
          }
          
        }

      )
      
      this.setState({ full_payemnt_data });      
      this.props.setEditFullConditionData(full_payemnt_data);
      this.setState({ checkedBtn });  
      this.setState({ apc });  
      this.setState({ epc });  
    }
  }

  componentWillReceiveProps(nextProps){     

    if(nextProps.conditions && nextProps.conditions.type == 'FULL_PAYMENT_LIST'){
      const cc = nextProps.conditions.data;
      const conditionItem = cc.sort((a, b) => a.title.localeCompare(b.title))
      this.setState({ conditionItem });   
      autosize(document.querySelectorAll('textarea'));  
    }

    if(nextProps.fpd){
      this.setState({  initialState });
      const fpd = nextProps.fpd;
      let full_payemnt_data  = [];
      const checkedBtn = [];
      const apc = [];
      const epc = [];
      
      fpd.map(f => 
        { 

        full_payemnt_data.push({ 
          fid : f.condition_id , 
          advanced_payment : f.advanced_payment ,
          contract_id :f.contract_id,
          defination:f.defination,
          enhanced_max_age:f.enhanced_max_age,
          enhanced_max_percentage:f.enhanced_max_percentage,
          enhanced_max_pound:f.enhanced_max_pound,
          enhanced_payment:f.enhanced_payment,
          specified_cover:f.specified_cover,
          other_cover:f.other_cover,
          claim_payment_basis:f.claim_payment_basis,
          severity_payment_from:f.severity_payment_from,
          severity_payment_to:f.severity_payment_to,
          partial_payment_amount_percentage:f.partial_payment_amount_percentage,
          partial_payment_amount_pound:f.partial_payment_amount_pound,
          is_capped:f.is_capped,
          additional_payment_amount_percentage:f.additional_payment_amount_percentage,
          additional_payment_amount_pound:f.additional_payment_amount_pound,
          additional_is_capped:f.additional_is_capped,
          id:f.id
        })

        checkedBtn.push(parseInt(f.condition_id));
        if(f.advanced_payment == 'y'){
          apc.push(f.condtions.title);
        }
        if(f.enhanced_payment == 'y'){
          epc.push(f.condtions.title);
        }

        }
      )
      this.props.setEditFullConditionData(full_payemnt_data);
      this.setState({ full_payemnt_data });
      this.setState({ checkedBtn });  
      this.setState({ apc });  
      this.setState({ epc }); 

    }
    
  }

  async componentDidMount(){

    await http.post(process.env.REACT_APP_API_URL+'/get-conditions-lists', { type :this.state.type },{
      headers: {
        'Authorization': `Bearer ${this.state.token}`
      }
    })
    .then((response) => { 
      const conditionItem = response.data.data;
      this.setState( { conditionItem });
      autosize(document.querySelectorAll('textarea'));
    })
    .catch((error) => {
      if(error.response && error.response.status != 200){
         // toast.error(error.response.data.message);
      }
    });


      const token = localStorage.getItem('cccAuthToken');
      //Get Event Category
      await http.get(process.env.REACT_APP_API_URL + '/get-event-categories', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        const category = response.data.data;
        this.setState({'eventCategoryItem': category})
      });


    
  }

  handleChecked = (id) => {
    const checkedBtn = this.state.checkedBtn;

    if(checkedBtn.includes(id)){      
      const filteredBtn= checkedBtn.filter(item => item !== id)
      this.setState({ checkedBtn: filteredBtn })      
      
    }else{
      
      const filteredBtn = [...this.state.checkedBtn,id];
      this.setState({ checkedBtn: filteredBtn })      
    }    
  }

  toggleDiv = (id) => {
    
    const checkedBtn = this.state.checkedBtn;
    if(this.state.toggleBtn == id){
      this.setState({ toggleBtn: ''})
    }else{
      if(checkedBtn.includes(id)){
        this.setState({ toggleBtn: id})
      }
    }
    
      
  }

  handleFulClick = (e,id,field) =>{
    let full_payemnt_data  = [];
    if(field == 'fpc'){
     
      if(e.target.checked == true){
        full_payemnt_data = this.state.full_payemnt_data.concat({ fid : id});
        this.setState({ full_payemnt_data });
      }else{
        const ofl = this.state.full_payemnt_data;
        full_payemnt_data = ofl.filter(fl => fl.fid != id);
        this.setState({ full_payemnt_data });
      }
      
    }

    if(field == 'epc'){
      full_payemnt_data = this.state.full_payemnt_data;
      const fpc = full_payemnt_data.find(fl => fl.fid == id);    
      
      if(fpc){
        const index = full_payemnt_data.indexOf(fpc);
        full_payemnt_data[index]={...fpc,enhanced_payment : e.target.checked == true ? 'y' :'n' };
        this.setState({ full_payemnt_data });
      }
      
    }

    if(field == 'apc'){
      full_payemnt_data = this.state.full_payemnt_data;
      const fpc = full_payemnt_data.find(fl => fl.fid == id);   
      
      if(fpc){
        const index = full_payemnt_data.indexOf(fpc);
        full_payemnt_data[index]={...fpc,advanced_payment : e.target.checked == true ? 'y' :'n' };
        this.setState({ full_payemnt_data });
      }
    }

    if(field == 'scc'){
      full_payemnt_data = this.state.full_payemnt_data;
      const fpc = full_payemnt_data.find(fl => fl.fid == id);   
      
      if(fpc){
        const index = full_payemnt_data.indexOf(fpc);
        full_payemnt_data[index]={...fpc,specified_cover : e.target.checked == true ? 'y' :'n','other_cover':'n' };
        this.setState({ full_payemnt_data });
      }
    }

    if(field == 'ccboh'){
      full_payemnt_data = this.state.full_payemnt_data;
      const fpc = full_payemnt_data.find(fl => fl.fid == id);   
      
      if(fpc){
        const index = full_payemnt_data.indexOf(fpc);
        full_payemnt_data[index]={...fpc,other_cover : e.target.checked == true ? 'y' :'n','specified_cover':'n' };
        this.setState({ full_payemnt_data });
      }
    }

    if(field == 'full'){
      full_payemnt_data = this.state.full_payemnt_data;
      const fpc = full_payemnt_data.find(fl => fl.fid == id);    
      
      if(fpc){
        const index = full_payemnt_data.indexOf(fpc);
        full_payemnt_data[index]={...fpc,claim_payment_basis : 'full' };
        this.setState({ full_payemnt_data });
      }
      
    }

    if(field == 'severity'){

      full_payemnt_data = this.state.full_payemnt_data;
      const fpc = full_payemnt_data.find(fl => fl.fid == id);    
      
      if(fpc){
        const index = full_payemnt_data.indexOf(fpc);
        full_payemnt_data[index]={...fpc,claim_payment_basis : 'severity' };
        this.setState({ full_payemnt_data });
      }
      
    }

    if(field == 'partial'){
      full_payemnt_data = this.state.full_payemnt_data;
      const fpc = full_payemnt_data.find(fl => fl.fid == id);    
      
      if(fpc){
        const index = full_payemnt_data.indexOf(fpc);
        full_payemnt_data[index]={...fpc,claim_payment_basis : 'partial' };
        this.setState({ full_payemnt_data });
      }
      
    }

    if(field == 'additional'){
      full_payemnt_data = this.state.full_payemnt_data;
      const fpc = full_payemnt_data.find(fl => fl.fid == id);    
      
      if(fpc){
        const index = full_payemnt_data.indexOf(fpc);
        full_payemnt_data[index]={...fpc,claim_payment_basis : 'additional' };
        this.setState({ full_payemnt_data });
      }
      
    }

    this.props.setEditFullConditionData(full_payemnt_data);
  }

  ApcEpcChange = (e,type,value) =>{
    if(e.target.checked == true){
      if(type == 'epc'){
        const epc = [...this.state.epc,value];
        this.setState({epc});
      }
      if(type == 'apc'){
        const apc = [...this.state.apc,value];
        this.setState({apc});
      }
      
    }else{
      if(type == 'epc'){
        const ec= this.state.epc;
        const epc = ec.filter(ep => ep != value)
        this.setState({epc});
      }
      if(type == 'apc'){
        const ac= this.state.apc;
        const apc = ac.filter(ac => ac != value)
        this.setState({apc});
      }
    }
  }

  handleFulChange = (e,id) =>{

    const { name , value  } = e.target;
    const full_payemnt_data = this.state.full_payemnt_data;
    const fpc = full_payemnt_data.find(fl => fl.fid == id);  
    if(fpc){
      const index = full_payemnt_data.indexOf(fpc);
      full_payemnt_data[index][name] = value;
      this.setState({full_payemnt_data });
    }

    this.props.setEditFullConditionData(full_payemnt_data);
  }

  handleIsCapped = (type,id) =>{

    const full_payemnt_data = this.state.full_payemnt_data;
    const fpc = full_payemnt_data.find(fl => fl.fid == id);  
    if(fpc){
      const index = full_payemnt_data.indexOf(fpc);
      full_payemnt_data[index]['is_capped'] = (type == 'Capped' ? 'Yes':'No');
      this.setState({full_payemnt_data });
    }
    this.props.setEditFullConditionData(full_payemnt_data);

  }

  handleAdditionalIsCapped = (type,id) =>{

    const full_payemnt_data = this.state.full_payemnt_data;
    const fpc = full_payemnt_data.find(fl => fl.fid == id);  
    if(fpc){
      const index = full_payemnt_data.indexOf(fpc);
      full_payemnt_data[index]['additional_is_capped'] = (type == 'Capped' ? 'Yes':'No');
      this.setState({full_payemnt_data });
    }
    this.props.setEditFullConditionData(full_payemnt_data);

  }

  handleChange = (e) => {
    const { name , value  } = e.target;
    const cData = {...this.state.cData};
    cData[name]=value;
    this.setState({cData });
  }

  toggleAddConditionModal = () => {
    this.setState({
      addConditionModal: !this.state.addConditionModal
    });
    this.setState({ errors : { errTitle : ''} });
    this.setState({ 
      cData:{ title:'',id:''},
      eventcategorytitle: 'Select Category',
      gendertitle : 'Select Gender'
    });
  }

  validateCreateCondition = () => {
    const { cData  } = this.state;    
    const errors = {};

    
    if (!cData.title) {
      errors.errTitle = 'The condition needs a name';
    }
    if (!cData.event_category_id) {
      errors.errEventCategory = 'Please select event category';
    }
    if (!cData.gender) {
      errors.errGender = 'Please select gender Specific';
    }
    if (!cData.event_description) {
      errors.errEventDescription = 'Please enter event description';
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0 ? null : errors;
  }

  handleCreateCondition = e => {
    e.preventDefault();
    const { cData } = this.state;

    let title = cData.title;
    let event_category_id = cData.event_category_id;
    let gender = cData.gender;
    let event_description = cData.event_description;
    let type = this.state.type;

    if(this.validateCreateCondition() === null){
      this.setState({ loader:true });      
      http.post(process.env.REACT_APP_API_URL+'/create-condition', { title,event_category_id,gender,event_description, type },{
        headers: {
          'Authorization': `Bearer ${this.state.token}`
        }
      })
      .then((response) => { 
        this.setState({ loader:false });       
        toast.success(response.data.message);
         const cc = [...this.state.conditionItem,{
            title: response.data.data.title,
            event_description: response.data.data.event_description,
            event_category_id: response.data.data.event_category_id,
            gender: response.data.data.gender,
            id: response.data.data.id,
            status: response.data.data.status
         }];
         const conditionItem = cc.sort((a, b) => a.title.localeCompare(b.title))
        this.toggleAddConditionModal();
        this.setState({ conditionItem });
        this.props.setCondtionData(conditionItem)
        autosize(document.querySelectorAll('textarea'));   
      })
      .catch((error) => {
        this.setState({ loader:false });   
        if(error.response && error.response.status != 200){
           toast.error(error.response.data.message);
        }
      });
    }   
  }

  moveCaretAtEnd = (e) =>{
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`; 
  }


  handleChangeCategory = (e) => {
    const { value } = e.target;
    const cData = { ...this.state.cData };
    cData['event_category_id'] = value;
    this.setState({ cData });
    this.setState({ 'eventcategorytitle' : e.target.textContent })
  }

   handleChangeGender = (e) => {
    const { value } = e.target;
    const cData = { ...this.state.cData };
    cData['gender'] = value;
    this.setState({ cData });
    this.setState({ 'gendertitle' : value })
  }


  render() {   
    const { eventCategoryItem,eventcategorytitle,errors,conditionItem,apc,epc,loader,toggleFullPayment,toggleInhance,toggleAdvancePayment,toggleBtn } = this.state
    const fcd = this.state.full_payemnt_data.filter(fc => fc.specified_cover == 'y')
    return (
      <Fragment>
         {loader && <Loading />}
         <Card>
          <CardHeader>
            <Button id="editoggleFullPayment" className={classnames('card-link', { 'active': toggleFullPayment })} onClick={() => { this.setState({ toggleFullPayment: !toggleFullPayment }) }}>ADULT EVENTS ({ fcd.length })</Button>
          </CardHeader>
          <UncontrolledCollapse toggler="#editoggleFullPayment" className="inner-collapse">
            <div className="sub-title d-flex justify-content-end align-items-center">
              <Button onClick={this.toggleAddConditionModal}>Add condition to database</Button>
            </div>
            <Scrollbars className={classnames('scrollable-inner-accordion', { 'long-scroll': false })}
              renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
              renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
              renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
              renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
              <div id="edinewContractAccordion1" className="inner-accordion">
                { conditionItem.map(cl => {

                  const full_payemnt_data = this.state.full_payemnt_data;
                  let fpc = full_payemnt_data.find(fl => fl.fid == cl.id); 
                  
                  if(!fpc){                   
                    fpc = {
                        fid : '' , 
                        advanced_payment : '' ,
                        contract_id :'',
                        defination:'',
                        enhanced_max_age:'',
                        enhanced_max_percentage:'',
                        enhanced_max_pound:'',
                        enhanced_payment:'',
                        specified_cover:'',
                        other_cover:'',
                        id:'',
                        claim_payment_basis:'',
                        partial_payment_amount_percentage:'',
                        partial_payment_amount_pound:'',
                        severity_payment_from:'',
                        severity_payment_to:'',
                        is_capped:'',
                        additional_payment_amount_percentage:'',
                        additional_payment_amount_pound:'',
                        additional_is_capped:'',

                    }                    
                  }
                  
                  return <Card className={classnames({ 'active': (toggleBtn == cl.id ? true :false) })}>
                  <CardHeader>
                    <CustomInput type="checkbox" checked = { cl.id == fpc.fid ? 'checked': '' } onClick={ (e) => { this.handleChecked(cl.id); this.handleFulClick(e,cl.id,'fpc') } }  id={`editnewContractCheckHead1-`+cl.id} />
                    <Button id={`edittoggleFullPayment-`+cl.id} className={classnames('card-link', { 'active': toggleBtn == cl.id ? true :false }) } onClick={() => this.toggleDiv(cl.id)}>{ cl.title }</Button>
                  </CardHeader>
                  <UncontrolledCollapse  isOpen={ (toggleBtn == cl.id ? true :false) } toggler={`#edittoggleFullPayment-`+cl.id}>
                    <ul className="common-input-list mb-0">

                      <li className="d-flex justify-content-between align-items-center">
                        <Label className="form-check-label" htmlFor={`editFnewContractCheck1ss-`+cl.id}>Specific Condition Covered</Label>
                        <CustomInput type="radio" name={`efscc-`+cl.id} checked = { fpc.specified_cover == 'y'  ? 'checked': '' }  id={`editFnewContractCheck1ss-`+cl.id } checked={ fpc.specified_cover == 'y' ? 'checked' : '' } onClick = { (e) => { this.handleFulClick(e,cl.id,'scc') } }/>
                      </li>
                      <li className="d-flex justify-content-between align-items-center">
                        <Label className="form-check-label" htmlFor={`editFnewContractCheck1oc-`+cl.id}>Condition Covered By Other Heading</Label>
                        <CustomInput type="radio" name={`efscc-`+cl.id} checked = { fpc.other_cover == 'y'  ? 'checked': '' }  id={`editFnewContractCheck1oc-`+cl.id } checked={ fpc.other_cover == 'y' ? 'checked' : '' } onClick = { (e) => { this.handleFulClick(e,cl.id,'ccboh') } }/>
                      </li>

                      <li className="d-flex justify-content-between align-items-center claim-radio-group">
                          <Label className="form-check-label">Claim Payment Basis</Label>

                          <Label className="form-check-label" htmlFor={`cpb11-`+cl.id}>Full</Label>
                          <CustomInput type="radio" name={`cpb11-`+cl.id} id={`cpb11-`+cl.id} checked = { fpc.claim_payment_basis == 'full'  ? 'checked': '' } onClick = { (e) => {  this.handleFulClick(e,cl.id,'full') } }/>

                          <Label className="form-check-label" htmlFor={`cpb23-`+cl.id}>SEVERITY</Label>
                          <CustomInput   type="radio" name={`cpb11-`+cl.id} id={`cpb23-`+cl.id} checked = { fpc.claim_payment_basis == 'severity'  ? 'checked': '' } onClick = { (e) => {  this.handleFulClick(e,cl.id,'severity') } }/>

                          <Label className="form-check-label" htmlFor={`cpb33-`+cl.id}>PARTIAL</Label>
                          <CustomInput   type="radio" name={`cpb11-`+cl.id} id={`cpb33-`+cl.id} checked = { fpc.claim_payment_basis == 'partial'  ? 'checked': '' } onClick = { (e) => {  this.handleFulClick(e,cl.id,'partial') } }/>
                          
                          <Label className="form-check-label" htmlFor={`cpb43-`+cl.id}>ADDITIONAL</Label>
                          <CustomInput   type="radio" name={`cpb11-`+cl.id} id={`cpb43-`+cl.id} checked = { fpc.claim_payment_basis == 'additional'  ? 'checked': '' } onClick = { (e) => {  this.handleFulClick(e,cl.id,'additional') } }/>
                        </li>
                        { fpc.claim_payment_basis == 'partial' ? 
                        <li className="d-flex justify-content-between align-items-center border-input">
                          <Label>PARTIAL PAYMENT AMOUNT </Label>
                          <div className="d-flex is-capped flex-grow-1">
                          <Input type="text" className='dev-txt' value={ fpc.partial_payment_amount_percentage } name='partial_payment_amount_percentage' placeholder="%" onChange={ (e) => this.handleFulChange(e,cl.id) }/>
                          <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                            <DropdownToggle className="dropdown-toggle">{ fpc.is_capped ?  (fpc.is_capped == 'No' ? 'Uncapped' : 'Capped') : 'Choose An Option' }</DropdownToggle>
                            <DropdownMenu>
                              <Scrollbars className="scrollable-dropdown"
                                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />}
                                >
                                <DropdownItem  onClick={ (e) => this.handleIsCapped('Capped',cl.id) } value='Yes' >Capped</DropdownItem>                
                                <DropdownItem onClick={ (e) => this.handleIsCapped('Uncapped',cl.id) } value='No' >Uncapped</DropdownItem>                
                              </Scrollbars>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                          { fpc.is_capped == 'Yes' ?
                          <Input type="text" className='dev-txt' value={ fpc.partial_payment_amount_pound } name='partial_payment_amount_pound' placeholder="£" onChange={ (e) => this.handleFulChange(e,cl.id) }/> : '' }
                          </div>
                        </li> : '' }

                        { fpc.claim_payment_basis == 'additional' ? 
                        <li className="d-flex justify-content-between align-items-center border-input">
                          <Label>ADDITIONAL PAYMENT AMOUNT </Label>
                          <div className="d-flex is-capped flex-grow-1">
                          <Input type="text" className='dev-txt' value={ fpc.additional_payment_amount_percentage } name='additional_payment_amount_percentage' placeholder="%" onChange={ (e) => this.handleFulChange(e,cl.id) }/>
                          <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                            <DropdownToggle className="dropdown-toggle">{ fpc.additional_is_capped ?  (fpc.additional_is_capped == 'No' ? 'Uncapped' : 'Capped') : 'Choose An Option' }</DropdownToggle>
                            <DropdownMenu>
                              <Scrollbars className="scrollable-dropdown"
                                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />}
                                >
                                <DropdownItem  onClick={ (e) => this.handleAdditionalIsCapped('Capped',cl.id) } value='Yes' >Capped</DropdownItem>                
                                <DropdownItem onClick={ (e) => this.handleAdditionalIsCapped('Uncapped',cl.id) } value='No' >Uncapped</DropdownItem>                
                              </Scrollbars>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                          { fpc.additional_is_capped == 'Yes' ?
                          <Input type="text" className='dev-txt' value={ fpc.additional_payment_amount_pound } name='additional_payment_amount_pound' placeholder="£" onChange={ (e) => this.handleFulChange(e,cl.id) }/> : '' }
                          </div>
                        </li> : '' }

                        { fpc.claim_payment_basis == 'severity' ? 
                        <li className="d-flex justify-content-between align-items-center border-input">
                          <Label>SEVERITY PAYMENT RANGE </Label>
                          <Input type="text" value={ fpc.severity_payment_from } className='dev-txt' name='severity_payment_from' placeholder="%" onChange={ (e) => this.handleFulChange(e,cl.id) }/>
                           <Label>&nbsp;&nbsp; TO &nbsp;&nbsp;</Label>
                          <Input type="text" value={ fpc.severity_payment_to } className='dev-txt' name='severity_payment_to' placeholder="%" onChange={ (e) => this.handleFulChange(e,cl.id) }/>
                        </li> : '' }


                      <li className="d-flex justify-content-between align-items-center">
                        <Label className="form-check-label" htmlFor={`editFnewContractCheck1-`+cl.id}>enhanced payout condition</Label>
                        <CustomInput type="checkbox" checked = { fpc.enhanced_payment == 'y'  ? 'checked': '' }  id={`editFnewContractCheck1-`+cl.id } checked={ fpc.enhanced_payment == 'y' ? 'checked' : '' } onClick = { (e) => { this.ApcEpcChange(e,'epc',cl.title) ; this.handleFulClick(e,cl.id,'epc') } }/>
                      </li>
                      <li className="d-flex justify-content-between align-items-center">
                        <Label>MAXIMUM ENHANCED CLAIM AGE </Label>
                        <Input type="text" name='enhanced_max_age' value={ fpc.enhanced_max_age } onChange={ (e) => this.handleFulChange(e,cl.id) }/>
                      </li>
                      <li className="d-flex justify-content-between align-items-center">
                        <Label>MAXIMUM ENHANCED CLAIM AMOUNT(£)</Label>
                        <Input type="text" name='enhanced_max_pound' value={ fpc.enhanced_max_pound }  onChange={ (e) => this.handleFulChange(e,cl.id) }/>
                      </li>
                      <li className="d-flex justify-content-between align-items-center">
                        <Label>MAXIMUM ENHANCED CLAIM AMOUNT(%)</Label>
                        <Input type="text" name='enhanced_max_percentage' value={ fpc.enhanced_max_percentage }  onChange={ (e) => this.handleFulChange(e,cl.id) }/>
                      </li>
                      <li className="d-flex justify-content-between align-items-center">
                        <Label className="form-check-label" htmlFor={`editFnewContractCheck2-`+cl.id} >
                          ADVANCED PAYMENT CONDITION
                          </Label>
                        <CustomInput type="checkbox" checked = { fpc.advanced_payment == 'y'  ? 'checked': '' }  name='advanced_payment' id={`editFnewContractCheck2-`+cl.id} onClick = { (e) => { this.ApcEpcChange(e,'apc',cl.title); this.handleFulClick(e,cl.id,'apc') } } />
                      </li>
                      <li className="definition-input">
                        <Label>condition definition</Label>
                        <Scrollbars className="scrollable-textarea"
                          renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                          renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                          renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                          renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                          <Input type="textarea" rows={10} onFocus={this.moveCaretAtEnd}  value={ fpc.defination } name='defination' placeholder="You can write condition definition here...." onChange={ (e) => this.handleFulChange(e,cl.id) } />
                        </Scrollbars>
                      </li>
                    </ul>
                  </UncontrolledCollapse>
                </Card> }  
                )}                      
              </div>
            </Scrollbars>
          </UncontrolledCollapse>
        </Card>
            <Card>
              <CardHeader>
                <Button id="editoggleInhancEdit" className={classnames('card-link', { 'active': toggleInhance })} onClick={() => { this.setState({ toggleInhance: !toggleInhance }) }}>enhanced payment conditions ({ epc.length })</Button>
              </CardHeader>
              <UncontrolledCollapse toggler="#editoggleInhancEdit" className="inner-collapse">
                <div id="newContractAccordion2Edit" className>
                  <Scrollbars className="scrollable-simple-list"
                    renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                    renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                    renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                    renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                    <ul className="simple-list">
                       { epc.map(ep => <li>{ ep }</li>)}                      
                    </ul>
                  </Scrollbars>
                </div>
              </UncontrolledCollapse>
            </Card>  
            <Card>
              <CardHeader>
                <Button id="editoggleAdvancePaymentEdt" className={classnames('card-link', { 'active': toggleAdvancePayment })} onClick={() => { this.setState({ toggleAdvancePayment: !toggleAdvancePayment }) }}>waiting list advanced payments ({ apc.length })</Button>
              </CardHeader>
              <UncontrolledCollapse toggler="#editoggleAdvancePaymentEdt" className="inner-collapse">
                <div id="newContractAccordion2Edit" className>
                  <Scrollbars className="scrollable-simple-list"
                    renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                    renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                    renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                    renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                    <ul className="simple-list">
                      { apc.map(ap => <li>{ ap }</li>)}                           
                    </ul>
                  </Scrollbars>
                </div>
              </UncontrolledCollapse>
            </Card>

            <Modal isOpen={this.state.addConditionModal} toggle={this.toggleAddConditionModal}>
              <ModalBody>
              {loader && <Loading />}
                <Form method="post" onSubmit= { this.handleCreateCondition } id='createCondition'>
                  <div className="action-button d-flex justify-content-end">
                    <Button type="button" onClick={this.toggleAddConditionModal }>Abandon</Button>
                    <Button type="submit">SAVE & CLOSE</Button>
                  </div>
                  <div className="modal-body">
                    <FormGroup>
                      <Label for="conditionName"><em>Condition name</em></Label>
                      <Input type="text" id="title" name='title' onChange={(e) => { this.handleChange(e) }}/>
                      { errors.errTitle && <p className="error-text"> <em> { errors.errTitle } </em></p> }
                    </FormGroup>

                    <FormGroup>
                    <Label for="eventCat"><em>Event category</em></Label>
                    <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                      <DropdownToggle className="dropdown-toggle d-flex justify-content-between align-items-center">{this.state.eventcategorytitle}</DropdownToggle>
                      <DropdownMenu>
                        <Scrollbars className="scrollable-dropdown"
                          renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                          renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                          renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                          renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                          { eventCategoryItem.map(eventCat =>
                            <DropdownItem value={ eventCat.id} onClick={(e) => { this.handleChangeCategory(e) }} >{ eventCat.title }</DropdownItem>
                          )}                        
                          
                        </Scrollbars>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {errors.errEventCategory && <p className="error-text"> <em> {errors.errEventCategory} </em></p>}
                  </FormGroup>


                  <FormGroup>
                    <Label for="eventCat"><em>Gender Specific</em></Label>
                    <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                      <DropdownToggle className="dropdown-toggle d-flex justify-content-between align-items-center">{ this.state.gendertitle ?? 'Select Gender'}</DropdownToggle>
                      <DropdownMenu>
                        <Scrollbars className="scrollable-dropdown"
                          renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                          renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                          renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                          renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                        
                          <DropdownItem value='Male' onClick={(e) => { this.handleChangeGender(e) }}>Male</DropdownItem>
                          <DropdownItem value='Female' onClick={(e) => { this.handleChangeGender(e) }}>Female</DropdownItem>
                          <DropdownItem value='All' onClick={(e) => { this.handleChangeGender(e) }}>All</DropdownItem>
                          
                        </Scrollbars>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {errors.errGender && <p className="error-text"> <em> {errors.errGender} </em></p>}
                  </FormGroup>

                  <FormGroup>
                    <Label for="eventDesc"><em>Event description</em></Label>
                    <Input type="textarea" name="event_description" onChange={(e) => { this.handleChange(e) }} id="EventDescription" />
                    {errors.errEventDescription && <p className="error-text"> <em> {errors.errEventDescription} </em></p>}
                  </FormGroup>

                  </div>
                </Form>
              </ModalBody>
            </Modal>

      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {

  return {
    setEditFullConditionData:(data) => {  dispatch({ type: 'SET_EDIT_FULLPAYMENT_DATA', payload:{ data : data } }) },
    setCondtionData:(data) => {dispatch({ type: 'FULL_PAYMENT_LIST',payload:{ data : data } }) }
  }
}

const mapStateToProps = state => ({
  conditions: state.conditions
})
export default connect(mapStateToProps,mapDispatchToProps)(EditFullPaymentCondition)