export default (state={},action) => {
	switch (action.type) {
		case 'FULL_PAYMENT_LIST':
			return {
				data : action.payload.data,
				type: action.type
			}
		case 'ADDITIONAL_LIST':
			return {
				data : action.payload.data,
				type: action.type
			}
		case 'CHILD_SPECIFIC_LIST':
			return {
				data : action.payload.data,
				type: action.type
			}
		case 'FURTHER_LIST':
			return {
				data : action.payload.data,
				type: action.type
			}
		default:
      	 return state
	}

}