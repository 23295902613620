import React,{ Component } from 'react'
import { NavLink } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { verifyLength, verifyEmail ,verifyPassword } from '../../../helper';
import http from '../../../httpService/httpService'
import config from '../../../config.json';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../../components/Loading';

const defaultAccount = { email : '', password: '',password_confirmation: '',token:''}
const defaultErr =  { errPassword : '',errCnfmPassword : ''}

class ResetForm extends  Component{

  constructor(props) {
    super(props)
  }

  state = {
    account : defaultAccount,
    errors : defaultErr,
    loader : false,
  };

  validate = () => {
    const { account  } = this.state;    
    const errors = {};

    // Password Validation
    if(account.password == ''){
      errors.errPassword = 'Password is required field';
    }else if(!verifyPassword(account.password)){
      errors.errPassword = 'Please enter password in between 6 to 15 character';
    } 

    // Password Validation
    if(account.password_confirmation == ''){
      errors.errCnfmPassword = 'Confirm Password is required field';
    }else if(!verifyPassword(account.password_confirmation)){
      errors.errCnfmPassword = 'Please enter password in between 6 to 15 character';
    }else if(account.password !== account.password_confirmation){
        errors.errCnfmPassword = 'Password and  confirm password should be same';
    } 
    this.setState({ errors });

    return Object.keys(errors).length === 0 ? null : errors;

  }

  handleSubmit = e => {
    
    e.preventDefault();
    const { account } = this.state;
    const { token , email } = this.props.match.params;

    if(this.validate() === null){
        this.setState({ loader:true });
        let password = account.password;
        let password_confirmation = account.password_confirmation;

        http.post(process.env.REACT_APP_API_URL+'/reset-password', { email , password, token ,password_confirmation})
        .then( (response) => {    
          this.setState({ loader:false });      
          toast.success(response.data.message);
          this.setState({ account : defaultAccount , errors:defaultErr });
          document.getElementById('resetFrm').reset();
        })
        .catch( (error) => {
          this.setState({ loader:false });
          if(error.response && error.response.status != 200){
             toast.error(error.response.data.message);
          }
        });
    }
   
  }

  handleChange = (e) => {
    const { name , value  } = e.target;
    const account = {...this.state.account};
    account[name]=value;
    this.setState({account });
  }

  render(){
      const { errors ,loader} =  this.state;
      const { token , email } = this.props.match.params;
    
      return (
        <React.Fragment>
          {loader && <Loading />}
          <Form method="post" onSubmit= { this.handleSubmit } id='resetFrm'>
            <Input type="hidden" name="token" value={ token } />
            <Input type="hidden" name="email" value={ email } />       
            <FormGroup>
              <Label htmlFor="password">Password</Label>
              <Input type="password" id="password" name='password'  onChange={(e) => { this.handleChange(e) }}/>
              { errors.errPassword && <p className="error-text"> <em> { errors.errPassword } </em></p> }
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password_confirmation">Confirm Password</Label>
              <Input type="password" id="password_confirmation" name='password_confirmation' onChange={(e) => { this.handleChange(e) }}/>
              { errors.errCnfmPassword && <p className="error-text"> <em> { errors.errCnfmPassword }  </em></p> }       
            </FormGroup>
            <Button type="submit" className="theme-btn submit-btn" >Reset</Button>
            <p className="mb-0"><NavLink to={`${process.env.PUBLIC_URL}/login`}>Back To Login</NavLink></p>
          </Form>
         </React.Fragment>
      );
  }
}

export default ResetForm