import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import Home from '../views/Home/index'
import Login from '../views/Auth/Login/index'
import Forgot from '../views/Auth/Forgot/index'
import ResetPassword from '../views/Auth/ResetPasword/index'
import Search from '../views/Search/index.jsx'
import Record from '../views/Record/Record.jsx'
import AddRecord from '../views/Record/AddRecord.jsx'
import CSV from '../views/CSV/index.jsx'
import Landing from '../views/Home/Landing.jsx'

import PrivateRoutes from './PrivateRoutes'
import PublicRoute from './PublicRoutes'

const Routes = () => (
	<Switch>
		<PrivateRoutes routename="home" path={`${process.env.PUBLIC_URL}/`} component={Home} exact />
		<PrivateRoutes routename="comparison" path={`${process.env.PUBLIC_URL}/comparison`} component={Home} exact />
		<PrivateRoutes routename="comparison-user" path={`${process.env.PUBLIC_URL}/comparison/user`} component={Home} exact />
		<PublicRoute  routename="login" path={`${process.env.PUBLIC_URL}/login`} component={Login} exact />
		<PublicRoute routename="forgot-password" path={`${process.env.PUBLIC_URL}/forgot-password`} component={Forgot} exact />
		<PublicRoute routename="reset-password" path={`${process.env.PUBLIC_URL}/reset-password/:token/:email`} component={ResetPassword} exact />
		<PrivateRoutes routename="search" path={`${process.env.PUBLIC_URL}/search`} component={Search} exact />
		<PrivateRoutes routename="record" path={`${process.env.PUBLIC_URL}/record`} component={Record} exact />
		<PrivateRoutes routename="add-record" path={`${process.env.PUBLIC_URL}/add-record`} component={AddRecord} exact />
		<PrivateRoutes routename="csv" path={`${process.env.PUBLIC_URL}/csv`} component={CSV} exact />
		<PrivateRoutes routename="get-record" path={`${process.env.PUBLIC_URL}/get-record/:record_id`} component={Record} exact />		
		<Redirect to={`${process.env.PUBLIC_URL}/login`} />
	</Switch>
)

export default Routes