import React, { Component, Fragment } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import classnames from 'classnames';
import { Form,Button, FormGroup, Label, Input, CustomInput, Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Scrollbars } from 'react-custom-scrollbars';
import userIcon from '../../assets/images/user-icon.png';
import userWhiteIcon from '../../assets/images/user-white-icon.png';
import closeIcon from '../../assets/images/close-icon.png';
import Loading from '../../components/Loading'
import http from '../../httpService/httpService'
import { ToastContainer,toast } from 'react-toastify';
import { verifyLength, verifyEmail ,verifyPassword } from '../../helper';
const resetTimeout = (id, newID) => {
	
	clearTimeout(id)
	return newID
	
}

const initCust = { 
	id : '',
	title:'',
	first_name:'',
	last_name:'',
	dob:'',
	age:'',
	gender:'',
	nationality:'',
	uk_residency_status:'',
	uk_resident_since:'',
	personal_email:'',
	work_email:'',
	home_tel:'',
	work_tel:'',
	mob_tel:'',
	home_post_code:'',
	occupier_status:'',
	home_address_1:'',
	date_moved:'',
	since_birth:0 ,
	consent_to_mail:'n',
	consent_to_telephone:'n',
	consent_to_text:'n',
	consent_to_email:'n',
	record_id:''
};

const initialState ={
	account : { title:'',first_name:'',last_name:'',dob:''},
	errors :  { errtitle : '',errfirst_name : '',errlast_name : '',errdob : ''},
	ctitle_text: '',
}
class Record extends Component {
	constructor(props) {
		super(props)
		this.toggleAddContact = this.toggleAddContact.bind(this);
		this.toggleMarkrting = this.toggleMarkrting.bind(this);
		this.togglePersonal = this.togglePersonal.bind(this);
		this.state = {
			isAddContact: false,			
			isMarkrting: false,
			isPersonal: true,
			isReceived:false,
			loader : false,
			records : { id : '',lead_type:'',import_batch_no:'',import_custom_id:'',record_status:'',source:'',channel:'',advising_firm:'',assigned_agent:'',campaign:'',received_date:'',consent_to_mail:'n',consent_to_telephone:'n',consent_to_text:'n',consent_to_email:'n'},
			customers : [],
			custData: initCust,
			contactItem : 0,
			title_text:'',
			gender_text:'',
			nationality_text:'',
			uk_residency_status_text:'',
			occupier_status_text:'',
			country:[],
			timeout: null,
			saved: false,
			lead_type_text:'',
			record_status_text:'',
			source_text:'',
			channel_text:'',
			advising_firm_text:'',
			account : { title:'',first_name:'',last_name:'',dob:''},
			errors :  { errtitle : '',errfirst_name : '',errlast_name : '',errdob : ''},
			ctitle_text:'',
			suggested_contact : [],
			change_log : [],
			record_id:'',
			rec : 0,
			isSaved:true
		};
	}

	toggleContact = (item,custId) => {
		const isSaved = this.state.isSaved;
		if(isSaved == true){
			const contacts = this.state.customers;
			const custData = contacts.find(con => con.id == custId);
			
			if(custData['dob'] != ''){
		    	custData['age'] = this._calculateAge(custData['dob']);
		    }

			this.setState({ 
				contactItem : item,
				custData:custData,
				title_text : custData.title,
				gender_text : custData.gender,
				nationality_text : custData.nationalityName,
				uk_residency_status_text : custData.uk_residency_status,
				occupier_status_text : custData.occupier_status 
			});
		}		
	};
	toggleAddContact() {
		const currentState1 = this.state.isAddContact;
		this.setState({ isAddContact: !currentState1 });
	};	
	toggleMarkrting() {
		this.setState({ isMarkrting: true, isPersonal: false });
	};
	togglePersonal() {
		this.setState({ isMarkrting: false, isPersonal: true });
	};
	

	async componentDidMount() {
		this.setState({ loader : true })
		const { record_id } = this.props.match.params;
		const token = localStorage.getItem('cccAuthToken');
		await http.post(process.env.REACT_APP_API_URL + '/record-customers', { record_id }, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
		.then((response) => {

			this.setState({ change_log:response.data.data.changelog, records : response.data.data , customers : response.data.data.customers,loader : false, isReceived:true })
			this.setState({
				lead_type_text:response.data.data.lead_type,
				record_status_text:response.data.data.record_status,
				source_text:response.data.data.source,
				channel_text:response.data.data.channel,
				advising_firm_text:response.data.data.advising_firm
			})	
			const contacts =  response.data.data.customers;
			if(contacts.length > 0){
				const cData = contacts[0];
				if(cData['dob'] != ''){
			    	cData['age'] = this._calculateAge(cData['dob']);
			    }
				this.setState({ 
					custData : cData,
					title_text : cData.title,
					gender_text : cData.gender,
					nationality_text : cData.nationalityName,
					uk_residency_status_text : cData.uk_residency_status,
					occupier_status_text : cData.occupier_status
				})				
			}else{
				this.setState({ custData : initCust })
			}

		}).catch(error => {
			this.setState({ loader : false })
	        if(error.response && error.response.status != 200){
	             toast.error(error.response.data.message);
	        }
	    });

	    // Get Country
	    await http.get(process.env.REACT_APP_API_URL + '/get-countries',{
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
		.then((response) => {
			this.setState({ country : response.data.data })
		})
	}

	setText = (e,type) => {
	    this.setState({ [type+"_text"]: e.currentTarget.textContent,isSaved:false })

	    const custData = {...this.state.custData};
	    custData[type]=e.currentTarget.value;
	    this.setState({ custData });

		this.setState({
			timeout: resetTimeout(this.state.timeout, setTimeout(this.saveValue, 1000))
		})	

	}

	handleChecked = (e,type) =>{
		this.setState({ isSaved:false });
		const custData = {...this.state.custData};
		if(type == 'since_birth'){
			custData['since_birth']=0;
		    if(e.target.checked == true){		    	
	    		custData['since_birth']=1;
	    		custData['uk_resident_since']=custData['dob'];
		    }
		    this.setState({ custData });
		    this.setState({
				timeout: resetTimeout(this.state.timeout, setTimeout(this.saveValue, 1000))
			})	
		}
		if(type == 'consent_to_mail'){
			custData['consent_to_mail']='n';
		    if(e.target.checked == true){		    	
	    		custData['consent_to_mail']='y';
		    }
		    this.setState({ custData });
		    this.setState({
				timeout: resetTimeout(this.state.timeout, setTimeout(this.saveValue, 1000))
			})	
		}
		if(type == 'consent_to_telephone'){
			custData['consent_to_telephone']='n';
		    if(e.target.checked == true){		    	
	    		custData['consent_to_telephone']='y';
		    }
		    this.setState({ custData });
		    this.setState({
				timeout: resetTimeout(this.state.timeout, setTimeout(this.saveValue, 1000))
			})	
		}
		if(type == 'consent_to_text'){
			custData['consent_to_text']='n';
		    if(e.target.checked == true){		    	
	    		custData['consent_to_text']='y';
		    }
		    this.setState({ custData });
		    this.setState({
				timeout: resetTimeout(this.state.timeout, setTimeout(this.saveValue, 1000))
			})	
		}
		if(type == 'consent_to_email'){
			custData['consent_to_email']='n';
		    if(e.target.checked == true){		    	
	    		custData['consent_to_email']='y';
		    }
		    this.setState({ custData });
		    this.setState({
				timeout: resetTimeout(this.state.timeout, setTimeout(this.saveValue, 1000))
			})	
		}
	 }
	editValue = e => {	
		this.setState({ isSaved:false });
		const { name , value  } = e.target;
	    const custData = {...this.state.custData};
	    custData[name]=value;
	   
	    if(name == 'dob' && custData['since_birth'] == 1 ){
	    	 custData['uk_resident_since']=value;
	    }
	    if(custData['dob'] != ''){
	    	custData['age'] = this._calculateAge(custData['dob']);
	    }
	    this.setState({ custData });

		this.setState({
			timeout: resetTimeout(this.state.timeout, setTimeout(this.saveValue, 1000))
		})	
	};

	saveValue = async () => {	

		if(this.validateForCustomer() === null && this.state.isSaved == false){
			this.setState({ loader : true })
			const data = this.state.custData
			const token = localStorage.getItem('cccAuthToken');
			await http.post(process.env.REACT_APP_API_URL + '/customer-update', { data }, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			})
			.then((response) => {
				this.setState({ loader : false,isSaved:true })
				toast.success(response.data.message);
				const { customers } = this.state;
				const updatdData = response.data.data

				const cdata = customers.find(cust => cust.id == updatdData.id);     
	          	const index = customers.indexOf(cdata); 
	          	customers[index] = response.data.data;

	          	this.setState({ 
					customers : customers
				})


			}).catch(error => {
				this.setState({ loader : false })
		        if(error.response && error.response.status != 200){
		             toast.error(error.response.data.message);
		        }
		    });
		}
		
	};

	validateForCustomer = () => {
	    const { custData  } = this.state;    
	    const errors = {};
	    if(custData.title === ''){
	      errors.errtitle = 'Title is required field';
	    }
	    if(custData.first_name === ''){
	      errors.errfirst_name = 'First Name is required field';
	    }
	    if(custData.last_name === ''){
	      errors.errlast_name = 'Last Name is required field';
	    }
	    if(custData.dob === ''){
	      errors.errdob = 'Dob is required field';
	    }
	    if(custData.personal_email !== ''){
	      	if(!verifyEmail(custData.personal_email)){
		      errors.personal_email = 'Please enter  valid personal email';
		    } 
	    }
	    if(custData.work_email !== ''){
	      	if(!verifyEmail(custData.work_email)){
		      errors.work_email = 'Please enter valid work email';
		    } 
	    }
	    if(Object.keys(errors).length > 0){
	      Object.keys(errors).map(c => toast.error(errors[c]) )
	    }
	    
	    return Object.keys(errors).length === 0 ? null : errors;

	  }

	// Update Record Data

	setTextRecord = (e,type) => {

	    this.setState({ [type+"_text"]: e.currentTarget.textContent })

	    const records = {...this.state.records};
	    records[type]=e.currentTarget.value;
	    this.setState({ records });

		this.setState({
			timeout: resetTimeout(this.state.timeout, setTimeout(this.saveValueRecord, 1000))
		})	

	}

	editValueRecord = e => {	

		const { name , value  } = e.target;
	    const records = {...this.state.records};
	    records[name]=value;
	    this.setState({ records });

		this.setState({
			timeout: resetTimeout(this.state.timeout, setTimeout(this.saveValueRecord, 1000))
		})	
	};

	saveValueRecord = async () => {
		this.setState({ loader : true })
		const data = this.state.records
		const token = localStorage.getItem('cccAuthToken');
		await http.post(process.env.REACT_APP_API_URL + '/record-update', { data }, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
		.then((response) => {
			this.setState({ loader : false })
			toast.success(response.data.message);			
          	const records= response.data.data;
          	this.setState({ 
				records : records
			})

		}).catch(error => {
			this.setState({ loader : false })
	        if(error.response && error.response.status != 200){
	             toast.error(error.response.data.message);
	        }
	    });
	};

	// Create Contact

	validate = () => {
	    const { account,records  } = this.state;    
	    const errors = {};
	    if(account.title === ''){
	      errors.errtitle = 'Title is required field';
	    }
	    if(account.first_name === ''){
	      errors.errfirst_name = 'First Name is required field';
	    }
	    if(account.last_name === ''){
	      errors.errlast_name = 'Last Name is required field';
	    }
	    if(account.dob === ''){
	      errors.errdob = 'Dob is required field';
	    }
	    if(records.id === ''){
	      errors.errrecord = 'Invalid record selection';
	    }

	    this.setState({ errors });

	    if(Object.keys(errors).length > 0){
	      Object.keys(errors).map(c => toast.error(errors[c]) )
	    }	    
	    return Object.keys(errors).length === 0 ? null : errors;
	}
	handleChangeAddCon = (e) => {
	    const { name , value  } = e.target;
	    const account = {...this.state.account};
	    account[name]=value;
	    this.setState({account });
	}

	setTextAddCon = (e,type) => {
	    this.setState({ ['ctitle_text']: e.currentTarget.textContent })

	    const account = {...this.state.account};
	    account[type]=e.currentTarget.value;
	    this.setState({ account });
	}
	importCustomer = (customer_id,record_id) => {
	    
	    this.setState({ loader:true });
        const token = localStorage.getItem('cccAuthToken');
        http.post(process.env.REACT_APP_API_URL+'/import-customer-to-record', { customer_id ,record_id},{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => { 
			this.setState({ loader:false });  
			this.setState(initialState);
			toast.success(response.data.message);	 

			const cust =  response.data.data;
			if(cust){
				const customers = this.state.customers;
				customers.push(cust);
				this.setState({ customers : customers});
			}
			this.setState({ suggested_contact : [],rec: 0,isAddContact:false });

        })
        .catch((error) => {
          this.setState({ loader:false });   
          if(error.response && error.response.status != 200){
             toast.error(error.response.data.message);
          }
        });	   
	}
	handleSubmit = e => {

	    e.preventDefault();
	    const { account,records } = this.state;

	    let title = account.title;
	    let first_name = account.first_name;
	    let last_name = account.last_name;
	    let dob = account.dob;
	    let record_id = records.id;
	    let rec = this.state.rec;
	    

	    if(this.validate() === null){
	        this.setState({ loader:true });
	        const token = localStorage.getItem('cccAuthToken');
	        http.post(process.env.REACT_APP_API_URL+'/add-contact-record', { title,first_name,last_name,dob,record_id,rec },{
	          headers: {
	            'Authorization': `Bearer ${token}`
	          }
	        })
	        .then((response) => {  
	        	this.setState({ loader:false,});
	        	const suggested_contact =  response.data.data.suggested_contact;
				this.setState({ 
					suggested_contact : suggested_contact,
					record_id:record_id,
					rec: response.data.data.rec
				})

				const cust =  response.data.data.contact;
				if(cust.id !== undefined){
					console.log(cust);
					const customers = this.state.customers;
					customers.push(cust);
					this.setState({ customers : customers,suggested_contact:[],isAddContact:false});
					this.setState(initialState);
					document.getElementById("addContact").reset();	 
				}			
				
				toast.success(response.data.message);	  
				         
	        })
	        .catch((error) => {
	          this.setState({ loader:false });   
	          if(error.response && error.response.status != 200){
	             toast.error(error.response.data.message);
	          }
	        });
	    }	   
	}

	_calculateAge = birthday =>  { // birthday is a date
		const bd = this.parseDate(birthday);
	    var ageDifMs = Date.now() - bd.getTime();
	    var ageDate = new Date(ageDifMs); // miliseconds from epoch
	    return Math.abs(ageDate.getUTCFullYear() - 1970) > 100 ? 0 : Math.abs(ageDate.getUTCFullYear() - 1970);
	}

	parseDate = (input) => {
	  var parts = input.match(/(\d+)/g);
	  // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
	  return new Date(parts[0], parts[1]-1, parts[2]); // months are 0-based
	}

	render() {
		const { account,ctitle_text,record_id,suggested_contact,country,custData,contactItem,customers,records,loader,isReceived,isAddContact, isPersonal, isMarkrting,title_text,gender_text,nationality_text,uk_residency_status_text,occupier_status_text } = this.state
		return (
			<Fragment>
				{ loader && <Loading />}
				<Header onOpen={this.handleOpen} />
				{ isReceived == true ? 
					<Fragment>						
						<main className="main-content record-main d-flex">
							<section className="record-sidebar">
								<h4 className="text-uppercase">RECORD ID: { records.id } <br />FIRM: { records.advising_firm }</h4>
								<Button className="succes-btn full-btn rounded-btn" onClick={this.toggleAddContact}>Add Contact</Button>
								<div className="contact-list-section">
									<Scrollbars className="scrollable-contact-list vertical-scrollable side-scroll"
										renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
										renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
										renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
										renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
										
										{/* Contact Loop Here */}
										{ customers.map( (cust,k)=>
											<div className="contact">
												<div className="head d-flex align-items-center">
													<img className="nav-icon" src={userIcon} />
													<p className="m-0 text-capitalize">CONTACT { k+1 }: <br />{ cust.first_name+ " " +cust.last_name} </p>
												</div>
											</div>
										)}										
										
									</Scrollbars>
								</div>
								<div className="full-btn-list">
									<Button className="succes-btn full-btn rounded-btn">CALL</Button>
									<Button className="succes-btn full-btn rounded-btn">TEXT</Button>
									<Button className="succes-btn full-btn rounded-btn">EMAIL</Button>
								</div>
								<div className="record-tab">
									<Button className="active" onClick={this.toggleMarkrting} className={classnames({ 'active': isMarkrting })}>MARKETING &amp; CONSENT</Button>
									<Button onClick={this.togglePersonal} className={classnames({ 'active': isPersonal })}>PERSONAL DETAILS</Button>
								</div>
							</section>
							<section className="record-section d-flex justify-content-center">
								<div className="inner-section">
									<div className={classnames('marketing-section', { 'active': isMarkrting })}>
										<p className="text-uppercase bold-text">MARKETING CONSENT</p>
										<div className="record-contact permissions">
											<Scrollbars className="scrollable-head-list horizontal-scrollable bottom-scroll"
												renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
												renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
												renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />} >
												<div className="head-list d-flex">
													{/* Contact Loop */}

													{ customers.map( (cust,k)=>
														<div className={classnames('head d-flex align-items-center',  { 'active': k == contactItem ? true : false })} onClick={ () => this.toggleContact(k,cust.id)}>
															<img className="nav-icon" src={userWhiteIcon} />
															<div>
																<h3 className="m-0 text-uppercase">Contact { k+1 }</h3>
																<p className="m-0 text-capitalize">{ cust.title+ " "+ cust.first_name+ " " +cust.last_name}</p>
															</div>
														</div>
													)}
												</div>
											</Scrollbars>

											<div className="common-form m-0">
												<FormGroup className="switch-group d-flex align-items-center justify-content-between">
													<Label for="">CONSENT to mail marketing</Label>
													<div className="custom-switch">
														<input type="checkbox" class="custom-control-input" id="consent_to_mail"  onClick={ (e) => this.handleChecked(e,'consent_to_mail') } checked={ custData.consent_to_mail == 'y' ? true : false} />
														<label class="custom-control-label" for="consent_to_mail" ><span>No</span><span>Yes</span></label>
													</div>
												</FormGroup>
												<FormGroup className="switch-group d-flex align-items-center justify-content-between">
													<Label for="">CONSENT to telephone marketing</Label>
													<div className="custom-switch">
														<input type="checkbox" class="custom-control-input" id="consent_to_telephone" onClick={ (e) => this.handleChecked(e,'consent_to_telephone') } checked ={ custData.consent_to_telephone == 'y' ? true : false} />
														<label class="custom-control-label" for="consent_to_telephone"><span>No</span><span>Yes</span></label>
													</div>
												</FormGroup>
												<FormGroup className="switch-group d-flex align-items-center justify-content-between">
													<Label for="">CONSENT to text marketing</Label>
													<div className="custom-switch">
														<input type="checkbox" class="custom-control-input" id="consent_to_text" onClick={ (e) => this.handleChecked(e,'consent_to_text') } checked ={ custData.consent_to_text == 'y' ? true : false} />
														<label class="custom-control-label" for="consent_to_text"><span>No</span><span>Yes</span></label>
													</div>
												</FormGroup>
												<FormGroup className="switch-group d-flex align-items-center justify-content-between">
													<Label for="">CONSENT to email marketing</Label>
													<div className="custom-switch">
														<input type="checkbox" class="custom-control-input" id="consent_to_email" onClick={ (e) => this.handleChecked(e,'consent_to_email') } checked ={ custData.consent_to_email == 'y' ? true : false} />
														<label class="custom-control-label" for="consent_to_email"><span>No</span><span>Yes</span></label>
													</div>
												</FormGroup>
											</div>
										</div>
										<p className="text-uppercase bold-text">Marketing INFORMATION</p>
										<div className="record-contact">
											<div className="common-form m-0">
												<FormGroup className="d-flex align-items-center">
													<Label for="lead_type">Lead type</Label>
													<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
														<DropdownToggle className="dropdown-toggle">{ this.state.lead_type_text}</DropdownToggle>
														<DropdownMenu>
															<DropdownItem value='protection' onClick={(e)=>this.setTextRecord(e,'lead_type')}>PROTECTION</DropdownItem>
															<DropdownItem value='mortgage' onClick={(e)=>this.setTextRecord(e,'lead_type')}>MORTGAGE</DropdownItem>
														</DropdownMenu>
													</UncontrolledDropdown>
												</FormGroup>
												<FormGroup className="d-flex align-items-center">
													<Label for="leadStatus">RECORD STATUS</Label>
													<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
														<DropdownToggle className="dropdown-toggle">{ this.state.record_status_text}</DropdownToggle>
														<DropdownMenu>
															<DropdownItem value='unverified' onClick={(e)=>this.setTextRecord(e,'record_status')}>UNVERIFIED</DropdownItem>
															<DropdownItem value='verified' onClick={(e)=>this.setTextRecord(e,'record_status')}>VERIFIED</DropdownItem>
														</DropdownMenu>
													</UncontrolledDropdown>
												</FormGroup>
												<FormGroup className="d-flex align-items-center">
													<Label for="received_date">date/time received</Label>
													<Input type="texy" id="received_date" value={ records.received_date } name="received_date" disabled placeholder="Enter Date" />
												</FormGroup>
												<FormGroup className="d-flex align-items-center">
													<Label for="source">Source</Label>
													<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
														<DropdownToggle className="dropdown-toggle">{ this.state.source_text}</DropdownToggle>
														<DropdownMenu>
															<DropdownItem value='policystore.co.uk' onClick={(e)=>this.setTextRecord(e,'source')}>POLICYSTORE.CO.UK</DropdownItem>
															<DropdownItem value='findingmortgages.co.uk' onClick={(e)=>this.setTextRecord(e,'source')}>FINDINGMORTGAGES.CO.UK</DropdownItem>
															<DropdownItem value='recommendation' onClick={(e)=>this.setTextRecord(e,'source')}>RECOMMENDATION</DropdownItem>
														</DropdownMenu>
													</UncontrolledDropdown>
												</FormGroup>
												<FormGroup className="d-flex align-items-center">
													<Label for="channel">Channel</Label>
													<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
														<DropdownToggle className="dropdown-toggle">{ this.state.channel_text}</DropdownToggle>
														<DropdownMenu>
															<DropdownItem value='facebook' onClick={(e)=>this.setTextRecord(e,'channel')}>FACEBOOK</DropdownItem>
															<DropdownItem value='google' onClick={(e)=>this.setTextRecord(e,'channel')}>GOOGLE</DropdownItem>
															<DropdownItem value='print advert' onClick={(e)=>this.setTextRecord(e,'channel')}>PRINT ADVERT</DropdownItem>
														</DropdownMenu>
													</UncontrolledDropdown>
												</FormGroup>
												<FormGroup className="d-flex align-items-center">
													<Label for="campaign">Campaign</Label>
													<Input type="text" id="campaign" name="campaign" placeholder="Enter Campaign" onChange={ e => this.editValueRecord(e)} value={records.campaign} />
												</FormGroup>
												<FormGroup className="d-flex align-items-center">
													<Label for="assigned_agent">assigned agent</Label>
													<Input type="text" id="assigned_agent" name="assigned_agent" placeholder="Enter assigned agent" onChange={ e => this.editValueRecord(e)} value={records.assigned_agent} />
												</FormGroup>
												<FormGroup className="d-flex align-items-center">
													<Label for="source">ADVISING FIRM</Label>
													<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
														<DropdownToggle className="dropdown-toggle">{ this.state.advising_firm_text}</DropdownToggle>
														<DropdownMenu>
															<DropdownItem value='one money' onClick={(e)=>this.setTextRecord(e,'advising_firm')}>ONE MONEY</DropdownItem>
															<DropdownItem value='policystore' onClick={(e)=>this.setTextRecord(e,'advising_firm')}>POLICYSTORE</DropdownItem>
														</DropdownMenu>
													</UncontrolledDropdown>
												</FormGroup>
											</div>
										</div>
									</div>
									<div className={classnames('marketing-section', { 'active': isPersonal })}>
										<p className="text-uppercase bold-text">PERSONAL DETAILS</p>
										<div className="record-contact">
											<Scrollbars className="scrollable-head-list horizontal-scrollable bottom-scroll"
												renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
												renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
												renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />} >
												<div className="head-list d-flex">
													{/* Contact Loop */}

													{ customers.map( (cust,k)=>
														<div className={classnames('head d-flex align-items-center', { 'active': k == contactItem ? true : false })} onClick={ () => this.toggleContact(k,cust.id)}>
															<img className="nav-icon" src={userWhiteIcon} />
															<div>
																<h3 className="m-0 text-uppercase">Contact { k+1 }</h3>
																<p className="m-0 text-capitalize">{ cust.title+ " "+ cust.first_name+ " " +cust.last_name}</p>
															</div>
														</div>
													)}

												</div>
											</Scrollbars>
											<Row className="no-gutters">												
													<Col xl={6}>														
														<Form method="post" autoComplete="off">
														<div className="common-form m-0">
															<FormGroup className="d-flex align-items-center">
																<Label for="firstName">Title</Label>
																<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
																	<DropdownToggle className="dropdown-toggle">{ title_text }</DropdownToggle>
																	<DropdownMenu>
																		<DropdownItem value='mr' onClick={ (e)=>this.setText(e,'title') }>Mr</DropdownItem>
																		<DropdownItem value='mrs' onClick={ (e)=>this.setText(e,'title') } >Mrs</DropdownItem>
																		<DropdownItem value='miss' onClick={ (e)=>this.setText(e,'title') } >Miss</DropdownItem>
																		<DropdownItem value='ms' onClick={ (e)=>this.setText(e,'title') } >Ms</DropdownItem>
																		<DropdownItem value='dr' onClick={ (e)=>this.setText(e,'title') } >Dr</DropdownItem>
																	</DropdownMenu>
																</UncontrolledDropdown>
															</FormGroup>
															<FormGroup className="d-flex align-items-center">
																<Label for="first_name">First name</Label>
																<Input type="text" autoComplete="nope" onChange={ e => this.editValue(e)} id="first_name" name="first_name" placeholder="Enter First name" value={ custData.first_name } />
															</FormGroup>
															<FormGroup className="d-flex align-items-center">
																<Label for="last_name">last name</Label>
																<Input type="text" autoComplete="nope" onChange={ e => this.editValue(e)} id="last_name" name="last_name" placeholder="Enter last name" value={ custData.last_name } />
															</FormGroup>
															<FormGroup className="d-flex align-items-center has-inner-group">
																<Label for="dob">DOB</Label>
																<Input type="date" autoComplete="nope" id="dob" name="dob" onChange={ e => this.editValue(e) } placeholder="Enter DOB" className="large-input" value={ custData.dob } />
																<FormGroup className="d-flex align-items-center">
																	<Label className="inner-label" for="age">age</Label>
																	<Input type="text" autoComplete="nope" id="age" onChange={ e => this.editValue(e)} disabled name="age" placeholder="age" value={ custData.age } />
																</FormGroup>
															</FormGroup>
															<FormGroup className="d-flex align-items-center">
																<Label for="gender">gender</Label>
																<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
																	<DropdownToggle className="dropdown-toggle">{gender_text}</DropdownToggle>
																	<DropdownMenu>
																		<DropdownItem value='male' onClick={ (e)=>this.setText(e,'gender') }>Male</DropdownItem>
																		<DropdownItem value='female' onClick={ (e)=>this.setText(e,'gender') }>Female</DropdownItem>
																		<DropdownItem value='unspecified' onClick={ (e)=>this.setText(e,'gender') }>Unspecified</DropdownItem>
																	</DropdownMenu>
																</UncontrolledDropdown>
															</FormGroup>
															<FormGroup className="d-flex align-items-center">
																<Label for="nationality">Nationality</Label>																
																<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
																	<DropdownToggle className="dropdown-toggle">{nationality_text}</DropdownToggle>
																	<DropdownMenu>
																		<Scrollbars className="scrollable-dropdown"
															                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
															                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
															                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
															                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
																			{country.map(con => 
																				<DropdownItem value={con.id} onClick={ (e)=>this.setText(e,'nationality') }>{con.name}</DropdownItem>
																			)}
																		</Scrollbars>
																	</DropdownMenu>
																</UncontrolledDropdown>
															</FormGroup>
															<FormGroup className="d-flex align-items-center">
																<Label for="residencyStatus">uk residency status</Label>
																<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
																	<DropdownToggle className="dropdown-toggle">{ uk_residency_status_text }</DropdownToggle>
																	<DropdownMenu>
																		<DropdownItem value='permanent' onClick={ (e)=>this.setText(e,'uk_residency_status') }>Permanent</DropdownItem>
																		<DropdownItem value='temporary work visa' onClick={ (e)=>this.setText(e,'uk_residency_status') }>Temporary work visa</DropdownItem>																	
																	</DropdownMenu>
																</UncontrolledDropdown>
															</FormGroup>
															<FormGroup className="d-flex align-items-center has-inner-group">
																<Label for="uk_resident_since">uk resident since</Label>
																<Input type="date"  id="uk_resident_since" onChange={ e => this.editValue(e)} name="uk_resident_since" placeholder="MM/YYYY" disabled = { custData.since_birth == '1' ? true : false} className="large-input"  value={ custData.uk_resident_since }/>
																<FormGroup>
																	<CustomInput className="inner-label" onClick={ (e) => this.handleChecked(e,'since_birth') } type="checkbox" checked ={ custData.since_birth == '1' ? true : false} id="exampleCustomCheckbox" label="since birth" />
																</FormGroup>
															</FormGroup>
															<FormGroup className="d-flex align-items-center">
																<Label for="personal_email">personal email</Label>
																<Input type="email" autoComplete="nope" id="personal_email" onChange={ e => this.editValue(e)} name="personal_email" placeholder="Enter Personal Email" value={ custData.personal_email } />
															</FormGroup>
															<FormGroup className="d-flex align-items-center">
																<Label for="work_email">work email</Label>
																<Input type="tel" autoComplete="nope" id="work_email" onChange={ e => this.editValue(e)} name="work_email" placeholder="Enter Work Email" value={ custData.work_email } />
															</FormGroup>
														</div>
														</Form>
													</Col>
													<Col xl={6}>
														<Form method="post" autoComplete="off">
														<div className="common-form m-0">
															<FormGroup className="d-flex align-items-center">
																<Label for="home_tel">home telphone</Label>
																<Input type="tel" autoComplete="nope" id="home_tel" onChange={ e => this.editValue(e)} name="home_tel" placeholder="Enter Home Telphone" value={ custData.home_tel } />
															</FormGroup>
															<FormGroup className="d-flex align-items-center">
																<Label for="work_tel">work telphone</Label>
																<Input type="tel" autoComplete="nope" id="work_tel" onChange={ e => this.editValue(e)} name="work_tel" placeholder="Enter Work Telphone" value={ custData.work_tel } />
															</FormGroup>
															<FormGroup className="d-flex align-items-center">
																<Label for="mob_tel">mobile telphone</Label>
																<Input type="tel" autoComplete="nope" id="mob_tel" onChange={ e => this.editValue(e)} name="mob_tel" placeholder="Enter Telphone" value={ custData.mob_tel } />
															</FormGroup>
															<FormGroup className="d-flex align-items-center">
																<Label for="home_post_code">home postcode</Label>
																<Input type="text" autoComplete="nope" id="home_post_code" onChange={ e => this.editValue(e)} name="home_post_code"  placeholder="Enter Postcode" value={ custData.home_post_code } />
															</FormGroup>
															<FormGroup className="d-flex">
																<Label for="home_address_1">HOME ADDRESS</Label>
																<Input type="textarea" autoComplete="nope" id="home_address_1" onChange={ e => this.editValue(e)} name="home_address_1" placeholder="Enter Address" value={ custData.home_address_1 } />
															</FormGroup>
															<FormGroup className="d-flex align-items-center">
																<Label for="occupier_status">occupier status</Label>
																<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
																	<DropdownToggle className="dropdown-toggle">{ occupier_status_text }</DropdownToggle>
																	<DropdownMenu>
																		<DropdownItem value='home owner' onClick={ (e)=>this.setText(e,'occupier_status') }>HOME OWNER</DropdownItem>
																		<DropdownItem value='renting' onClick={ (e)=>this.setText(e,'occupier_status') }>RENTING</DropdownItem>
																		<DropdownItem value='living with parents' onClick={ (e)=>this.setText(e,'occupier_status') }>LIVING WITH PARENTS</DropdownItem>
																		<DropdownItem value='living with relatives' onClick={ (e)=>this.setText(e,'occupier_status') }>LIVING WITH RELATIVES</DropdownItem>
																	</DropdownMenu>
																</UncontrolledDropdown>
															</FormGroup>
															<FormGroup className="d-flex align-items-center">
																<Label for="date_moved">Date moved in</Label>
																<Input type="text" id="date_moved" name="date_moved" placeholder="MM/YYYY" onChange={ e => this.editValue(e)}  value={ custData.date_moved }/>
															</FormGroup>
														</div>
														</Form>
													</Col>
											</Row>
										</div>
									</div>
								</div>
							</section>
							<div className={classnames('common-side-section add-contact-sidebar', { 'active': isAddContact })}>
								<section className="inner-section">
									<p className="d-flex justify-content-between">
										<b className="text-uppercase">ADD CONTACT TO RECORD</b>
										<Button color="link" className="close-btn" onClick={this.toggleAddContact}> <img src={closeIcon} alt="close" /> </Button>
									</p>
									<Form method="post" onSubmit= { this.handleSubmit } id='addContact'  autocomplete="off">
										<div className="common-form">
											<FormGroup className="d-flex align-items-center">
												<Label for="addTitle">Title</Label>
												<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
													<DropdownToggle className="dropdown-toggle">{ ctitle_text }</DropdownToggle>
													<DropdownMenu>
														<DropdownItem value='mr' onClick={(e)=>this.setTextAddCon(e,'title')}>Mr</DropdownItem>
														<DropdownItem value='mrs' onClick={(e)=>this.setTextAddCon(e,'title')}>Mrs</DropdownItem>
														<DropdownItem value='miss' onClick={(e)=>this.setTextAddCon(e,'title')}>Miss</DropdownItem>
														<DropdownItem value='ms' onClick={(e)=>this.setTextAddCon(e,'title')}>Ms</DropdownItem>
														<DropdownItem value='dr' onClick={(e)=>this.setTextAddCon(e,'title')}>Dr</DropdownItem>
													</DropdownMenu>
												</UncontrolledDropdown>
											</FormGroup>
											<FormGroup className="d-flex align-items-center">
												<Label for="fname">First name</Label>
												<Input type="text" id="fname" name="first_name" value={account.first_name} placeholder="Enter First name" onChange={(e) => { this.handleChangeAddCon(e) }} />
											</FormGroup>
											<FormGroup className="d-flex align-items-center">
												<Label for="lname">last name</Label>
												<Input type="text" id="lname" name="last_name"  value={account.last_name} placeholder="Enter last name" onChange={(e) => { this.handleChangeAddCon(e) }} />
											</FormGroup>
											<FormGroup className="d-flex align-items-center">
												<Label for="dob1">DOB</Label>
												<Input type="date" id="dob1" name="dob"  value={account.dob} placeholder="Enter DOB" onChange={(e) => { this.handleChangeAddCon(e) }} />
											</FormGroup>
											<div className="text-right">
												<Button type="submit" className="succes-btn">create</Button>
											</div>
										</div>
									</Form>

									{ suggested_contact.length > 0 ? 
									<Fragment>
										<p className="mb-1">We’ve found the following contacts matching that description.</p>
										<p>Do you want to create a new contact or import existing contact. </p>
										<div className="customer-list">
											{ suggested_contact.map(con =>
												<div className="customer-item">
													<div className="title d-flex justify-content-between align-items-center">
														<span className="text-uppercase">customer ID : { con.id}</span>
														<Button className="succes-btn"  onClick={ () => this.importCustomer(con.id,record_id) }>IMPORT</Button>
													</div>
													<div className="common-form">
														<FormGroup className="d-flex align-items-center">
															<Label for="customerName1">Name</Label>
															<Input type="text" id="customerName1" disabled placeholder="" value = { con.first_name+" "+con.last_name} />
														</FormGroup>
														<FormGroup className="d-flex align-items-center">
															<Label for="customerDOB1">DOB</Label>
															<Input type="date" id="customerDOB1"  disabled placeholder="" value = { con.dob} />
														</FormGroup>
														<FormGroup className="d-flex">
															<Label for="customeraddress1">Address</Label>
															<Input type="textarea" id="customeraddress1" disabled placeholder="" value = { con.home_address_1 } />
														</FormGroup>
													</div>
												</div>
											)}
										</div>
									</Fragment> : '' }
									
								</section>
							</div>
						</main>
						<Footer logs = { this.state.change_log } />
					</Fragment>
				: '' }
			</Fragment>
		)
	}
}

export default Record