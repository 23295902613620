export default (state={},action) => {

	switch (action.type) {
		case 'SET_EDIT_CHILD_SPECIFIC_DATA':
			return {
				data : action.payload.data,
				type: action.type
			}
		default:
      	 return state
	}

}