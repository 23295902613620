import React from 'react'

const Loading = () => (
  <>
    <div className="loader-container d-flex justify-content-center align-items-center">
      <div className="custom-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </>
)

export default Loading
