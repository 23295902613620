import { combineReducers } from 'redux'
import auth from './auth'
import fullPaymentCondition from './fullPaymentCondition'
import user from './user'
import provider from './provider'
import AdditionalPaymentCondition from './AdditionalPaymentCondition'
import childSpecificCondition from './childSpecificCondition'
import ExtraFeatureCondition from './ExtraFeatureCondition'
import conditions from './conditions'
import editfullPaymentCondition from './editfullPaymentCondition'
import editAditionalPaymentCondition from './editAditionalPaymentCondition'
import editChildSpecificCondition from './editChildSpecificCondition'
import editExtraFeatureCondition from './editExtraFeatureCondition'
import CreateContract from './CreateContract'
import comparisonContract from './comparisonContract'
import DeleteContract from './DeleteContract'


export default combineReducers({ 
  auth,
  fullPaymentCondition,
  user,
  provider,
  AdditionalPaymentCondition,
  childSpecificCondition,
  ExtraFeatureCondition,
  conditions,
  editfullPaymentCondition,
  editAditionalPaymentCondition,
  editChildSpecificCondition,
  editExtraFeatureCondition,
  CreateContract,
  comparisonContract,
  DeleteContract
})
