import React,{Component} from 'react'
import { NavLink } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { verifyLength, verifyEmail ,verifyPassword } from '../../../helper';
import config from '../../../config.json';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../../components/Loading';
import http from '../../../httpService/httpService';


import bannerImg from '../../../assets/images/login-banner.jpg';


class ForgotForm extends  Component{

  constructor(props) {
    super(props)
  }
  state = {
    account : {email : ''},
    errors  : {errEmail : ''},
    loader : false,
  } 


  validate = () =>{
      const { account } = this.state;
      const errors = {};

      if(account.email == ''){
        errors.errEmail = 'Email is required field';
      }else if(!verifyEmail(account.email)){
        errors.errEmail = 'Please enter valid email';
      }
      this.setState({ errors });
      return Object.keys(errors).length === 0 ? null : errors;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { account } = this.state;
   
    if(this.validate() === null){
        this.setState({ loader:true });
        const email = account.email;
        http.post(process.env.REACT_APP_API_URL+'/forgot-password', { email })
        .then((response) => {
          this.setState({ loader:false });
          toast.success(response.data.message);
        })
        .catch((error) => {
          this.setState({ loader:false });
          if(error.response && error.response.status != 200){
             toast.error(error.response.data.message);
          }
        });
    }
   
  }

  handleChange = (e) => {
    const { name , value  } = e.target 
    const account = {...this.state.account};
    account[name] = value;
    this.setState({ account });
  }

  render(){
     const {  account , errors,loader } =  this.state;

    return (
      <React.Fragment>
        {loader && <Loading />}
         <Form onSubmit = { (e) => { this.handleSubmit(e) } }>
            <FormGroup>
              <Label htmlFor="email">Your email address</Label>
              <Input type="email" name='email' onChange={(e) => { this.handleChange(e) }}/>
              { errors.errEmail && <p className="error-text"> <em> { errors.errEmail }   </em></p> }
            </FormGroup>
            <Button type="submit" className="theme-btn submit-btn">Send</Button>
            <p className="mb-0">Back to <NavLink to={`${process.env.PUBLIC_URL}/login`}>Login screen</NavLink></p>
          </Form>
      </React.Fragment> 
    )
  }
}

export default ForgotForm