import React, { Component, Fragment } from 'react'
import {
  Form, Nav, TabContent, TabPane, NavItem, Label, Button, FormGroup, CustomInput, Input, Modal, ModalBody
} from 'reactstrap';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import plusIcon from '../../assets/images/plus-icon.png';
import deleteIcon from '../../assets/images/delete-icon.png';
import http from '../../httpService/httpService'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Loading from '../../components/Loading'
import { connect } from 'react-redux';

class Filter extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this);
    this.toggleSaveComprModal = this.toggleSaveComprModal.bind(this);
    this.toggleSaveComprModal1 = this.toggleSaveComprModal1.bind(this);
    this.state = {
      activeTab: '1',
      modal: false,
      token: localStorage.getItem('cccAuthToken'),
      contractList: [],
      checkedContract: [],
      selectCon: false,
      cData: { title: '', id: '' },
      errors: { errTitle: '' },
      loader: false,
      comparison: [],
      comparisonContractList: [],
      dispCon: false,
      cc_id: '',
      deleteCCModal: false,
      c_id: '',
      deleteCModal: false,
      com_id: '',
      cData1: { title: '', id: '' },
      errors1: { errTitle: '' },
      contractid: []
    };
  }

  async componentDidMount() {

    const token = this.state.token;
    await http.get(process.env.REACT_APP_API_URL + '/get-contracts', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        const cc = response.data.data;
        const contractList = cc.sort((a, b) => a.provider.localeCompare(b.provider))
        this.setState({ contractList });
      })
      .catch((error) => {
        if (error.response && error.response.status != 200) {
          toast.error(error.response.data.message);
        }
      });

    await http.get(process.env.REACT_APP_API_URL + '/get-comparison', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        const comparison = response.data.data;
        this.setState({ comparison });
      })
      .catch((error) => {
        if (error.response && error.response.status != 200) {
          toast.error(error.response.data.message);
        }
      });

  }

  validateCreateComparison = () => {
    const { cData } = this.state;
    const errors = {};

    // Email Validation
    if (cData.title == '') {
      errors.errTitle = 'This comparison needs a title to be saved';
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0 ? null : errors;
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const cData = { ...this.state.cData };
    cData[name] = value;
    this.setState({ cData });
  }

  handleCreateComparison = e => {
    e.preventDefault();
    const { cData } = this.state;

    let title = cData.title;
    const checkedContract = this.state.checkedContract;

    if (this.validateCreateComparison() === null) {
      this.setState({ loader: true });
      http.post(process.env.REACT_APP_API_URL + '/create-contract-comparison', {
        title,
        checkedContract: JSON.stringify(checkedContract)
      }, {
        headers: {
          'Authorization': `Bearer ${this.state.token}`
        }
      })
        .then((response) => {
          this.setState({ loader: false });
          toast.success(response.data.message);

          const cc = [...this.state.comparison, {
            id: response.data.data.id,
            title: response.data.data.title,
            user_id: response.data.data.user_id,
          }];
          const comparison = cc.sort((a, b) => a.title.localeCompare(b.title))
          this.setState({ comparison });

          this.setState({
            saveComprModal: false,
            cData: { title: '', id: '' },
            errors: { errTitle: '' },
            checkedContract: []
          });

          var inputs = document.querySelectorAll('.checkd1 .custom-control-input');
          for (var i = 0; i < inputs.length; i++) {
            inputs[i].checked = false;
          }

        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error.response && error.response.status != 200) {
            toast.error(error.response.data.message);
          }
        });
    }
  }


  toggleSaveComprModal() {
    const checkedContract = this.state.checkedContract;
    if (checkedContract.length < 2) {
      this.setState({ selectCon: true });
    } else {
      this.setState({
        saveComprModal: !this.state.saveComprModal
      });
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  handleChecked = (id) => {
    const checkedContract = this.state.checkedContract;

    if (checkedContract.includes(id)) {
      const filteredBtn = checkedContract.filter(item => item !== id)
      this.setState({ checkedContract: filteredBtn })

    } else {

      const filteredBtn = [...this.state.checkedContract, id];
      this.setState({ checkedContract: filteredBtn })
    }
  }

  getContract = async (e, conId) => {

    if (e.target.checked == true) {
      this.setState({ loader: true });
      const com_id = e.target.value;
      const token = this.state.token;

      this.setState({ com_id: com_id })
      await http.post(process.env.REACT_APP_API_URL + '/get-contract-comparison',
        { com_id },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => {
          const comparisonContractList = response.data.data;
          const contractid = response.data.contractid;

          this.setState({ contractid });
          this.setState({ comparisonContractList });
          this.setState({ dispCon: true, loader: false });

          this.props.setContractComparisonData(JSON.stringify(contractid));
        })
        .catch((error) => {
          if (error.response && error.response.status != 200) {
            toast.error(error.response.data.message);
          }
          this.setState({ loader: false });
        });
    } else {
      const comparisonContractList = this.state.comparisonContractList;
      this.setState({ comparisonContractList });
      this.setState({ dispCon: false });
    }


  }

  toggleDelConModal = (cc_id) => {
    this.setState({ cc_id: cc_id });
    this.setState({
      deleteCCModal: !this.state.deleteCCModal
    });
  }

  deleteCC = async (cc_id) => {
    this.setState({ loader: true, deleteCCModal: false });

    const token = this.state.token;
    await http.post(process.env.REACT_APP_API_URL + '/delete-contract-comparison', { 'id': cc_id }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {

        const pls = this.state.comparisonContractList;
        const comparisonContractList = pls.filter(cc => cc.id != cc_id);
        this.setState({ comparisonContractList });

        toast.success(response.data.message);
        this.setState({ loader: false });

      })
      .catch((error) => {
        this.setState({ loader: false });
        if (error.response && error.response.status != 200) {
          toast.error(error.response.data.message);
        }
      });

  }

  toggleDelCModal = (c_id) => {
    this.setState({ c_id: c_id });
    this.setState({
      deleteCModal: !this.state.deleteCModal
    });
  }

  deleteC = async (c_id) => {
    this.setState({ loader: true, dispCon: false, deleteCModal: false });

    const token = this.state.token;
    await http.post(process.env.REACT_APP_API_URL + '/delete-comparison', { 'id': c_id }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {

        const pls = this.state.comparison;
        const comparison = pls.filter(c => c.id != c_id);
        this.setState({ comparison });
        this.setState({ com_id: '' });

        toast.success(response.data.message);
        this.setState({ loader: false });

        var inputs = document.querySelectorAll('.inpradio .custom-control-input');
        for (var i = 0; i < inputs.length; i++) {
          inputs[i].checked = false;
        }
        this.props.setContractComparisonData(JSON.stringify([]));
      })
      .catch((error) => {
        this.setState({ loader: false });
        if (error.response && error.response.status != 200) {
          toast.error(error.response.data.message);
        }
      });

  }

  deleteCCL = (id) => {
    const checkedContract = this.state.checkedContract;

    if (checkedContract.includes(id)) {
      const filteredBtn = checkedContract.filter(item => item !== id)
      this.setState({ checkedContract: filteredBtn })

    } else {

      const filteredBtn = [...this.state.checkedContract, id];
      this.setState({ checkedContract: filteredBtn })
    }
  }

  setDefaultComp = async () => {

    const checkedContract = this.state.checkedContract;
    const com_id = this.state.com_id;
    if (checkedContract.length > 0 && com_id == '') {
      const ck = JSON.stringify(checkedContract);
      this.props.setContractData(ck);
    } else if (checkedContract.length == 0 && com_id == '') {
      const ck = JSON.stringify([]);
      this.props.setContractData(ck);
    }
  }



  componentWillReceiveProps(nextProps) {

    if (nextProps.createContractData && nextProps.createContractData.type == 'SET_CREATE_CONTRACT') {
      const cData = nextProps.createContractData.data;
      const conlist = this.state.contractList.find(cl => cl.id == cData.id);
      if (!conlist) {
        const cc = [...this.state.contractList, cData];
        const contractList = cc.sort((a, b) => a.provider.localeCompare(b.provider))
        this.setState({ contractList });
      }
    }

    if (nextProps.deleteContract && nextProps.deleteContract.type == 'DELETE_CONTRACT') {

      const contractId = nextProps.deleteContract.data;
      const con = this.state.contractList;
      const contractList = con.filter(cl => cl.id != contractId);
      this.setState({ contractList });

      const conCheked = this.state.checkedContract;
      const checkedContract = conCheked.filter(cl1 => cl1 != contractId);
      this.setState({ checkedContract });

    }

  }

  /*  new  Cpmparoson */
  handleChange1 = (e) => {
    const { name, value } = e.target;
    const cData1 = { ...this.state.cData1 };
    cData1[name] = value;
    this.setState({ cData1 });
  }

  toggleSaveComprModal1() {
    this.setState({
      saveComprModal1: !this.state.saveComprModal1
    });
  }

  handleCreateComparison1 = e => {
    e.preventDefault();
    const { cData1 } = this.state;
    let title = cData1.title;
    const comparisonContractList = this.state.comparisonContractList;

    if (this.validateCreateComparison1() === null && comparisonContractList.length > 0) {

      const checkedContract = [];
      comparisonContractList.map(cc => {
        checkedContract.push(cc.contract_id)
      });

      this.setState({ loader: true });
      http.post(process.env.REACT_APP_API_URL + '/create-contract-comparison', {
        title,
        checkedContract: JSON.stringify(checkedContract)
      }, {
        headers: {
          'Authorization': `Bearer ${this.state.token}`
        }
      })
        .then((response) => {
          this.setState({ loader: false });
          toast.success(response.data.message);

          const cc = [...this.state.comparison, {
            id: response.data.data.id,
            title: response.data.data.title,
            user_id: response.data.data.user_id,
          }];
          const comparison = cc.sort((a, b) => a.title.localeCompare(b.title))
          this.setState({ comparison });

          this.setState({
            saveComprModal1: false,
            cData1: { title: '', id: '' },
            errors1: { errTitle: '' }
          });

        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error.response && error.response.status != 200) {
            toast.error(error.response.data.message);
          }
        });
    } else {
      toast.error('Something went wrong!!');
      this.setState({
        saveComprModal1: false,
        cData1: { title: '', id: '' },
        errors1: { errTitle: '' }
      });
    }
  }

  validateCreateComparison1 = () => {
    const { cData1 } = this.state;
    const errors1 = {};

    // Email Validation
    if (cData1.title == '') {
      errors1.errTitle = 'This comparison needs a title to be saved';
    }
    this.setState({ errors1 });
    return Object.keys(errors1).length === 0 ? null : errors1;
  }


  clearData = (type) => {
    if (type == 'cnew') {

      const comparisonContractList = [];
      this.setState({ comparisonContractList });
      this.setState({ dispCon: false, com_id: '' });

      var inputs = document.querySelectorAll('.inpradio .custom-control-input');
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;
      }
    }
    if (type == 'csaved') { //Remove contract saved            

      this.setState({
        checkedContract: []
      });

      var inputs = document.querySelectorAll('.checkd1 .custom-control-input');
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;
      }

    }
  }


  render() {
    const { errors1, saveComprModal1, checkedContract, loader, contractList, selectCon, errors, comparison, comparisonContractList, dispCon, deleteCCModal, cc_id, c_id, deleteCModal } = this.state
    const checkedContractList = [];

    checkedContract.map(cc => {
      let cl = contractList.find(cl => cl.id == cc);
      checkedContractList.push(cl);
    })

    return (
      <Fragment>
        {loader && <Loading />}
        <div className="tab-section">
          <div className="tab-head d-flex justify-content-between align-items-center">
            <Nav tabs className="nav nav-pills">
              <NavItem>
                <a className={classnames('nav-link', { active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); this.clearData('cnew') }}>New Comparison</a>
              </NavItem>
              <NavItem>
                <a className={classnames('nav-link', { active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); this.clearData('csaved') }}>Saved comparison</a>
              </NavItem>
            </Nav>
            <Button className="close-btn" onClick={() => { this.props.onClose('filter'); this.setDefaultComp() }}><img src={plusIcon} alt="close" /></Button>
          </div>
          <TabContent className="tab-content" activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              {/* {this.state.activeTab == 1 ? <h4>Tab 1 Contents</h4> : null} */}
              <div className="sub-title d-flex justify-content-between align-items-center">
                <em>Select contracts</em>
              </div>
              <Scrollbars className="scrollable-list"
                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                <ul className="check-list text-uppercase">
                  {contractList.map(con =>
                    <li className="d-flex justify-content-between align-items-center">
                      <FormGroup>
                        <CustomInput checked={checkedContract.includes(con.id) ? 'checked' : ''} type="checkbox" onClick={(e) => { this.handleChecked(con.id); }} id={`defaultCheck` + con.id} label={con.provider + ' / ' + con.contract_name + ' / ' + con.status + ' / ' + con.c_live_date + (con.c_end_date != '' ? (' / ' + con.c_end_date) : '')} className='checkd1' />
                      </FormGroup>
                    </li>
                  )}
                </ul>
              </Scrollbars>
              <div className="sub-title d-flex justify-content-between align-items-center">
                <em>Contracts selected</em>
                <Button onClick={this.toggleSaveComprModal}>ADD TO SAVED COMPARISON</Button>
              </div>
              <div className="selected-block">
                <Scrollbars className="scrollable-list"
                  renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                  renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                  renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                  renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                  <ul className="selected-list text-uppercase">
                    {checkedContractList.map(con =>
                      <li className="d-flex justify-content-between align-items-center">
                        {con.provider + ' / ' + con.contract_name + ' / ' + con.status + ' / ' + con.c_live_date + (con.c_end_date != '' ? (' / ' + con.c_end_date) : '')}
                        <a className="delete-icon" onClick={() => this.deleteCCL(con.id)}><img src={deleteIcon} alt="delete" /></a>
                      </li>
                    )}
                  </ul>
                </Scrollbars>
              </div>
            </TabPane>
            <TabPane tabId="2">
              {/* {this.state.activeTab == 2 ? <h4>Tab 2 Contents</h4> : null} */}
              <div className="sub-title d-flex justify-content-between align-items-center">
                <em>Select saved comparison</em>
              </div>
              <Scrollbars className="scrollable-list"
                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                <ul className="check-list text-uppercase">
                  {comparison.map(com =>
                    <li className="d-flex justify-content-between align-items-center">
                      <FormGroup>
                        <CustomInput type="radio" name="contractRadio" className='inpradio' value={com.id} onClick={(e) => this.getContract(e, com.id)} id={"com_checkbox" + com.id} label={com.title} />
                      </FormGroup>
                      <a className="delete-icon" onClick={() => this.toggleDelCModal(com.id)}><img src={deleteIcon} alt="delete" /></a>
                    </li>
                  )}
                </ul>
              </Scrollbars>

              {dispCon == true ?
                <>
                  <div className="sub-title d-flex justify-content-between align-items-center">
                    <em>Contracts selected</em>
                    {<Button onClick={this.toggleSaveComprModal1}>ADD TO SAVED COMPARISON</Button>}
                  </div>
                  <div className="selected-block">
                    <Scrollbars className="scrollable-list"
                      renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                      renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                      renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                      renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                      <ul className="selected-list text-uppercase">
                        {comparisonContractList.map(cc =>
                          <li className="d-flex justify-content-between align-items-center">
                            {cc.contract.provider + ' / ' + cc.contract.contract_name + ' / ' + cc.contract.status + ' / ' + cc.contract.c_live_date + (cc.contract.c_end_date != '' ? (' / ' + cc.contract.c_end_date) : '')}

                            <a className="delete-icon" onClick={() => this.toggleDelConModal(cc.id)} ><img src={deleteIcon} alt="delete" /></a>
                          </li>
                        )}

                      </ul>
                    </Scrollbars>
                  </div>
                </>
                : null}
            </TabPane>
          </TabContent>
        </div>
        <Modal isOpen={this.state.saveComprModal} toggle={this.toggleSaveComprModal}>
          <ModalBody>
            {loader && <Loading />}
            <Form method="post" onSubmit={this.handleCreateComparison} id='createComparison'>
              <div className="action-button d-flex justify-content-end">
                <Button type="button" onClick={this.toggleSaveComprModal}>Abandon</Button>
                <Button type="submit">Save changes</Button>
              </div>
              <div className="modal-body">
                <FormGroup>
                  <Label for="comparisonName"><em>Name this comparison</em></Label>
                  <Input type="text" id="comparisonName" name='title' onChange={(e) => { this.handleChange(e) }} />
                  {errors.errTitle && <p className="error-text"> <em> {errors.errTitle} </em></p>}
                </FormGroup>
              </div>
            </Form>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.selectCon} toggle={this.toggledeleteModal} className="add-provider-modal">
          <ModalBody>
            {loader && <Loading />}
            <div className="action-button d-flex justify-content-end">
              <Button type="button" onClick={() => this.setState({ selectCon: !selectCon })}>Ok</Button>
            </div>
            <div className="modal-body">
              <FormGroup>
                <Label htmlFor="contractFeature"><em>Please select minimum two contract for comparison.</em></Label>
              </FormGroup>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.deleteCCModal} toggle={this.toggleDelConModal} className="delete-condition-modal">
          <ModalBody>
            {loader && <Loading />}
            <div className="action-button d-flex justify-content-end">
              <Button type="button" onClick={this.toggleDelConModal}>No</Button>
              <Button type="button" onClick={() => this.deleteCC(cc_id)} >Yes</Button>
            </div>
            <div className="modal-body">
              <FormGroup>
                <Label htmlFor="cc"><em>Are you sure want to delete this?</em></Label>
              </FormGroup>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.deleteCModal} toggle={this.toggleDelCModal} className="delete-condition-modal">
          <ModalBody>
            {loader && <Loading />}
            <div className="action-button d-flex justify-content-end">
              <Button type="button" onClick={this.toggleDelCModal}>No</Button>
              <Button type="button" onClick={() => this.deleteC(c_id)} >Yes</Button>
            </div>
            <div className="modal-body">
              <FormGroup>
                <Label htmlFor="c"><em>Are you sure want to delete this?</em></Label>
              </FormGroup>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.saveComprModal1} toggle={this.toggleSaveComprModal1}>
          <ModalBody>
            {loader && <Loading />}
            <Form method="post" onSubmit={this.handleCreateComparison1} id='createComparison'>
              <div className="action-button d-flex justify-content-end">
                <Button type="button" onClick={this.toggleSaveComprModal1}>Abandon</Button>
                <Button type="submit">Save changes</Button>
              </div>
              <div className="modal-body">
                <FormGroup>
                  <Label for="comparisonName"><em>Name this comparison</em></Label>
                  <Input type="text" id="ncomparisonName" name='title' onChange={(e) => { this.handleChange1(e) }} />
                  {errors1.errTitle && <p className="error-text"> <em> {errors1.errTitle} </em></p>}
                </FormGroup>
              </div>
            </Form>
          </ModalBody>
        </Modal>

      </Fragment >
    )
  }
}


const mapStateToProps = state => ({
  createContractData: state.CreateContract,
  deleteContract: state.DeleteContract,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setContractComparisonData: (data) => { dispatch({ type: 'SET_COMPARISON_CONTRACT_DATA', payload: { data: data } }) },
    setContractData: (data) => { dispatch({ type: 'SET_COMPARISON_CONTRACT', payload: { data: data } }) }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Filter)

