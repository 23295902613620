import React, { Component, Fragment } from 'react'
import {
  Form,Nav, TabContent, TabPane, NavItem, Label, Button, FormGroup, Input, CustomInput, Modal, ModalBody, CardHeader, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, UncontrolledCollapse, Card
} from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import Loading from '../../../../components/Loading'
import config from '../../../../config.json';
import { ToastContainer,toast } from 'react-toastify';
import http from '../../../../httpService/httpService';
import autosize from 'autosize';

const initialState = {
    edittoggleEFCPayment: false,
    toggleBtn: '',  
    conditionTitle: 'Further contract features',
    conditionItem : [],
    type:'extra_feature_payment_conditions',
    loader:false,
    errors :  { errTitle : ''},
    token:localStorage.getItem('cccAuthToken'),
    checkedBtn : [],
    extra_feature_condition : [],
    eventcategorytitle: 'Select Category',
    eventCategoryItem: []
} 
class EditExtraFeatureCondition extends Component {
  constructor(props) {
    super(props)
  }

  state = initialState;

  componentWillMount() {
     
   if(this.props.efd){
      this.setState({ initialState });
      const efd = this.props.efd;
      let extra_feature_condition  = [];
      const checkedBtn = [];

      efd.map(f => 
          { extra_feature_condition.push({ 
            fid : f.condition_id , 
            max_claim_amount_pound : f.max_claim_amount_pound ,
            contract_id :f.contract_id,
            defination:f.defination,
            benefit_reduced:f.benefit_reduced,
            id:f.id
          })
          checkedBtn.push(parseInt(f.condition_id));  
        }
      )

      this.setState({ extra_feature_condition });      
      this.props.setEditExtraFeatureData(extra_feature_condition);
      this.setState({ checkedBtn });  
    }
  } 

  componentWillReceiveProps(nextProps){     

    if(nextProps.conditions && nextProps.conditions.type == 'FURTHER_LIST'){
      const cc = nextProps.conditions.data;
      const conditionItem = cc.sort((a, b) => a.title.localeCompare(b.title))
      this.setState({ conditionItem });  
      autosize(document.querySelectorAll('textarea'));      
    }

    if(nextProps.efd){
      this.setState({  initialState });
      const efd = nextProps.efd;
      let extra_feature_condition  = [];
      const checkedBtn = [];
      
      efd.map(f => 
        { 
          extra_feature_condition.push({ 
            fid : f.condition_id , 
            max_claim_amount_pound : f.max_claim_amount_pound ,
            contract_id :f.contract_id,
            defination:f.defination,
            benefit_reduced:f.benefit_reduced,
            id:f.id
          })
          checkedBtn.push(parseInt(f.condition_id));   
        }
      )
      this.props.setEditExtraFeatureData(extra_feature_condition);
      this.setState({ extra_feature_condition });
      this.setState({ checkedBtn }); 

    }
    
  }

  handleEFCClick = (e,id,field) =>{
    let extra_feature_condition  = [];
    if(field == 'efc'){
     
      if(e.target.checked == true){
        extra_feature_condition = this.state.extra_feature_condition.concat({ fid : id});
        this.setState({ extra_feature_condition });
      }else{
        const ofl = this.state.extra_feature_condition;
        extra_feature_condition = ofl.filter(fl => fl.fid != id);
        this.setState({ extra_feature_condition });
      }
      
    }

    if(field == 'prc'){
      extra_feature_condition = this.state.extra_feature_condition;
      const apc = extra_feature_condition.find(fl => fl.fid == id); 

      if(apc){
        const index = extra_feature_condition.indexOf(apc);
        extra_feature_condition[index]={...apc,benefit_reduced : e.target.checked == true ? 'y' :'n' };
        this.setState({ extra_feature_condition });
      }    
      
    }

    this.props.setEditExtraFeatureData(extra_feature_condition);
  }

   handleEFCChange = (e,id) =>{

    const { name , value  } = e.target;
    const extra_feature_condition = this.state.extra_feature_condition;
    const fpc = extra_feature_condition.find(fl => fl.fid == id);  

    if(fpc){

      const index = extra_feature_condition.indexOf(fpc);
      extra_feature_condition[index][name] = value;
      this.setState({extra_feature_condition });
      this.props.setEditExtraFeatureData(extra_feature_condition);

    }    

  }

  async componentDidMount(){

    await http.post(process.env.REACT_APP_API_URL+'/get-conditions-lists', { type :this.state.type },{
      headers: {
        'Authorization': `Bearer ${this.state.token}`
      }
    })
    .then((response) => { 
      const conditionItem = response.data.data;
      this.setState( { conditionItem });
      autosize(document.querySelectorAll('textarea'));
    })
    .catch((error) => {
      if(error.response && error.response.status != 200){
         toast.error(error.response.data.message);
      }
    });
    
  }

  toggleDiv = (id) => {
    const checkedBtn = this.state.checkedBtn;
    if(this.state.toggleBtn == id){
      this.setState({ toggleBtn: ''})
    }else{
      if(checkedBtn.includes(id)){
         this.setState({ toggleBtn: id})
       } 
    }
      
  }

  handleChecked = (id) => {
    const checkedBtn = this.state.checkedBtn;
    if(checkedBtn.includes(id)){
      const filteredBtn= checkedBtn.filter(item => item !== id)
      this.setState({ checkedBtn: filteredBtn })

    }else{
      const filteredBtn = [...this.state.checkedBtn,id];
      this.setState({ checkedBtn: filteredBtn })
    }    
  }

  handleChange = (e) => {
    const { name , value  } = e.target;
    const cData = {...this.state.cData};
    cData[name]=value;
    this.setState({cData });
  }

  toggleAddConditionModal = () => {
    this.setState({
      addConditionModal: !this.state.addConditionModal
    });
    this.setState({ errors : { errTitle : ''} });
    this.setState({ 
      cData:{ title:'',id:''},
      eventcategorytitle: 'Select Category',
      gendertitle : 'Select Gender'
    });
  }

  validateCreateCondition = () => {
    const { cData  } = this.state;    
    const errors = {};

    if (!cData.title) {
      errors.errTitle = 'The condition needs a name';
    }
    
    if (!cData.gender) {
      errors.errGender = 'Please select gender Specific';
    }
    if (!cData.event_description) {
      errors.errEventDescription = 'Please enter event description';
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0 ? null : errors;
  }

  handleCreateCondition = e => {
    e.preventDefault();
    const { cData } = this.state;

     let title = cData.title;
    let gender = cData.gender;
    let event_description = cData.event_description;
    let type = this.state.type;

    if(this.validateCreateCondition() === null){
      this.setState({ loader:true });      
      http.post(process.env.REACT_APP_API_URL+'/create-condition', {  title,gender,event_description, type },{
        headers: {
          'Authorization': `Bearer ${this.state.token}`
        }
      })
      .then((response) => { 
        this.setState({ loader:false });       
        toast.success(response.data.message);
         const cc = [...this.state.conditionItem,{
            title: response.data.data.title,
            event_description: response.data.data.event_description,
            gender: response.data.data.gender,
            id: response.data.data.id,
            status: response.data.data.status
         }];
        const conditionItem = cc.sort((a, b) => a.title.localeCompare(b.title))
        
        this.toggleAddConditionModal();
        this.setState({ conditionItem });
        autosize(document.querySelectorAll('textarea'));
        this.props.setCondtionData(conditionItem)
      })
      .catch((error) => {
        this.setState({ loader:false });   
        if(error.response && error.response.status != 200){
           toast.error(error.response.data.message);
        }
      });
    }   
  }

  moveCaretAtEnd = (e) =>{
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`; 
  }

  handleChangeGender = (e) => {
    const { value } = e.target;
    const cData = { ...this.state.cData };
    cData['gender'] = value;
    this.setState({ cData });
    this.setState({ 'gendertitle' : value })
  }

  render() {    
    const { eventcategorytitle,addConditionModal,checkedBtn,conditionItem,loader,errors,edittoggleEFCPayment,toggleBtn } = this.state
    return (
      <Fragment>
         {loader && <Loading />}
         <Card>
          <CardHeader>
            <Button id="edittoggleEFCPayment" className={classnames('card-link', { 'active': edittoggleEFCPayment })} onClick={() => { this.setState({ edittoggleEFCPayment: !edittoggleEFCPayment }) }}>{ this.state.conditionTitle} ({ this.state.extra_feature_condition.length })</Button>
          </CardHeader>
          <UncontrolledCollapse toggler="#edittoggleEFCPayment" className="inner-collapse">            
            <div className="sub-title d-flex justify-content-end align-items-center">
              <Button onClick={this.toggleAddConditionModal}>Add contract features to database</Button>
            </div>
            <Scrollbars className={classnames('scrollable-inner-accordion', { 'long-scroll': false })}
              renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
              renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
              renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
              renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
              <div id="editEFCAccordian" className="inner-accordion">
                { conditionItem.map(cl =>{
                 
                 const extra_feature_condition = this.state.extra_feature_condition;
                 let efc = extra_feature_condition.find(fl => fl.fid == cl.id); 
                  
                  if(!efc){                   
                    efc = {
                      fid : '', 
                      max_claim_amount_pound : '' ,
                      contract_id :'',
                      defination:'',
                      benefit_reduced:'',
                      id:''
                    }                    
                  }

                return <><Card className={classnames({ 'active': (toggleBtn == cl.id ? true :false) })}>
                  <CardHeader>
                    <CustomInput type="checkbox" onClick={ (e) => { this.handleChecked(cl.id); this.handleEFCClick(e,cl.id,'efc') } }  checked = { (checkedBtn.includes(cl.id)? true :false) } id={`editnewEFCHead1-`+cl.id} />
                    <Button id={`edittoggleEFCPayment-`+cl.id} className={classnames('card-link', { 'active': (toggleBtn == cl.id ? true :false) })} onClick={() => this.toggleDiv(cl.id)}>{ cl.title }</Button>
                  </CardHeader>
                  <UncontrolledCollapse isOpen={ (toggleBtn == cl.id ? true :false) }  toggler={`#edittoggleEFCPayment-`+cl.id}>
                    <ul className="common-input-list mb-0">                      
                      
                      <li className="d-flex justify-content-between align-items-center">
                        <Label>MAXIMUM CLAIM AMOUNT(£)</Label>
                        <Input type="text" value={efc.max_claim_amount_pound} name='max_claim_amount_pound' onChange={ (e) => this.handleEFCChange(e,cl.id) }/>
                      </li>                      
                      <li className="d-flex justify-content-between align-items-center">
                        <Label className="form-check-label" htmlFor={`editnewEfchk2-`+cl.id} >
                          Plan benefit reduced
                          </Label>
                        <CustomInput type="checkbox" checked = { efc.benefit_reduced == 'y'  ? 'checked': '' } name='benefit_reduced' onClick={ (e) => this.handleEFCClick(e,cl.id,'prc') } id={`editnewEfchk2-`+cl.id}  />
                      </li>
                      <li className="definition-input">
                        <Label>condition definition</Label>
                        <Scrollbars className="scrollable-textarea"
                          renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                          renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                          renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                          renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                          <Input type="textarea" rows={10} onFocus={this.moveCaretAtEnd} value={efc.defination} name='defination' onChange={ (e) => this.handleEFCChange(e,cl.id) } placeholder="You can write condition definition here...." />
                        </Scrollbars>
                      </li>
                    </ul>
                  </UncontrolledCollapse>
                </Card>                
                  </>
                }
                )}



              <Modal isOpen={this.state.addConditionModal} toggle={this.toggleAddConditionModal}>
                    <ModalBody>
                      {loader && <Loading />}
                      <Form method="post" onSubmit= { this.handleCreateCondition } id='createCondition'>
                        <div className="action-button d-flex justify-content-end">
                          <Button type="button" onClick={this.toggleAddConditionModal }>Abandon</Button>
                          <Button type="submit">SAVE & CLOSE</Button>
                        </div>
                        <div className="modal-body">
                          <FormGroup>
                            <Label for="conditionName"><em>Condition name</em></Label>
                            <Input type="text" id="title" name='title' onChange={(e) => { this.handleChange(e) }}/>
                            { errors.errTitle && <p className="error-text"> <em> { errors.errTitle } </em></p> }
                          </FormGroup>
                          <FormGroup>
                            <Label for="eventCat"><em>Gender Specific</em></Label>
                            <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                              <DropdownToggle className="dropdown-toggle d-flex justify-content-between align-items-center">{ this.state.gendertitle ?? 'Select Gender'}</DropdownToggle>
                              <DropdownMenu>
                                <Scrollbars className="scrollable-dropdown"
                                  renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                                  renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                                  renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                                  renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                                
                                  <DropdownItem value='Male' onClick={(e) => { this.handleChangeGender(e) }}>Male</DropdownItem>
                                  <DropdownItem value='Female' onClick={(e) => { this.handleChangeGender(e) }}>Female</DropdownItem>
                                  <DropdownItem value='All' onClick={(e) => { this.handleChangeGender(e) }}>All</DropdownItem>
                                  
                                </Scrollbars>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                            {errors.errGender && <p className="error-text"> <em> {errors.errGender} </em></p>}
                          </FormGroup>

                          <FormGroup>
                            <Label for="eventDesc"><em>Event description</em></Label>
                            <Input type="textarea" name="event_description" onChange={(e) => { this.handleChange(e) }} id="EventDescription" />
                            {errors.errEventDescription && <p className="error-text"> <em> {errors.errEventDescription} </em></p>}
                          </FormGroup>
                        </div>
                      </Form>
                    </ModalBody>
                  </Modal>
                                        
              </div>
            </Scrollbars>
          </UncontrolledCollapse>
        </Card>           
      </Fragment>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    setEditExtraFeatureData:(data) => { dispatch({ type: 'SET_EDIT_EXTRAFEATURE_DATA', payload:{ data : data } }) },
    setCondtionData:(data) => {dispatch({ type: 'FURTHER_LIST', payload:{ data : data } }) }
  }
}
const mapStateToProps = state => ({
  conditions: state.conditions
})
export default connect(mapStateToProps,mapDispatchToProps)(EditExtraFeatureCondition)