export default (state={},action) => {
	switch (action.type) {
		case 'USER_PROFILE':
			return {
				...state,
				...action.payload.data,
				token: action.payload.token,
				dispatchType: action.type
			}
		default:
      	 return state
	}

}