import React, { Component, Fragment } from 'react'
import {
  Form,Nav, TabContent, TabPane, NavItem, Label, Button, FormGroup, Input, CustomInput, Modal, ModalBody, CardHeader, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, UncontrolledCollapse, Card
} from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import Loading from '../../../../components/Loading'
import config from '../../../../config.json';
import { ToastContainer,toast } from 'react-toastify';
import http from '../../../../httpService/httpService';

class EditChildrenCover extends Component {
  

  constructor(props) {
    super(props)
  }

  state = {
    etoggleChildInclude: false,
    etoggleChildInclude_1: false,
    loader: false,
    age_from:['Birth','30 Days'],
    age_to:[18,19,20,21,22,23],
    af_txt: this.props.contract.child_age_from,
    at_txt:this. props.contract.child_age_to,
    isChecked: this.props.contract.child_cover == 'y' ? true :false,
    isOpen:false
  } 

  changeAgeType = (e,type) =>{
    if(type == 'af'){
      this.setState({ af_txt: e.currentTarget.textContent });
    }
    if(type == 'at'){
      this.setState({ at_txt: e.currentTarget.textContent });
    }
  }

  handleChecked = (e) => {    
      this.setState({ isChecked : e.target.checked})    
  }

  toggleDiv = () => {
    if(this.state.isChecked == true){
      this.setState({ etoggleChildInclude_1 : true });
      this.setState({ isOpen : true });
    }else{
      this.setState({ etoggleChildInclude_1 : false });
      this.setState({ isOpen : false });
    }

    if(this.state.isOpen == true)
         this.setState({ isOpen : false });

  }  
 
  render() {    
    const { isChecked,isOpen,age_to,af_txt,at_txt,age_from,etoggleChildInclude,etoggleChildInclude_1,loader } = this.state
    const { contract } = this.props
    return (
      <Fragment>
         {loader && <Loading />}
         <Card>
              <CardHeader>
                <Button id="etoggleChildInclude" className={classnames('card-link', { 'active': etoggleChildInclude })} onClick={() => { this.setState({ etoggleChildInclude: !etoggleChildInclude }) }}>childrens cover (included)</Button>
              </CardHeader>
              <UncontrolledCollapse toggler="#etoggleChildInclude" className="inner-collapse">
                <Scrollbars className={classnames('scrollable-inner-accordion', { 'long-scroll': (isOpen == true ? true :false) })}
                  renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                  renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                  renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                  renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                  <div id="enewContractAccordion1" className="inner-accordion">
                    <Card className={classnames({ 'active': (isOpen == true ? true :false) })}>
                      <CardHeader>
                        <CustomInput checked = { isChecked } onClick={(e) => { this.handleChecked(e); this.props.onChecked(e,'child_cover');}}  type="checkbox" id="enewContractCheckHead1-1" />
                        <Button  id="etoggleChildInclude-1" className={classnames('card-link', { 'active': (isOpen == true ? true :false) })} onClick={ () => this.toggleDiv() }>CHILDRENS cover included</Button>
                      </CardHeader>
                      <UncontrolledCollapse isOpen={ isOpen } toggler="#etoggleChildInclude-1">
                        <ul className="common-input-list mb-0">                         
                          <li className="d-flex justify-content-between align-items-center">
                            <Label>Children age from</Label>
                            <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                              <DropdownToggle className="dropdown-toggle">{ af_txt }</DropdownToggle>
                              <DropdownMenu name='type'>                                
                                { age_from.map(af => <DropdownItem className={classnames({ active: af_txt === af })} value={ af } name='child_age_from' onClick={(e) => { this.changeAgeType(e,'af'); this.props.onChange(e)} }>{ af }</DropdownItem> )}                    
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                          <li className="d-flex justify-content-between align-items-center">
                            <Label>Children age to</Label>
                            <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                                <DropdownToggle className="dropdown-toggle">{ at_txt }</DropdownToggle>
                                <DropdownMenu name='type'>                                                            
                                  { age_to.map(at => <DropdownItem className={classnames({ active: at_txt == at })} value={ at } name='child_age_to' onClick={(e) => { this.changeAgeType(e,'at'); this.props.onChange(e) }}>{ at }</DropdownItem> )}                    
                                 </DropdownMenu>
                              </UncontrolledDropdown>
                          </li>
                          <li className="d-flex justify-content-between align-items-center">
                            <Label>Child max claim amount(£) </Label>
                            <Input type="text" name='claim_max_pound' value={ contract.claim_max_pound } onChange={(e) => this.props.onChange(e)}/>
                          </li>  
                          <li className="d-flex justify-content-between align-items-center">
                            <Label>Child max claim amount(%) </Label>
                            <Input type="text" name='claim_max_percentage' value={ contract.claim_max_percentage } onChange={(e) =>this.props.onChange(e)}/>
                          </li>  
                          <li className="d-flex justify-content-between align-items-center">
                            <Label className="form-check-label" htmlFor="child-plan-reduce">
                              PLAN COVER AMOUNT REDUCED
                              </Label>
                            <CustomInput type="checkbox" id="editbr" checked = { contract.benefit_reduce == 'y' ? true : false } name='benefit_reduce' onClick={ (e) => this.props.onChecked(e,'benefit_reduce') }  />
                          </li> 
                          <li className="d-flex justify-content-between align-items-center">
                            <Label>CHILD FUNERAL COVER AMOUNT(£) </Label>
                            <Input type="text" name='funeral_amount'  value={ contract.funeral_amount } onChange={(e) =>this.props.onChange(e)}/>
                          </li> 
                          <li className="d-flex justify-content-between align-items-center">
                            <Label className="form-check-label" htmlFor="ceditnon_uk_treatment">
                              ENHANCED PAYMENT NON UK TREATMENT
                              </Label>
                            <CustomInput type="checkbox" name='non_uk_treatment' checked = { contract.non_uk_treatment == 'y' ? true : false } onClick={ (e) => this.props.onChecked(e,'non_uk_treatment') } id="ceditnon_uk_treatment"  />
                          </li>                     
                        </ul>
                      </UncontrolledCollapse>
                    </Card>                        
                  </div>
                </Scrollbars>
              </UncontrolledCollapse>
            </Card>
      </Fragment>
    );
  }
}

export default connect()(EditChildrenCover)