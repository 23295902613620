/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
// eslint-disable-next-line import/no-cycle
import { history } from './index'

// Separate function for email validation
export function verifyEmail(value) {
  const emailRex = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (emailRex.test(value)) {
    return true
  }
  return false
}

// Separate function for password strength validation
export function verifyPassword(value) {
  const passwordRex = /^[\S]{6,15}$/
  if (passwordRex.test(value)) {
    return true
  }
  return false
}

// Separate function for field length validation
export function verifyLength(value, length) {
  if (value.length >= length) {
    return true
  }
  return false
}

export function verifyLink(value) {
  // eslint-disable-next-line no-useless-escape
  const linkRex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
  if (linkRex.test(value)) {
    return true
  }
  return false
}

export function verifyMobileNumber(value) {
  const mobRex = /^[0-9]{6,14}$/
  if (mobRex.test(value)) {
    return true
  }
  return false
}

export function verifyUsername(value) {
  const usernameRex = /^(?!.*__.*)(?!.*\.\..*)[a-z0-9_.]+$/
  if (usernameRex.test(value)) {
    return true
  }
  return false
}

export function verifyFullname(value) {
  const fullnameRegex = /^[a-zA-Z ]+$/
  if (fullnameRegex.test(value)) {
    return true
  }
  return false
}

export function formatDollar(amount) {
  const p = amount.toFixed(2).split('.')
  return `${p[0].split('').reverse().reduce((acc, num, i) => (num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc), '')}.${p[1]}`
}

export const S3_URL = 'https://egyftcards-media.s3-us-west-2.amazonaws.com/'

export const FRONT_URL = 'http://www.egyftcards.com/'

export const currencyData = [
  { value: 'aud', label: 'AUD - Australian dollar' },
  { value: 'cad', label: 'CAD - Canadian dollar' },
  { value: 'gbp', label: 'GBP - Pound' },
  { value: 'usd', label: 'USD - US Dollar' },
  { value: 'eur', label: 'EUR - Euro' }
]

export const countryData = [
  {
    id: 233,
    name: 'United States',
    iso3: 'USA',
    iso2: 'US',
    phone_code: '1',
    capital: 'Washington',
    currency: 'usd'
  },
  {
    id: 39,
    name: 'Canada',
    iso3: 'CAN',
    iso2: 'CA',
    phone_code: '1',
    capital: 'Ottawa',
    currency: 'cad'
  },
  {
    id: 14,
    name: 'Australia',
    iso3: 'AUS',
    iso2: 'AU',
    phone_code: '61',
    capital: 'Canberra',
    currency: 'aud'
  },
  {
    id: 232,
    name: 'United Kingdom',
    iso3: 'GBR',
    iso2: 'GB',
    phone_code: '44',
    capital: 'London',
    currency: 'gbp'
  },
  {
    id: 82,
    name: 'Germany',
    iso3: 'DEU',
    iso2: 'DE',
    phone_code: '49',
    capital: 'Berlin',
    currency: 'eur'
  },
  {
    id: 75,
    name: 'France',
    iso3: 'FRA',
    iso2: 'FR',
    phone_code: '33',
    capital: 'Paris',
    currency: 'eur'
  },
  {
    id: 107,
    name: 'Italy',
    iso3: 'ITA',
    iso2: 'IT',
    phone_code: '39',
    capital: 'Rome',
    currency: 'eur'
  },
  {
    id: 207,
    name: 'Spain',
    iso3: 'ESP',
    iso2: 'ES',
    phone_code: '34',
    capital: 'Madrid',
    currency: 'eur'
  },
  {
    id: 156,
    name: 'Netherlands The',
    iso3: 'NLD',
    iso2: 'NL',
    phone_code: '31',
    capital: 'Amsterdam',
    currency: 'eur'
  },
  {
    id: 22,
    name: 'Belgium',
    iso3: 'BEL',
    iso2: 'BE',
    phone_code: '32',
    capital: 'Brussels',
    currency: 'eur'
  },
  {
    id: 85,
    name: 'Greece',
    iso3: 'GRC',
    iso2: 'GR',
    phone_code: '30',
    capital: 'Athens',
    currency: 'eur'
  },
  {
    id: 177,
    name: 'Portugal',
    iso3: 'PRT',
    iso2: 'PT',
    phone_code: '351',
    capital: 'Lisbon',
    currency: 'eur'
  },
  {
    id: 15,
    name: 'Austria',
    iso3: 'AUT',
    iso2: 'AT',
    phone_code: '43',
    capital: 'Vienna',
    currency: 'eur'
  },
  {
    id: 74,
    name: 'Finland',
    iso3: 'FIN',
    iso2: 'FI',
    phone_code: '358',
    capital: 'Helsinki',
    currency: 'eur'
  },
  {
    id: 200,
    name: 'Slovakia',
    iso3: 'SVK',
    iso2: 'SK',
    phone_code: '421',
    capital: 'Bratislava',
    currency: 'eur'
  },
  {
    id: 105,
    name: 'Ireland',
    iso3: 'IRL',
    iso2: 'IE',
    phone_code: '353',
    capital: 'Dublin',
    currency: 'eur'
  },
  {
    id: 201,
    name: 'Slovenia',
    iso3: 'SVN',
    iso2: 'SI',
    phone_code: '386',
    capital: 'Ljubljana',
    currency: 'eur'
  },
  {
    id: 120,
    name: 'Latvia',
    iso3: 'LVA',
    iso2: 'LV',
    phone_code: '371',
    capital: 'Riga',
    currency: 'eur'
  },
  {
    id: 69,
    name: 'Estonia',
    iso3: 'EST',
    iso2: 'EE',
    phone_code: '372',
    capital: 'Tallinn',
    currency: 'eur'
  },
  {
    id: 147,
    name: 'Montenegro',
    iso3: 'MNE',
    iso2: 'ME',
    phone_code: '382',
    capital: 'Podgorica',
    currency: 'eur'
  },
  {
    id: 127,
    name: 'Luxembourg',
    iso3: 'LUX',
    iso2: 'LU',
    phone_code: '352',
    capital: 'Luxembourg',
    currency: 'eur'
  },
  {
    id: 135,
    name: 'Malta',
    iso3: 'MLT',
    iso2: 'MT',
    phone_code: '356',
    capital: 'Valletta',
    currency: 'eur'
  },
  {
    id: 6,
    name: 'Andorra',
    iso3: 'AND',
    iso2: 'AD',
    phone_code: '376',
    capital: 'Andorra la Vella',
    currency: 'eur'
  },
  {
    id: 145,
    name: 'Monaco',
    iso3: 'MCO',
    iso2: 'MC',
    phone_code: '377',
    capital: 'Monaco',
    currency: 'eur'
  },
  {
    id: 192,
    name: 'San Marino',
    iso3: 'SMR',
    iso2: 'SM',
    phone_code: '378',
    capital: 'San Marino',
    currency: 'eur'
  },
  {
    id: 238,
    name: 'Vatican City State (Holy See)',
    iso3: 'VAT',
    iso2: 'VA',
    phone_code: '379',
    capital: 'Vatican City',
    currency: 'eur'
  }
]

export const countryDataOld = [
  {
    value: 1,
    label: 'Andorra'
  },
  {
    value: 2,
    label: 'Australia'
  },
  {
    value: 3,
    label: 'Austria'
  },
  {
    value: 4,
    label: 'Belgium'
  },
  {
    value: 5,
    label: 'Canada'
  },
  {
    value: 6,
    label: 'Estonia'
  },
  {
    value: 7,
    label: 'Finland'
  },
  {
    value: 8,
    label: 'France'
  },
  {
    value: 9,
    label: 'Germany'
  },
  {
    value: 10,
    label: 'Greece'
  },
  {
    value: 11,
    label: 'Ireland'
  },
  {
    value: 12,
    label: 'Italy'
  },
  {
    value: 13,
    label: 'Latvia'
  },
  {
    value: 14,
    label: 'Luxembourg'
  },
  {
    value: 15,
    label: 'Malta'
  },
  {
    value: 16,
    label: 'Monaco'
  },
  {
    value: 17,
    label: 'Montenegro'
  },
  {
    value: 18,
    label: 'Netherlands'
  },
  {
    value: 19,
    label: 'Portugal'
  },
  {
    value: 20,
    label: 'San Marino'
  },
  {
    value: 21,
    label: 'Slovakia'
  },
  {
    value: 22,
    label: 'Slovenia'
  },
  {
    value: 23,
    label: 'Spain'
  },
  {
    value: 24,
    label: 'United Kingdom'
  },
  {
    value: 25,
    label: 'United States'
  },
  {
    value: 26,
    label: 'Vatican City State (Holy See)'
  }
]

export const countryCodeOld = [
  {
    value: '+1',
    label: '+1'
  },
  {
    value: '+30',
    label: '+30'
  },
  {
    value: '+31',
    label: '+31'
  },
  {
    value: '+32',
    label: '+32'
  },
  {
    value: '+33',
    label: '+33'
  },
  {
    value: '+34',
    label: '+34'
  },
  {
    value: '+39',
    label: '+39'
  },
  {
    value: '+43',
    label: '+43'
  },
  {
    value: '+44',
    label: '+44'
  },
  {
    value: '+49',
    label: '+49'
  },
  {
    value: '+61',
    label: '+61'
  },
  {
    value: '+351',
    label: '+351'
  },
  {
    value: '+352',
    label: '+352'
  },
  {
    value: '+353',
    label: '+353'
  },
  {
    value: '+356',
    label: '+356'
  },
  {
    value: '+358',
    label: '+358'
  },
  {
    value: '+371',
    label: '+371'
  },
  {
    value: '+372',
    label: '+372'
  },
  {
    value: '+376',
    label: '+376'
  },
  {
    value: '+377',
    label: '+377'
  },
  {
    value: '+378',
    label: '+378'
  },
  {
    value: '+379',
    label: '+379'
  },
  {
    value: '+382',
    label: '+382'
  },
  {
    value: '+386',
    label: '+386'
  },
  {
    value: '+421',
    label: '+421'
  }
]

export const sortingData = [
  {
    value: 'PRICE_LH',
    label: 'Price High To Low'
  },
  {
    value: 'PRICE_HL',
    label: 'Price Low to High'
  },
  {
    value: 'DT_LATEST',
    label: 'Newest Coupons'
  },
  {
    value: 'DT_OLDEST',
    label: 'Oldest Coupons'
  }
]
