import React, { Component, Fragment } from 'react'
import { Scrollbars } from 'react-custom-scrollbars';
import { UncontrolledCollapse, Button } from 'reactstrap';
import classnames from 'classnames';
import Header from '../../components/Header'
import autosize from 'autosize';
import { connect } from 'react-redux';
import http from '../../httpService/httpService'
import createFragment from "react-addons-create-fragment";
import Loading from '../../components/Loading'

class Landing extends Component {
	
	constructor(props) {
		super(props)
		this.state={
			isFilterActive: false,
			isEditActive: false,
			isUserActive: false,
			loader:false
		}
	}

	handleClose = (type) => {
		this.setState({ isFilterActive: false })
		this.setState({ isEditActive: false })
		this.setState({ isUserActive: false })
	}

	handleOpen = (type) => {
		if (type === 'filter') {
			this.setState({ isFilterActive: true })
			this.setState({ isEditActive: false })
		} else if (type === 'editItem') {
			this.setState({ isFilterActive: false })
			this.setState({ isEditActive: true })
		} else if (type === 'userItem') {
			this.setState({ isFilterActive: false })
			this.setState({ isUserActive: true })
		} else {
			this.setState({ isFilterActive: false })
			this.setState({ isEditActive: false })
		}

	}

	render() {
		const { loader } = this.state;
		return (	
			<Fragment>
				{loader && <Loading />}   
				<Header onOpen={this.handleOpen} />
				<main className="main-content d-flex">
					<section className="content-section">
						<section className="small-section error-section not-found-section text-center">
					      <div className="container">        
					        <h4>Welcome To Advisor 360</h4>
					      </div>
					    </section>
					</section>
				</main>
			</Fragment>
		)
	}
}

export default Landing
