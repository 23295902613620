import React, { Component, Fragment } from 'react'
import Header from '../../components/Header'
import { Link } from 'react-router-dom'
import { Button,Form, FormGroup, Label, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Scrollbars } from 'react-custom-scrollbars';
import closeIcon from '../../assets/images/close-icon.png';
import { ToastContainer,toast } from 'react-toastify';
import http from '../../httpService/httpService';
import Loading from '../../components/Loading'
import { history } from '../../index'

const initialState = {    
	source_text : '',
	channel_text : '',
	lead_type_text : '',
	advising_firm_text : '',
	title_text : '',
	account : { lead_type:'',source : '',channel:'', campaign:'',advising_firm:'',title:'',first_name:'',last_name:'',dob:''},
	errors :  { errlead_type : '',errsource : '',errchannel:'',errcampaign : '',erradvisingfirm : '',errtitle : '',errfirst_name : '',errlast_name : '',errdob : ''},
	loader : false,
};

class AddRecord extends Component {
	constructor(props) {
		super(props)
		this.state = {
			suggested_contact : [],
			record_id : '',
			source_text : '',
			channel_text : '',
			lead_type_text : '',
			advising_firm_text : '',
			title_text : '',
			account : { lead_type:'',source : '',channel:'', campaign:'',advising_firm:'',title:'',first_name:'',last_name:'',dob:''},
  			errors :  { errlead_type : '',errsource : '',errchannel:'',errcampaign : '',erradvisingfirm:'',errtitle : '',errfirst_name : '',errlast_name : '',errdob : ''},
			loader : false
		}		
		
	}
	setText = (e,type) => {
	    this.setState({ [type+"_text"]: e.currentTarget.textContent })

	    const account = {...this.state.account};
	    account[type]=e.currentTarget.value;
	    this.setState({account });

	}

	validate = () => {
	    const { account  } = this.state;    
	    const errors = {};

	    if(account.lead_type == ''){
	      errors.errlead_type = 'Lead Type is required field';
	    }

	    if(account.source == ''){
	      errors.errsource = 'Source is required field';
	    }

	    if(account.channel == ''){
	      errors.errchannel = 'Channel is required field';
	    }

	    if(account.campaign === ''){
	      errors.errcampaign = 'Campaign is required field';
	    }
	    if(account.advising_firm === ''){
	      errors.erradvisingfirm = 'Advising Firm is required field';
	    }
	    if(account.title === ''){
	      errors.errtitle = 'Title is required field';
	    }
	    if(account.first_name === ''){
	      errors.errfirst_name = 'First Name is required field';
	    }
	    if(account.last_name === ''){
	      errors.errlast_name = 'Last Name is required field';
	    }
	    if(account.dob === ''){
	      errors.errdob = 'Dob is required field';
	    }

	    this.setState({ errors });

	    if(Object.keys(errors).length > 0){
	      Object.keys(errors).map(c => toast.error(errors[c]) )
	    }
	    
	    return Object.keys(errors).length === 0 ? null : errors;

	}
	handleChange = (e) => {
	    const { name , value  } = e.target;
	    const account = {...this.state.account};
	    account[name]=value;
	    this.setState({account });
	}

	handleSubmit = e => {

	    e.preventDefault();
	    const { account } = this.state;

	    let lead_type =account.lead_type; 
	    let source = account.source;
	    let channel = account.channel;
	    let campaign = account.campaign;
	    let advising_firm = account.advising_firm;
	    let title = account.title;
	    let first_name = account.first_name;
	    let last_name = account.last_name;
	    let dob = account.dob;

	    let record_id = this.state.record_id;
	    if(this.validate() === null){
	    	if(record_id > 0){
	    		const addrec = 1;
		    	this.setState({ loader:true });
		        const token = localStorage.getItem('cccAuthToken');
		        http.post(process.env.REACT_APP_API_URL+'/add-contact-record', { record_id,title,first_name,last_name,dob,addrec },{
		          headers: {
		            'Authorization': `Bearer ${token}`
		          }
		        })
		        .then((response) => {  
		        	this.setState({ loader:false });	
					this.setState(initialState);
					toast.success(response.data.message);	  
					document.getElementById("addRecord").reset();	
					history.push(process.env.PUBLIC_URL+"/get-record/"+record_id);
					          
		        })
		        .catch((error) => {
		          this.setState({ loader:false });   
		          if(error.response && error.response.status != 200){
		             toast.error(error.response.data.message);
		          }
		        });
	    		return true;
	    	}else{
	    		this.setState({ loader:true });
		        const token = localStorage.getItem('cccAuthToken');
		        http.post(process.env.REACT_APP_API_URL+'/create-record', { lead_type ,source, channel, campaign,advising_firm,title,first_name,last_name,dob },{
		          headers: {
		            'Authorization': `Bearer ${token}`
		          }
		        })
		        .then((response) => {  
		        	this.setState({ loader:false });
					const suggested_contact =response.data.data.suggested_contact;
					const record_id =response.data.data.record_id;

					if(suggested_contact.length == 0){
						document.getElementById("addRecord").reset();
						this.setState(initialState);
						history.push(process.env.PUBLIC_URL+"/get-record/"+record_id);
					}
					this.setState({ suggested_contact : suggested_contact,record_id :record_id })
					toast.success(response.data.message);	  
						          
		        })
		        .catch((error) => {
		          this.setState({ loader:false });   
		          if(error.response && error.response.status != 200){
		             toast.error(error.response.data.message);
		          }
		        });
	    	}
	    }
	       
	}

	importCustomer = (customer_id,record_id) => {
	    
	    this.setState({ loader:true });
        const token = localStorage.getItem('cccAuthToken');
        http.post(process.env.REACT_APP_API_URL+'/import-customer-to-record', { customer_id ,record_id},{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => {
			this.setState({ loader:false });    
			this.setState(initialState);
			toast.success(response.data.message);
			history.push(process.env.PUBLIC_URL+"/get-record/"+record_id);         
        })
        .catch((error) => {
          this.setState({ loader:false });   
          if(error.response && error.response.status != 200){
             toast.error(error.response.data.message);
          }
        });	   
	}

	render() {
		const { advising_firm_text,suggested_contact,source_text,channel_text,lead_type_text,title_text,record_id,loader } = this.state;
		return (
			<Fragment>
				{loader && <Loading />}
				<Header onOpen={this.handleOpen} routename={ this.props.routename } />
				<main className="main-content">
					<div className="common-side-section">
						<section className="inner-section">
							<b className="text-uppercase">Create new record</b>
							<p className="d-flex justify-content-between">
								<b className="text-uppercase">Marketing</b>
								<Button color="link" tag={Link} to={`${process.env.PUBLIC_URL}`} className="close-btn">
									<img src={closeIcon} alt="close" />
								</Button>
							</p>
							<Form method="post" onSubmit= { this.handleSubmit } id='addRecord'  autocomplete="off">
							<div className="common-form">
								<FormGroup className="d-flex align-items-center">
									<Label for="source">Source</Label>
									<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
										<DropdownToggle className="dropdown-toggle">{ source_text }</DropdownToggle>
										<DropdownMenu>
											<DropdownItem value='policystore.co.uk' onClick={(e)=>this.setText(e,'source')}>POLICYSTORE.CO.UK</DropdownItem>
											<DropdownItem value='findingmortgages.co.uk' onClick={(e)=>this.setText(e,'source')}>FINDINGMORTGAGES.CO.UK</DropdownItem>
											<DropdownItem value='recommendation' onClick={(e)=>this.setText(e,'source')}>RECOMMENDATION</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								</FormGroup>
								<FormGroup className="d-flex align-items-center">
									<Label for="channel">Channel</Label>
									<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
										<DropdownToggle className="dropdown-toggle">{ channel_text }</DropdownToggle>
										<DropdownMenu>
											<DropdownItem value='facebook' onClick={(e)=>this.setText(e,'channel')}>FACEBOOK</DropdownItem>
											<DropdownItem value='google' onClick={(e)=>this.setText(e,'channel')}>GOOGLE</DropdownItem>
											<DropdownItem value='print advert' onClick={(e)=>this.setText(e,'channel')}>PRINT ADVERT</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								</FormGroup>
								<FormGroup className="d-flex align-items-center">
									<Label for="campaign">Campaign</Label>
									<Input type="text" id="campaign" name="campaign" placeholder="Enter Campaign" onChange={(e) => { this.handleChange(e) }} />
								</FormGroup>
								<FormGroup className="d-flex align-items-center">
									<Label for="leadType">Lead type</Label>
									<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
										<DropdownToggle className="dropdown-toggle">{ lead_type_text }</DropdownToggle>
										<DropdownMenu>
											<DropdownItem value='protection' onClick={(e)=>this.setText(e,'lead_type')}>PROTECTION</DropdownItem>
											<DropdownItem value='mortgage' onClick={(e)=>this.setText(e,'lead_type')}>MORTGAGE</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								</FormGroup>
								<FormGroup className="d-flex align-items-center">
									<Label for="leadType">Advising Firm</Label>
									<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
										<DropdownToggle className="dropdown-toggle">{ advising_firm_text }</DropdownToggle>
										<DropdownMenu>
											<DropdownItem value='policystore' onClick={(e)=>this.setText(e,'advising_firm')}>POLICYSTORE</DropdownItem>
											<DropdownItem value='one money' onClick={(e)=>this.setText(e,'advising_firm')}>ONE MONEY</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								</FormGroup>
								
							</div>
							<p className="bold-text text-uppercase">Contact 1</p>
							<div className="common-form">
								<FormGroup className="d-flex align-items-center">
									<Label for="title">Title</Label>
									<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
										<DropdownToggle className="dropdown-toggle">{ title_text }</DropdownToggle>
										<DropdownMenu>
											<DropdownItem value='mr' onClick={(e)=>this.setText(e,'title')}>Mr</DropdownItem>
											<DropdownItem value='mrs' onClick={(e)=>this.setText(e,'title')}>Mrs</DropdownItem>
											<DropdownItem value='miss' onClick={(e)=>this.setText(e,'title')}>Miss</DropdownItem>
											<DropdownItem value='ms' onClick={(e)=>this.setText(e,'title')}>Ms</DropdownItem>
											<DropdownItem value='dr' onClick={(e)=>this.setText(e,'title')}>Dr</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								</FormGroup>
								<FormGroup className="d-flex align-items-center">
									<Label for="first_name">First name</Label>
									<Input type="text" id="first_name" name="first_name" placeholder="Enter First name" onChange={(e) => { this.handleChange(e) }} />
								</FormGroup>
								<FormGroup className="d-flex align-items-center">
									<Label for="lastName">last name</Label>
									<Input type="text" id="last_name" name="last_name" placeholder="Enter Last name" onChange={(e) => { this.handleChange(e) }} />
								</FormGroup>
								<FormGroup className="d-flex align-items-center">
									<Label for="dob">DOB</Label>
									<Input type="date" id="dob" name="dob" placeholder="Enter DOB" onChange={(e) => { this.handleChange(e) }} />
								</FormGroup>
								<div className="text-right">
									<Button type="submit" className="succes-btn">create</Button>
								</div>
							</div>
							</Form>
							{ suggested_contact.length > 0 ? 
							<Fragment>
								<p className="mb-1">We’ve found the following contacts matching that description.</p>
								<p>Do you want to create a new contact or import existing contact. </p>
								<div className="customer-list">
									{ suggested_contact.map(con =>
										<div className="customer-item">
											<div className="title d-flex justify-content-between align-items-center">
												<span className="text-uppercase">customer ID : { con.id}</span>
												<Button className="succes-btn"  onClick={ () => this.importCustomer(con.id,record_id) }>IMPORT</Button>
											</div>
											<div className="common-form">
												<FormGroup className="d-flex align-items-center">
													<Label for="customerName1">Name</Label>
													<Input type="text" id="customerName1" disabled placeholder="" value = { con.first_name+" "+con.last_name } />
												</FormGroup>
												<FormGroup className="d-flex align-items-center">
													<Label for="customerDOB1">DOB</Label>
													<Input type="date" id="customerDOB1" disabled placeholder="" value = { con.dob} />
												</FormGroup>
												<FormGroup className="d-flex">
													<Label for="customeraddress1">Address</Label>
													<Input type="textarea" id="customeraddress1" disabled placeholder="" value = { con.home_address_1 } />
												</FormGroup>
											</div>
										</div>
									)}
								</div>
							</Fragment> : '' }
						</section>
					</div>
				</main>
			</Fragment>
		)
	}
}
export default AddRecord