import React, { Component, Fragment } from 'react'
import { Scrollbars } from 'react-custom-scrollbars';
import { UncontrolledCollapse, Button } from 'reactstrap';
import classnames from 'classnames';
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import NotFound from '../../components/NotFound'
import SelectComparison from '../../components/SelectComparison'
import Error from '../../components/Error'
import Filter from './Filter.jsx'
import Edit from './Edit.jsx'
import User from './User.jsx'
import aigLogo from '../../assets/images/aig-logo.png';
import rightArrow from '../../assets/images/right-arrow-white.png';
import umbrellaIcon from '../../assets/images/umbrella-icon.svg';
import autosize from 'autosize';
import { connect } from 'react-redux';
import http from '../../httpService/httpService'
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../../components/Loading'
import createFragment from "react-addons-create-fragment";
import cancer from '../../assets/images/cancer.png'
import heart from '../../assets/images/heart.png'
import brain from '../../assets/images/brain.png'
import organ from '../../assets/images/organ.png'
import injury from '../../assets/images/injury.png'
import ear from '../../assets/images/ear.png'
import child from '../../assets/images/child-event.png'
import { history } from '../../index'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


var inct = 1;
var inct1 = 1;
var inct2 = 1;
class Home extends Component {
	// document.title = 'Home'
	constructor(props) {
		super(props)
		this.state = {
			addRowCollapse: false,
			isRowCollapse1: false,
			isRowCollapse2: false,
			isRowCollapse3: false,
			isRowCollapse4: false,
			isRowCollapse5: false,
			isRowCollapse6: false,
			isRowCollapse7: false,
			isRowCollapse8: false,
			isRowCollapse9: false,
			isRowCollapse10: false,
			isRowCollapse11: false,
			isRowCollapse12: false,
			isRowCollapse13: false,
			isRowCollapse14: false,
			isFilterActive: false,
			isEditActive: false,
			isUserActive: false,
			comparison_contract: [],
			dispComarison: false,
			fullPayment: [],
			additionPayment: [],
			childspecific: [],
			extraFeature: [],
			ec: [],
			ac: [],
			token: localStorage.getItem('cccAuthToken'),
			conid: [],
			loader: false,
			extra_data: { title: '', con_data: [] },
			contracts: []
		}

	}


	handleClose = (type) => {
		if (type == 'usr') {
			history.push(process.env.PUBLIC_URL + "/comparison");
		}
		this.setState({ isFilterActive: false })
		this.setState({ isEditActive: false })
		this.setState({ isUserActive: false })
	}

	handleOpen = (type) => {
		if (type === 'filter') {
			this.setState({ isFilterActive: true })
			this.setState({ isEditActive: false })
		} else if (type === 'editItem') {
			this.setState({ isFilterActive: false })
			this.setState({ isEditActive: true })
		} else if (type === 'userItem') {
			this.setState({ isFilterActive: false })
			this.setState({ isUserActive: true })
		} else {
			this.setState({ isFilterActive: false })
			this.setState({ isEditActive: false })
		}

	}

	compareArrays = (first, second) => {
		//write type error
		return first.every((e) => second.includes(e)) && second.every((e) => first.includes(e));
	}

	async componentWillReceiveProps(nextProps) {

		if (nextProps.contractComparisonData && nextProps.contractComparisonData.type == 'SET_COMPARISON_CONTRACT_DATA') {

			const comparison_contract = nextProps.contractComparisonData.data;
			var conid = JSON.parse(comparison_contract);

			if (this.compareArrays(conid, this.state.conid) == false) {
				this.setState({ conid });
				this.setState({ loader: true });

				if (conid.length > 0) {
					await http.post(process.env.REACT_APP_API_URL + '/get-seleted-contract-comparison', { contractIds: JSON.stringify(conid), }, {
						headers: {
							'Authorization': `Bearer ${this.state.token}`
						}
					})
						.then((response) => {
							this.setState({
								contracts: response.data.data.contract,
								ac: response.data.data.ac,
								additionPayment: response.data.data.additionPayment,
								childspecific: response.data.data.childspecific,
								ec: response.data.data.ec,
								extraFeature: response.data.data.extraFeature,
								fullPayment: response.data.data.fullPayment,
								isRowCollapse1: false,
								isRowCollapse2: false,
								isRowCollapse3: false,
								isRowCollapse4: false,
								isRowCollapse5: false,
								isRowCollapse6: false,
								isRowCollapse7: false,
								isRowCollapse8: false,
								isRowCollapse9: false,
								isRowCollapse10: false,
								isRowCollapse11: false,
								isRowCollapse12: false,
								isRowCollapse13: false,
								isRowCollapse14: false,
							});
							this.setState({ dispComarison: true, loader: false });
						});
				} else {
					this.setState({ dispComarison: false, loader: false });
				}
			}

		}

		if (nextProps.contractComparisonData && nextProps.contractComparisonData.type == 'SET_COMPARISON_CONTRACT') {
			const comparison_contract = nextProps.contractComparisonData.data;
			var conid = JSON.parse(comparison_contract);

			if (this.compareArrays(conid, this.state.conid) == false) {
				this.setState({ conid });
				this.setState({ loader: true });

				if (conid.length > 0) {
					await http.post(process.env.REACT_APP_API_URL + '/get-seleted-contract-comparison', { contractIds: JSON.stringify(conid), }, {
						headers: {
							'Authorization': `Bearer ${this.state.token}`
						}
					})
						.then((response) => {
							this.setState({
								contracts: response.data.data.contract,
								ac: response.data.data.ac,
								additionPayment: response.data.data.additionPayment,
								childspecific: response.data.data.childspecific,
								ec: response.data.data.ec,
								extraFeature: response.data.data.extraFeature,
								fullPayment: response.data.data.fullPayment,
								// isRowCollapse1: false,
								// isRowCollapse2: false,
								// isRowCollapse3: false,
								// isRowCollapse4: false,
								// isRowCollapse5: false,
								// isRowCollapse6: false,
								// isRowCollapse7: false,
								// isRowCollapse8: false,
								// isRowCollapse9: false,
								// isRowCollapse10: false,
								// isRowCollapse11: false,
								// isRowCollapse12: false,
								// isRowCollapse13: false,
								// isRowCollapse14: false,
							});
							this.setState({ dispComarison: true, loader: false });
						});
				} else {
					this.setState({ dispComarison: false, loader: false });
				}
			}
		}

	}

	async componentDidMount() {

		await http.post(process.env.REACT_APP_API_URL + '/get-conditions-lists', { type: 'full_payment_conditions' }, {
			headers: {
				'Authorization': `Bearer ${this.state.token}`
			}
		})
			.then((response) => {
				const full_payment_condition = response.data.data;
				this.props.setCondtionData(full_payment_condition, 'full_payment_conditions')
			}).catch(error => {
				if (!error.response) {
					toast.error('Error: Network Error');
				}
			});

		await http.post(process.env.REACT_APP_API_URL + '/get-conditions-lists', { type: 'addtional_payment_conditions' }, {
			headers: {
				'Authorization': `Bearer ${this.state.token}`
			}
		})
			.then((response) => {
				const additional_payment_condition = response.data.data;
				this.props.setCondtionData(additional_payment_condition, 'addtional_payment_conditions')
			});

		await http.post(process.env.REACT_APP_API_URL + '/get-conditions-lists', { type: 'child_payment_conditions' }, {
			headers: {
				'Authorization': `Bearer ${this.state.token}`
			}
		})
			.then((response) => {
				const child_payment_conditions = response.data.data;
				this.props.setCondtionData(child_payment_conditions, 'child_payment_conditions')
			});

		await http.post(process.env.REACT_APP_API_URL + '/get-conditions-lists', { type: 'extra_feature_payment_conditions' }, {
			headers: {
				'Authorization': `Bearer ${this.state.token}`
			}
		})
			.then((response) => {
				const extra_feature_payment_conditions = response.data.data;
				this.props.setCondtionData(extra_feature_payment_conditions, 'extra_feature_payment_conditions')
			});

	}


	efc = (cfp, fpid, type) => {

		if (type == 'per-pound') {
			if (cfp) {
				const cfl = cfp.find(cf => cf.condition_id == fpid)
				if (cfl) {
					return (cfl.enhanced_max_percentage && cfl.enhanced_max_pound && cfl.enhanced_max_age) ? (cfl.enhanced_max_percentage + '% up to' + '£' + cfl.enhanced_max_pound + '/ To age ' + cfl.enhanced_max_age) : '-'
				} else {
					return '-'
				}
			}
		}

	}

	afc = (cfp, fpid, type) => {

		if (type == 'arrow') {
			if (cfp) {
				const cfl = cfp.find(cf => cf.condition_id == fpid)
				if (cfl) {
					return true
				} else {
					return false
				}
			}
		}

	}

	fpc = (cfp, fpid, type) => {

		if (type == 'arrow') {
			if (cfp) {
				const cfl = cfp.find(cf => cf.condition_id == fpid)
				if (cfl) {
					if (cfl.specified_cover == 'y') {
						return 'sc'
					}
					if (cfl.other_cover == 'y') {
						return 'oc'
					}
				} else {
					return false;
				}

			}
			return false;
		}
		if (type == 'defination') {
			if (cfp) {
				const cfl = cfp.find(cf => cf.condition_id == fpid)
				if (cfl) {
					return cfl.defination ?? '-'
				} else {
					return '-'
				}
			}
		}

		if (type == 'event_condition') {
			if (cfp) {
				const cfl = cfp.find(cf => cf.condition_id == fpid)

				if (cfl) {
					var html = ``;
					
					if(cfl.claim_payment_basis=='full'){
						html += `Full 100%`;
					}else if(cfl.claim_payment_basis=='severity'){
						html += (cfl.severity_payment_from && cfl.severity_payment_to) ? 'Severity -'+ (cfl.severity_payment_from + '% - ' + cfl.severity_payment_to + '%') : '-'
					}else if(cfl.claim_payment_basis=='partial'){
						if(cfl.partial_payment_amount_percentage && cfl.is_capped && cfl.is_capped =='No'){							
							html += 'Partial - '+ cfl.partial_payment_amount_percentage + '%(uncapped)'
						}else if(cfl.partial_payment_amount_percentage && cfl.partial_payment_amount_pound && cfl.is_capped && cfl.is_capped =='Yes'){
							html += 'Partial - '+ (cfl.partial_payment_amount_percentage + '% (capped £' + cfl.partial_payment_amount_pound)+")"
						}else{
							html += '-'
						}
						
					}else if(cfl.claim_payment_basis=='additional'){
						if(cfl.additional_payment_amount_percentage && cfl.additional_is_capped && cfl.additional_is_capped =='No'){							
							html += 'Additional - '+ cfl.additional_payment_amount_percentage + '%(uncapped)'
						}else if(cfl.additional_payment_amount_percentage && cfl.additional_payment_amount_pound && cfl.additional_is_capped && cfl.additional_is_capped =='Yes'){
							html += 'Additional - '+ (cfl.additional_payment_amount_percentage + '% (capped £' + cfl.additional_payment_amount_pound)+")"
						}else{
							html += '-'
						}
						
					}else{
						html += `Full 100%`;
					}
					
					html += `<ul class="payment-list">`;
					if(cfl.advanced_payment=='y'){
						html += `<li>Advanced payment</li>`;
					}

					if(cfl.enhanced_payment=='y'){
						html += `<li>Enhanced payment</li>`;
					}

					return html +=`</ul>`;

					// return `<p class="normal">100% of sum assured</p> <ul class="payment-list"><li>Advanced payment</li></ul>`;
					// return (cfl.enhanced_max_percentage && cfl.enhanced_max_pound) ? (cfl.enhanced_max_percentage + '% up to £' + cfl.enhanced_max_pound) : '-'
				} else {
					return '-'
				}
			}
		}

	}

	apcc = (cfp, apid, type) => {

		if (type == 'defination') {
			if (cfp) {
				const cfl = cfp.find(cf => cf.condition_id == apid)
				if (cfl) {
					return cfl.defination ?? '-'
				} else {
					return '-'
				}
			}
		}
		if (type == 'per-pound') {
			if (cfp) {
				const cfl = cfp.find(cf => cf.condition_id == apid)
				if (cfl) {
					if (cfl.specified_cover == 'y') {
						return (cfl.max_claim_amount_percentage && cfl.max_claim_amount_pound) ? (cfl.max_claim_amount_percentage + '% up to £' + cfl.max_claim_amount_pound) : '-'
					}
					if (cfl.other_cover == 'y') {
						return 'oc'
					}
					return false
				} else {
					return false
				}
			}
		}

		if (type == 'event_condition') {
			if (cfp) {
				const cfl = cfp.find(cf => cf.condition_id == apid)
				if (cfl) {
					return (cfl.max_claim_amount_percentage && cfl.max_claim_amount_pound) ? (cfl.max_claim_amount_percentage + '% up to £' + cfl.max_claim_amount_pound) : '-'
				} else {
					return '-'
				}
			}
		}

	}


	cpcc = (cfp, apid, type) => {

		if (type == 'arrow') {
			if (cfp) {
				if (cfp.find(cf => cf.condition_id == apid)) {
					return true
				}
			}
		}
		if (type == 'defination') {
			if (cfp) {
				const cfl = cfp.find(cf => cf.condition_id == apid)
				if (cfl) {
					return cfl.defination ?? '-'
				} else {
					return '-'
				}
			}
		}
		if (type == 'per-pound') {
			if (cfp) {
				const cfl = cfp.find(cf => cf.condition_id == apid)
				if (cfl) {
					return (cfl.max_claim_amount_percentage && cfl.max_claim_amount_pound) ? (cfl.max_claim_amount_percentage + '% up to £' + cfl.max_claim_amount_pound) : '-'
				} else {
					return '-'
				}
			}
		}

		if (type == 'event_condition') {
			if (cfp) {
				const cfl = cfp.find(cf => cf.condition_id == apid)
				if (cfl) {
					return (cfl.max_claim_amount_percentage && cfl.max_claim_amount_pound) ? (cfl.max_claim_amount_percentage + '% up to £' + cfl.max_claim_amount_pound) : '-'
				} else {
					return '-'
				}
			}
		}


	}

	getTotalClaimableEvents = (fp, ap, cs, ef) => {
		const fpc = fp ? fp.length : 0
		// const apc = ap ? ap.length : 0
		// const csc = fp ? cs.length : 0
		// const efc = ef ? ef.length : 0
		return fpc; // + apc + csc + efc;
	}

	exportPdf = async () => {

		const conid = this.state.conid;
		const extra_data = this.state.extra_data;

		this.setState({ loader: true });
		const token = this.state.token;
		await http.post(process.env.REACT_APP_API_URL + '/export-contract-comparison',
			{
				contractIds: JSON.stringify(conid),
				extra_data: JSON.stringify(extra_data),
			},
			{
				headers: {
					'Authorization': `Bearer ${token}`
				}
			})
			.then((response) => {

				var url = response.data.data;
				// window.open(url, 'Download');  
				this.setState({ loader: false });

				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('target', '_blank');
				document.body.appendChild(link);
				link.click();

			})
			.catch((error) => {
				if (error.response && error.response.status != 200) {
					toast.error(error.response.data.message);
				}
				this.setState({ loader: false });
			});

	}

	setExtraData = (e, type) => {
		if (type == 'title') {
			const extra_data = this.state.extra_data;
			extra_data['title'] = e.target.value
			this.setState({ extra_data });
		} else {
			const extra_data = this.state.extra_data;
			extra_data['con_data'][type] = e.target.value
			this.setState({ extra_data });
		}
	}

	eventCount = (event_category_id,data) => {


		var fpc = data[event_category_id].full_payment_condition.length ?? 0;
		//var apc = data[event_category_id].advance_payment_condition.length ?? 0;
		//var csc = data[event_category_id].child_specific_condition.length ?? 0;
		// return  fpc + apc + csc;
		return  fpc;
	}

	eventCountCC = (event_category_id,data) => {


		//var fpc = data[event_category_id].full_payment_condition.length ?? 0;
		//var apc = data[event_category_id].advance_payment_condition.length ?? 0;
		var csc = data[event_category_id].child_specific_condition.length ?? 0;
		// return  fpc + apc + csc;
		return  csc;
	}


	eventCondition = (event_category_id,data) => {		

			//var fpc = data[event_category_id].full_payment_condition;
			//if(event_category_id == 'c1'){

			var fpc = data[event_category_id].full_payment_condition;
			var apc = data[event_category_id].advance_payment_condition;
			var csc = data[event_category_id].child_specific_condition;
			//var epc = data[event_category_id].extra_feature_condition;

			var html = '';
			if(fpc.length > 0){

				fpc.map(fp => {
					html +=  `<li class="tiles-bg yellow-bg">`
					html +=  ( fp.pivot &&  fp.pivot.defination ) ?  `<button class="btn btn-link condition-toggle-btn" data-id="`+(inct)+`">` : ``;
					html +=  `<p class="capital">`+ fp.title +`</p>`
					html +=	`<p class="normal">Full payment condition</p>`;	
					html += ( fp.pivot &&  fp.pivot.defination ) ?	`</button>` : ``;		

					if(fp.pivot && fp.pivot.advanced_payment=='y' || fp.pivot.enhanced_payment=='y'){
						html += `<ul class="payment-list">`;

						if(fp.pivot.advanced_payment=='y'){
							html += `<li>Advanced payment</li>`;
						}

						if(fp.pivot.enhanced_payment=='y'){
							html += `<li>Enhanced payment
										<p class="small-font">to age `+fp.pivot.enhanced_max_age+` `+fp.pivot.enhanced_max_percentage+`% up to £`+fp.pivot.enhanced_max_pound+`</p>
									</li>`;
						}

						html +=`</ul>`;
					}
					
					if(fp.pivot &&  fp.pivot.defination){
						html +=  `<div class="condition-toggle-desc collapse `+inct+`" style="display:none">`+(fp.pivot.defination ?? '-')+`</div>`;
					}
					html +=  `</li>`;

					inct++;
				})
			}

			if(apc.length > 0){

				apc.map(ap => {
					html +=  `<li class="tiles-bg">`
					html +=  ( ap.pivot &&  ap.pivot.defination ) ?  `<button class="btn btn-link condition-toggle-btn" data-id="apc_`+(inct1)+`">` : ``;
					html += `<p class="capital">`+ ap.title +`</p>`
					html += ( ap.pivot &&  ap.pivot.defination ) ?	`</button>` : ``;	
					if(ap.pivot && ap.pivot.max_claim_amount_pound && ap.pivot.max_claim_amount_percentage){
						html +=  `<p class="normal">`+ap.pivot.max_claim_amount_percentage+`% of sum assured up to £`+ap.pivot.max_claim_amount_pound+`</p>`
					}

					if(ap.pivot &&  ap.pivot.defination){
						html +=  `<div class="condition-toggle-desc collapse apc_`+inct1+`" style="display:none">`+(ap.pivot.defination ?? '-')+`</div>`;
					}

					html +=`</li>`;
					inct1++;
				})				
			}

			if(csc.length > 0){

				csc.map(cs => {
					html +=  `<li  class="tiles-bg">`
					html +=  ( cs.pivot &&  cs.pivot.defination ) ?  `<button class="btn btn-link condition-toggle-btn" data-id="csc_`+(inct2)+`">` : ``;
					html += `<p class="capital">`+ cs.title +`</p>`;
					html += ( cs.pivot &&  cs.pivot.defination ) ?	`</button>` : ``;	
					if(cs.pivot && cs.pivot.max_claim_amount_pound && cs.pivot.max_claim_amount_percentage){
						html +=  `<p class="normal">`+cs.pivot.max_claim_amount_percentage+`% of sum assured up to £`+cs.pivot.max_claim_amount_pound+`</p>`
					}
					if(cs.pivot &&  cs.pivot.defination){
						html +=  `<div class="condition-toggle-desc collapse csc_`+inct2+`" style="display:none">`+(cs.pivot.defination ?? '-')+`</div>`;
					}
					html +=`</li>`;
					inct2++;
				})
			}

			// if(epc.length > 0){

			// 	epc.map(ep => {
			// 		html +=  `<li><p className="capital">`+ ep.title +`</p></li>`;
			// 	})
			// }
			
			return html;
		//}
	}




	render() {
		const { ac, additionPayment, childspecific, ec, extraFeature, 
			fullPayment, contracts, loader, isRowCollapse7, isRowCollapse8, 
			isRowCollapse9, isRowCollapse10, isRowCollapse11, isRowCollapse12, isRowCollapse13, isRowCollapse14, isRowCollapse6, 
			isRowCollapse5, isRowCollapse4, isRowCollapse3, addRowCollapse, isRowCollapse1,
			isFilterActive, isEditActive, isUserActive, comparison_contract, dispComarison, 
			isRowCollapse2 } = this.state

		return (
			<Fragment>
				{loader && <Loading />}
				<Header onOpen={this.handleOpen} routename={this.props.routename} />
				<main className="main-content d-flex">
					{/******* Sidebar *******/}
					<Sidebar onOpen={this.handleOpen} />
					{/******* Main Content Start *******/}
					<section className="content-section">
						{/* <NotFound /> */}
						{/* <Error /> */}

						{
							dispComarison == true ?
							<>
							<div className="home-table-wraper">
								<ul className="table-btns">
									<li><Button className="add-row" id="input-row" className={classnames('add-row', { 'active': addRowCollapse })} onClick={() => { this.setState({ addRowCollapse: !addRowCollapse }) }}></Button></li>
									<li><Button className="gen-pdf" onClick={() => this.exportPdf()}></Button></li>
								</ul>
								<Scrollbars className="scrollable-table" 
										renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
										renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
										renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
										renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<div className="home-table">
											<ul className="theme-table text-uppercase">
												<li className="table-head">
													<div>
														<div className="col-title">Contract comparison</div>
													</div>
													{contracts.map((cc, i) =>
														<div key={i}>
															<div className="col-title">
																{cc.provider_image != '' ?
																	<img src={cc.provider_image} alt="aig" />
																	: ''}
															</div>
														</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#input-row">
														<ul>
															<li className="input-row">
																<div><input type="text" className="form-control" placeholder="Add Row label" onChange={(e) => this.setExtraData(e, 'title')} /></div>
																{contracts.map((cc, i) =>
																	<div key={i}><input type="text" className="form-control" placeholder="Value" onChange={(e) => this.setExtraData(e, cc.id)} /></div>
																)}
															</li>
														</ul>
													</UncontrolledCollapse>
												</li>
												<li>
													<div>Contract provider</div>
													{contracts.map((cc, i) =>
														<div key={i}>{cc.provider ? cc.provider : '-'}</div>
													)}
												</li>
												<li>
													<div>Contract Name</div>
													{contracts.map((cc, i) =>
														<div key={i}>{cc.contract_name ? cc.contract_name : '-'}</div>
													)}

												</li>
												{/* <li>
													<div>ENHANCED CHILD COVER ADD ON</div>
													{contracts.map((cc, i) =>
														<div key={i}>{cc.enhanced_child_cover ? cc.enhanced_child_cover : '-'}</div>
													)}

												</li>
												<li>
													<div>ENHANCED CI COVER ADD ON</div>
													{contracts.map((cc, i) =>
														<div key={i}>{cc.enhanced_ci_cover ? cc.enhanced_ci_cover : '-'}</div>
													)}

												</li> 
												<li>
													<div>contract live date</div>
													{contracts.map((cc, i) =>
														<div key={i}>{cc.c_live_date != '' ? cc.c_live_date : '-'}</div>
													)}
												</li>
												<li>
													<div>contract end date</div>
													{contracts.map((cc, i) =>
														<div key={i}>{cc.c_end_date != '' ? cc.c_end_date : '-'} </div>
													)}
												</li> */}
												<li>
													<div>contract date</div>
													{contracts.map((cc, i) =>
														<div key={i}>
														{ (cc.status && cc.status == 'Current') ? cc.c_live_date + ' to present' : cc.c_live_date + ' TO ' + cc.c_end_date }
														</div>
													)}
												</li>
												<li>
													<div>contract status</div>
													{contracts.map((cc, i) =>
														<div key={i}>{cc.status ? cc.status : '-'}</div>
													)}
												</li>
												{/* <li>
													<div>TOTAL CLAIMABLE EVENTS</div>
													{contracts.map((cc, i) =>
														<div key={i}>{this.getTotalClaimableEvents(cc.full_payment_data_special, cc.additional_payment_data_special, cc.child_payment_data, cc.extra_feature_data)}</div>
													)}
												</li> */}
												<li className={classnames({ 'dark-blue': isRowCollapse1 })}>
													<div>ADULT EVENTS
													<Button id="payoutCond" className={classnames('close-toggle', { 'active': isRowCollapse1 })} onClick={() => { this.setState({ isRowCollapse1: !isRowCollapse1 }) }}></Button>
													</div>
													{contracts.map((cc, i) =>
														<div key={i}>{cc.full_payment_data_special ? cc.full_payment_data_special.length : 0}</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#payoutCond" defaultOpen={isRowCollapse1}>
														<ul className="toggle-row">
															{fullPayment.map((fp, i) =>
																<>
																	<li key={i} >
																		<div>{fp.title}</div>
																		{contracts.map((cc, i) =>
																			<div key={i}>
																				{
																					this.fpc(cc.full_payment_data, fp.condition_id, 'arrow') == false ? '-' :
																						[
																							(this.fpc(cc.full_payment_data, fp.condition_id, 'arrow') == 'sc' ?
																								<img className="right-icon" src={rightArrow} alt="right" /> :
																								createFragment({ data: '-', data1: <img className="umbrella-icon" src={umbrellaIcon} alt="right" /> })
																							)
																						]
																				}

																			</div>
																		)}
																		<Button id={"conDef1-" + fp.condition_id} className="full-btn"></Button>
																	</li>
																	<li className="collapse-row">
																		<UncontrolledCollapse toggler={"conDef1-" + fp.condition_id} >
																			<ul className="toggle-row">
																				<li className="cond-def-row" >
																					<div>CONDITION DEFINITION</div>

																					{contracts.map((cc, i) =>
																						<>
																							<div key={i}>
																								<div className="desc">
																									{ this.fpc(cc.full_payment_data, fp.condition_id, 'defination')}
																								</div>
																							</div>
																						</>
																					)}

																				</li>
																			</ul>
																		</UncontrolledCollapse>
																	</li>
																</>
															)}
														</ul>
													</UncontrolledCollapse>
												</li>

												<li className={classnames({ 'dark-blue': isRowCollapse2 })}>
													<div>ADULT ENHANCED payments
													<Button id="epc" className={classnames('close-toggle', { 'active': isRowCollapse2 })} onClick={() => { this.setState({ isRowCollapse2: !isRowCollapse2 }) }}></Button>
													</div>
													{contracts.map((cc, i) =>
														<div key={i}>{cc.enhance_full_payment_data ? cc.enhance_full_payment_data.length : 0}</div>
													)}
												</li>

												<li className="collapse-row">
													<UncontrolledCollapse toggler="#epc" defaultOpen={isRowCollapse2}>
														<ul className="toggle-row">
															{fullPayment.map((fp, i) => {
																if (ec[fp.title] != undefined)
																	return (
																		<li key={i}>
																			<div>{fp.title}</div>
																			{ec[fp.title].map(cc =>
																				<div>
																					{cc != '' ? cc : '-'}
																				</div>
																			)}
																		</li>
																	)
																return ''
															})}
														</ul>
													</UncontrolledCollapse>
												</li>

												<li className={classnames({ 'dark-blue': isRowCollapse3 })}>
													<div>ADULT ADVANCED PAYMENTS
													<Button id="apc" className={classnames('close-toggle', { 'active': isRowCollapse3 })} onClick={() => { this.setState({ isRowCollapse3: !isRowCollapse3 }) }}></Button>
													</div>
													{contracts.map((cc, i) =>
														<div key={i} >{cc.advance_full_payment_data ? cc.advance_full_payment_data.length : 0}</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#apc" defaultOpen={isRowCollapse3}>
														<ul className="toggle-row">

															{fullPayment.map((fp, i) => {
																if (ac[fp.title])
																	return (
																		<li key={i}>
																			<div>{fp.title}</div>
																			{ac[fp.title].map((cc, i) =>
																				<div key={i}>
																					{cc == 1 ? <img className="right-icon" src={rightArrow} alt="right" /> : '-'}
																				</div>
																			)}
																		</li>
																	)
																return ''
															})}
														</ul>
													</UncontrolledCollapse>
												</li>
												{/* <li className={classnames({ 'dark-blue': isRowCollapse4 })}>
													<div>Additional payment conditions
													<Button id="advancepayoutCond" className={classnames('close-toggle', { 'active': isRowCollapse4 })} onClick={() => { this.setState({ isRowCollapse4: !isRowCollapse4 }) }}></Button>
													</div>
													{contracts.map((cc, i) =>
														<div key={i}>{cc.additional_payment_data_special ? cc.additional_payment_data_special.length : 0}</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#advancepayoutCond" defaultOpen={isRowCollapse4}>
														<ul className="toggle-row">
															{additionPayment.map((apc, i) =>
																<>
																	<li key={i}>
																		<div>{apc.title}</div>
																		{contracts.map((cc, i) =>
																			<div key={i}>
																				

																				{this.apcc(cc.additional_payment_data, apc.condition_id, 'per-pound') == false ? '-' :
																					[
																						(this.apcc(cc.additional_payment_data, apc.condition_id, 'per-pound') == 'oc' ?
																							createFragment({ data: '-', data1: <img className="umbrella-icon" src={umbrellaIcon} alt="right" /> }) :
																							this.apcc(cc.additional_payment_data, apc.condition_id, 'per-pound')
																						)
																					]
																				}

																			</div>
																		)}
																		<Button id={"aconDef1-" + apc.condition_id} className="full-btn"></Button>
																	</li>
																	<li className="collapse-row">
																		<UncontrolledCollapse toggler={"aconDef1-" + apc.condition_id} >
																			<ul className="toggle-row">
																				<li className="cond-def-row" >
																					<div>CONDITION DEFINITION</div>

																					{contracts.map((cc, i) =>
																						<>
																							<div key={i}>
																								<div className="desc">
																									{this.apcc(cc.additional_payment_data, apc.condition_id, 'defination')}
																								</div>
																							</div>
																						</>
																					)}

																				</li>
																			</ul>
																		</UncontrolledCollapse>
																	</li>
																</>
															)}
														</ul>
													</UncontrolledCollapse>
												</li>
												*/}
												<li className={classnames({ 'dark-blue': isRowCollapse5 })}>
													<div>CHILDRENS COVER
													<Button id="childCover" className={classnames('close-toggle', { 'active': isRowCollapse5 })} onClick={() => { this.setState({ isRowCollapse5: !isRowCollapse5 }) }}></Button>
													</div>
													{contracts.map((cc, i) =>
														<div key={i}>{cc.child_cover == 'y' ? 'Included' : 'Not Included'} </div>
													)}


												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#childCover" defaultOpen={isRowCollapse5}>
														<ul className="toggle-row">
															<li>
																<div>COVERED FROM</div>
																{contracts.map((cc, i) =>
																	<div key={i}>{cc.child_age_from ? (cc.child_age_from + ' old') : '-'} </div>
																)}
															</li>
															<li>
																<div>COVERED TO</div>
																{contracts.map((cc, i) =>
																	<div key={i}>{cc.child_age_to ? ('Age ' + cc.child_age_to) : '-'} </div>
																)}
															</li>
															<li>
																<div>MAXIMUM CLAIM AMOUNT</div>
																{contracts.map((cc, i) =>
																	<div key={i}>
																		{cc.claim_max_percentage == null && cc.claim_max_percentage == null ? '-' : ''}
																		{cc.claim_max_percentage && (cc.claim_max_percentage + '% up to ')}
																		{cc.claim_max_pound && ('£' + cc.claim_max_pound)}
																	</div>
																)}

															</li>
															<li>
																<div>PLAN BENEFIT REDUCED</div>
																{contracts.map((cc, i) =>
																	<div key={i}>{cc.benefit_reduce == 'y' ? 'Yes' : 'No'} </div>
																)}
															</li>
															<li>
																<div>Non Uk treatment enhancement</div>
																{contracts.map((cc, i) =>
																	<div key={i}>{cc.non_uk_treatment == 'y' ? 'Yes' : 'No'} </div>
																)}
															</li>
															<li>
																<div>Child Funeral cover amount</div>
																{contracts.map((cc, i) =>
																	<div key={i}>{cc.funeral_amount ? cc.funeral_amount : '-'} </div>
																)}
															</li>
														</ul>
													</UncontrolledCollapse>
												</li>

												<li className={classnames({ 'dark-blue': isRowCollapse6 })}>
													<div>CHILD SPECIFIC EVENTS
													<Button id="childPayout" className={classnames('close-toggle', { 'active': isRowCollapse6 })} onClick={() => { this.setState({ isRowCollapse6: !isRowCollapse6 }) }}></Button>
													</div>
													{contracts.map((cc, i) =>
														<div key={i}>{cc.child_payment_data ? cc.child_payment_data.length : 0}</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#childPayout" defaultOpen={isRowCollapse6}>
														<ul className="toggle-row">
															{childspecific.map((cpc, i) =>
																<>
																	<li key={i} >
																		<div>{cpc.title}</div>
																		{contracts.map(cc =>
																			<div>
																				{this.cpcc(cc.child_payment_data, cpc.condition_id, 'per-pound')}
																			</div>
																		)}
																		<Button id={"ccconDef1-" + cpc.condition_id} className="full-btn"></Button>
																	</li>
																	<li className="collapse-row" key={i}>
																		<UncontrolledCollapse toggler={"ccconDef1-" + cpc.condition_id} >
																			<ul className="toggle-row">
																				<li className="cond-def-row" >
																					<div>CONDITION DEFINITION</div>

																					{contracts.map((cc, i) =>
																						<>
																							<div key={i} >
																								<div className="desc">
																									{this.cpcc(cc.child_payment_data, cpc.condition_id, 'defination')}
																								</div>
																							</div>
																						</>
																					)}

																				</li>
																			</ul>
																		</UncontrolledCollapse>
																	</li>
																</>
															)}
														</ul>
													</UncontrolledCollapse>
												</li>

												<li className={classnames({ 'dark-blue': isRowCollapse7 })}>
													<div>ADDITIONAL CONTRACT FEATURES
													<Button id="extraFeaturePayout" className={classnames('close-toggle', { 'active': isRowCollapse7 })} onClick={() => { this.setState({ isRowCollapse7: !isRowCollapse7 }) }}></Button>
													</div>
													{contracts.map(cc =>
														<div>{cc.extra_feature_data ? cc.extra_feature_data.length : 0}</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#extraFeaturePayout" defaultOpen={isRowCollapse7}>
														<ul className="toggle-row">
															{extraFeature.map(cpc =>
																<>
																	<li>
																		<div>{cpc.title}</div>
																		{contracts.map(cc =>
																			<div>
																				{this.fpc(cc.extra_feature_data, cpc.condition_id, 'arrow') == true ?
																					<img className="right-icon" src={rightArrow} alt="right" />
																					: '-'}
																			</div>
																		)}
																		<Button id={"acconDef1-" + cpc.condition_id} className="full-btn"></Button>
																	</li>
																	<li className="collapse-row">
																		<UncontrolledCollapse toggler={"acconDef1-" + cpc.condition_id} >
																			<ul className="toggle-row">
																				<li className="cond-def-row" >
																					<div>CONDITION DEFINITION</div>

																					{contracts.map((cc, i) =>
																						<>
																							<div key={i}>
																								<div className="desc">
																									{this.cpcc(cc.extra_feature_data, cpc.id, 'defination')}
																								</div>
																							</div>
																						</>
																					)}

																				</li>
																			</ul>
																		</UncontrolledCollapse>
																	</li>
																</>
															)}
														</ul>
													</UncontrolledCollapse>
												</li>
												<li>
													<div className="cat-title-box"><p className="cat-table-title text-uppercase">EVENTS COVERED BY CATEGORY</p>	</div>
												</li>
												<li className={classnames({ 'dark-blue': isRowCollapse8 })}>
													<div>Cancers & Tumors
													<Button id="cancerCollapse" className={classnames('close-toggle', { 'active': isRowCollapse8 })} onClick={() => { this.setState({ isRowCollapse8: !isRowCollapse8 }) }}></Button>
													</div>
													{contracts.map(cc =>
														<div>{ this.eventCount('c1',cc.conditionEvents) }</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#cancerCollapse" defaultOpen={isRowCollapse8}>
														<ul className="toggle-row">
																
																{fullPayment.map((fp, i) =>
																	( fp.event_category_id==1 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{ReactHtmlParser(this.fpc(cc.full_payment_data, fp.condition_id, 'event_condition'))}</div>																			
																			)}
																			<Button id={"cancer1-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"cancer1-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{ this.fpc(cc.full_payment_data, fp.condition_id, 'defination') }
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}

																{/* {additionPayment.map((fp, i) =>
																	( fp.event_category_id==1 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{this.apcc(cc.additional_payment_data, fp.condition_id, 'event_condition')}</div>
																			)}
																			<Button id={"cancer2-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"cancer2-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.apcc(cc.additional_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}

																

																{childspecific.map((fp, i) =>
																	( fp.event_category_id==1 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{this.cpcc(cc.child_payment_data, fp.condition_id, 'event_condition')}</div>
																			)}
																			<Button id={"cancer3-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"cancer3-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.cpcc(cc.child_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
																*/}
															
														</ul>
													</UncontrolledCollapse>
												</li>
												<li className={classnames({ 'dark-blue': isRowCollapse9 })}>
													<div>Heart & Circulatory
													<Button id="heartCollapse" className={classnames('close-toggle', { 'active': isRowCollapse9 })} onClick={() => { this.setState({ isRowCollapse9: !isRowCollapse9 }) }}></Button>
													</div>
													{contracts.map(cc =>
														<div>{ this.eventCount('c2',cc.conditionEvents) }</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#heartCollapse" defaultOpen={isRowCollapse9}>
														<ul className="toggle-row">
																
																{fullPayment.map((fp, i) =>
																	( fp.event_category_id==2 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{ReactHtmlParser(this.fpc(cc.full_payment_data, fp.condition_id, 'event_condition'))}</div>																			
																			)}
																			<Button id={"heart1-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"heart1-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.fpc(cc.full_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}

																{/* {additionPayment.map((fp, i) =>
																	( fp.event_category_id==2 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{this.apcc(cc.additional_payment_data, fp.condition_id, 'event_condition')}</div>
																			)}
																			<Button id={"heart2-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"heart2-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.apcc(cc.additional_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
																
																{childspecific.map((fp, i) =>
																	( fp.event_category_id==2 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{this.cpcc(cc.child_payment_data, fp.condition_id, 'event_condition')}</div>
																			)}
																			<Button id={"heart3-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"heart3-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.cpcc(cc.child_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
																*/}
															
														</ul>
													</UncontrolledCollapse>
												</li>
												<li className={classnames({ 'dark-blue': isRowCollapse10 })}>
													<div>Brain & Neurological
													<Button id="bnCollapse" className={classnames('close-toggle', { 'active': isRowCollapse10 })} onClick={() => { this.setState({ isRowCollapse10: !isRowCollapse10 }) }}></Button>
													</div>
													{contracts.map(cc =>
														<div>{ this.eventCount('c3',cc.conditionEvents) }</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#bnCollapse" defaultOpen={isRowCollapse10}>
														<ul className="toggle-row">
																
																{fullPayment.map((fp, i) =>
																	( fp.event_category_id==3 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{ReactHtmlParser(this.fpc(cc.full_payment_data, fp.condition_id, 'event_condition'))}</div>																			
																			)}
																			<Button id={"bn1-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"bn1-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.fpc(cc.full_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}

																{/* {additionPayment.map((fp, i) =>
																	( fp.event_category_id==3 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{this.apcc(cc.additional_payment_data, fp.condition_id, 'event_condition')}</div>
																			)}
																			<Button id={"bn2-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"bn2-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.apcc(cc.additional_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
																
																{childspecific.map((fp, i) =>
																	( fp.event_category_id==3 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{this.cpcc(cc.child_payment_data, fp.condition_id, 'event_condition')}</div>
																			)}
																			<Button id={"bn3-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"bn3-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.cpcc(cc.child_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
																*/}
															
														</ul>
													</UncontrolledCollapse>
												</li>
												<li className={classnames({ 'dark-blue': isRowCollapse11 })}>
													<div>other body systems
													<Button id="ofCollapse" className={classnames('close-toggle', { 'active': isRowCollapse11 })} onClick={() => { this.setState({ isRowCollapse11: !isRowCollapse11 }) }}></Button>
													</div>
													{contracts.map(cc =>
														<div>{ this.eventCount('c4',cc.conditionEvents) }</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#ofCollapse" defaultOpen={isRowCollapse11}>
														<ul className="toggle-row">
																
																{fullPayment.map((fp, i) =>
																	( fp.event_category_id==4 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{ReactHtmlParser(this.fpc(cc.full_payment_data, fp.condition_id, 'event_condition'))}</div>																			
																			)}
																			<Button id={"of1-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"of1-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.fpc(cc.full_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
																{/*

																{additionPayment.map((fp, i) =>
																	( fp.event_category_id==4 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{this.apcc(cc.additional_payment_data, fp.condition_id, 'event_condition')}</div>
																			)}
																			<Button id={"of2-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"of2-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.apcc(cc.additional_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
																
																{childspecific.map((fp, i) =>
																	( fp.event_category_id==4 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{this.cpcc(cc.child_payment_data, fp.condition_id, 'event_condition')}</div>
																			)}
																			<Button id={"of3-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"of3-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.cpcc(cc.child_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
																*/}
															
														</ul>
													</UncontrolledCollapse>
												</li>
												<li className={classnames({ 'dark-blue': isRowCollapse12 })}>
													<div>Accident, Injury & Hospitalisation 
													<Button id="ihsCollapse" className={classnames('close-toggle', { 'active': isRowCollapse12 })} onClick={() => { this.setState({ isRowCollapse12: !isRowCollapse12 }) }}></Button>
													</div>
													{contracts.map(cc =>
														<div>{ this.eventCount('c5',cc.conditionEvents) }</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#ihsCollapse" defaultOpen={isRowCollapse12}>
														<ul className="toggle-row">
																
																{fullPayment.map((fp, i) =>
																	( fp.event_category_id==5 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{ReactHtmlParser(this.fpc(cc.full_payment_data, fp.condition_id, 'event_condition'))}</div>																			
																			)}
																			<Button id={"ihs1-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"ihs1-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.fpc(cc.full_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
																{/*
																{additionPayment.map((fp, i) =>
																	( fp.event_category_id==5 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{this.apcc(cc.additional_payment_data, fp.condition_id, 'event_condition')}</div>
																			)}
																			<Button id={"ihs2-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"ihs2-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.apcc(cc.additional_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
																
																{childspecific.map((fp, i) =>
																	( fp.event_category_id==5 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{this.cpcc(cc.child_payment_data, fp.condition_id, 'event_condition')}</div>
																			)}
																			<Button id={"ihs3-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"ihs3-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.cpcc(cc.child_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
																*/}
															
														</ul>
													</UncontrolledCollapse>
												</li>
												<li className={classnames({ 'dark-blue': isRowCollapse13 })}>
													<div>Senses & physical impairment
													<Button id="ssCollapse" className={classnames('close-toggle', { 'active': isRowCollapse13 })} onClick={() => { this.setState({ isRowCollapse13: !isRowCollapse13 }) }}></Button>
													</div>
													{contracts.map(cc =>
														<div>{ this.eventCount('c6',cc.conditionEvents) }</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#ssCollapse" defaultOpen={isRowCollapse13}>
														<ul className="toggle-row">
																
																{fullPayment.map((fp, i) =>
																	( fp.event_category_id==6 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{ReactHtmlParser(this.fpc(cc.full_payment_data, fp.condition_id, 'event_condition'))}</div>																			
																			)}
																			<Button id={"ss1-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"ss1-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.fpc(cc.full_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
																{/*
																{additionPayment.map((fp, i) =>
																	( fp.event_category_id==6 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{this.apcc(cc.additional_payment_data, fp.condition_id, 'event_condition')}</div>
																			)}
																			<Button id={"ss2-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"ss2-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.apcc(cc.additional_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
																
																{childspecific.map((fp, i) =>
																	( fp.event_category_id==6 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{this.cpcc(cc.child_payment_data, fp.condition_id, 'event_condition')}</div>
																			)}
																			<Button id={"ss3-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"ss3-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.cpcc(cc.child_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
																*/}
															
														</ul>
													</UncontrolledCollapse>
												</li>
												<li className={classnames({ 'dark-blue': isRowCollapse14 })}>
													<div>Child Specific Events
													<Button id="csCollapse" className={classnames('close-toggle', { 'active': isRowCollapse14 })} onClick={() => { this.setState({ isRowCollapse14: !isRowCollapse14 }) }}></Button>
													</div>
													{contracts.map(cc =>
														<div>{ this.eventCountCC('c7',cc.conditionEvents) }</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#csCollapse" defaultOpen={isRowCollapse14}>
														<ul className="toggle-row">
																
																{/* {fullPayment.map((fp, i) =>
																	( fp.event_category_id==7 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{ReactHtmlParser(this.fpc(cc.full_payment_data, fp.condition_id, 'event_condition'))}</div>																			
																			)}
																			<Button id={"cs1-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"cs1-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.fpc(cc.full_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
																
																{additionPayment.map((fp, i) =>
																	( fp.event_category_id==7 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{this.apcc(cc.additional_payment_data, fp.condition_id, 'event_condition')}</div>
																			)}
																			<Button id={"cs2-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"cs2-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.apcc(cc.additional_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
																*/}
																{childspecific.map((fp, i) =>
																	( fp.event_category_id==7 && <>
																		<li key={i} >
																			<div>{fp.title}</div>
																			{contracts.map((cc, i) =>
																				<div key={i}>{this.cpcc(cc.child_payment_data, fp.condition_id, 'event_condition')}</div>
																			)}
																			<Button id={"cs3-" + fp.condition_id} className="full-btn"></Button>
																		</li>
																		<li className="collapse-row">
																			<UncontrolledCollapse toggler={"cs3-" + fp.condition_id} >
																				<ul className="toggle-row">
																					<li className="cond-def-row" >
																						<div>CONDITION DEFINITION</div>

																						{contracts.map((cc, i) =>
																							<>
																								<div key={i}>
																									<div className="desc">
																										{this.cpcc(cc.child_payment_data, fp.condition_id, 'defination')}
																									</div>
																								</div>
																							</>
																						)}

																					</li>
																				</ul>
																			</UncontrolledCollapse>
																		</li>
																	</>)
																)}
															
														</ul>
													</UncontrolledCollapse>
												</li>
												
												{/* <li className={classnames({ 'dark-blue': isRowCollapse8 })}>
													<div>CANCER <Button id="extraFeaturePayout" id="toggler1" onClick={() => { this.setState({ isRowCollapse8: !isRowCollapse8 }) }} className={classnames('close-toggle', { 'active': isRowCollapse8 })}></Button></div>
													
													{contracts.map(cc =>
														<div>EVENT COUNT: { this.eventCount('c1',cc.conditionEvents) }</div>
													)}
													
												</li>
												<li className="collapse-row" >
													<UncontrolledCollapse toggler="#toggler1" defaultOpen={isRowCollapse8}>
														<ul className="toggle-row">
															<li className="cat-row">
																<div className={classnames({ 'blue-bg': isRowCollapse8 })}>
																	<img src={cancer} className="svg-icon" alt="icon" />
																</div>

																{contracts.map(cc =>
																	<div>
																		<ul className="condition-list">
																			{ ReactHtmlParser(this.eventCondition('c1',cc.conditionEvents)) }
																		</ul>
																	</div>
																)}															
																
															</li>
														</ul>
													</UncontrolledCollapse>
												</li>

												<li className={classnames({ 'dark-blue': isRowCollapse9 })}>
													<div>HEART
														<Button id="extraFeaturePayout" id="toggler2" onClick={() => { this.setState({ isRowCollapse9: !isRowCollapse9 }) }} className={classnames('close-toggle', { 'active': isRowCollapse9 })}></Button>
													</div>
													{contracts.map(cc =>
														<div>EVENT COUNT: { this.eventCount('c2',cc.conditionEvents) }</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#toggler2" defaultOpen={isRowCollapse9}>
														<ul className="toggle-row">
															<li className="cat-row">
																<div className={classnames({ 'blue-bg': isRowCollapse9 })}>
																	<img src={heart} className="svg-icon" alt="icon" />
																</div>
																{contracts.map(cc =>
																	<div>
																		<ul className="condition-list">
																			{ ReactHtmlParser(this.eventCondition('c2',cc.conditionEvents)) }
																		</ul>
																	</div>
																)}	
															</li>
														</ul>
													</UncontrolledCollapse>
												</li>
												<li className={classnames({ 'dark-blue': isRowCollapse10 })} >
													<div>BRIAN & NEUROLOGICAL
														<Button id="extraFeaturePayout" id="toggler3" onClick={() => { this.setState({ isRowCollapse10: !isRowCollapse10 }) }} className={classnames('close-toggle', { 'active': isRowCollapse10 })}></Button>
													</div>
													{contracts.map(cc =>
														<div>EVENT COUNT: { this.eventCount('c3',cc.conditionEvents) }</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#toggler3" defaultOpen={isRowCollapse10}>
														<ul className="toggle-row">
															<li className="cat-row">
																<div className={classnames({ 'blue-bg': isRowCollapse10 })}>
																	<img src={brain} className="svg-icon" alt="icon" />
																</div>
																{contracts.map(cc =>
																	<div>
																		<ul className="condition-list">
																			{ ReactHtmlParser(this.eventCondition('c3',cc.conditionEvents)) }
																		</ul>
																	</div>
																)}	
															</li>
														</ul>
													</UncontrolledCollapse>
												</li>
												<li className={classnames({ 'dark-blue': isRowCollapse11 })}>
													<div>Organs & Nervous System
														<Button id="extraFeaturePayout" id="toggler4" onClick={() => { this.setState({ isRowCollapse11: !isRowCollapse11 }) }} className={classnames('close-toggle', { 'active': isRowCollapse11 })}></Button>
													</div>
													{contracts.map(cc =>
														<div>EVENT COUNT: { this.eventCount('c4',cc.conditionEvents) }</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#toggler4" defaultOpen={isRowCollapse11}>
														<ul className="toggle-row">
															<li className="cat-row">
																<div className={classnames({ 'blue-bg': isRowCollapse11 })}>
																	<img src={organ} className="svg-icon" alt="icon" />
																</div>
																{contracts.map(cc =>
																	<div>
																		<ul className="condition-list">
																			{ ReactHtmlParser(this.eventCondition('c4',cc.conditionEvents)) }
																		</ul>
																	</div>
																)}	
															</li>
														</ul>
													</UncontrolledCollapse>
												</li>
												<li className={classnames({ 'dark-blue': isRowCollapse12 })}>
													<div>Injury,Hospitalisation & Surgery
														<Button id="extraFeaturePayout" id="toggler5" onClick={() => { this.setState({ isRowCollapse12: !isRowCollapse12 }) }} className={classnames('close-toggle', { 'active': isRowCollapse12 })}></Button>
													</div>
													{contracts.map(cc =>
														<div>EVENT COUNT: { this.eventCount('c5',cc.conditionEvents) }</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#toggler5" defaultOpen={isRowCollapse12}>
														<ul className="toggle-row">
															<li className="cat-row">
																<div className={classnames({ 'blue-bg': isRowCollapse12 })}>
																	<img src={injury} className="svg-icon" alt="icon" />
																</div>
																{contracts.map(cc =>
																	<div>
																		<ul className="condition-list">
																			{ ReactHtmlParser(this.eventCondition('c5',cc.conditionEvents)) }
																		</ul>
																	</div>
																)}	
															</li>
														</ul>
													</UncontrolledCollapse>
												</li>
												<li className={classnames({ 'dark-blue': isRowCollapse13 })}>
													<div>SENSES
														<Button id="extraFeaturePayout" id="toggler6" onClick={() => { this.setState({ isRowCollapse13: !isRowCollapse13 }) }} className={classnames('close-toggle', { 'active': isRowCollapse13 })}></Button>
													</div>
													{contracts.map(cc =>
														<div>EVENT COUNT: { this.eventCount('c6',cc.conditionEvents) }</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#toggler6" defaultOpen={isRowCollapse13}>
														<ul className="toggle-row">
															<li className="cat-row">
																<div className={classnames({ 'blue-bg': isRowCollapse13 })}>
																	<img src={ear} className="svg-icon" alt="icon" />
																</div>
																{contracts.map(cc =>
																	<div>
																		<ul className="condition-list">
																			{ ReactHtmlParser(this.eventCondition('c6',cc.conditionEvents)) }
																		</ul>
																	</div>
																)}	
															</li>
														</ul>
													</UncontrolledCollapse>
												</li>
												<li className={classnames({ 'dark-blue': isRowCollapse14 })}>
													<div>CHILD SPECIFIC
												<Button id="extraFeaturePayout" id="toggler7" onClick={() => { this.setState({ isRowCollapse14: !isRowCollapse14 }) }} className={classnames('close-toggle', { 'active': isRowCollapse14 })}></Button>
													</div>
													{contracts.map(cc =>
														<div>EVENT COUNT: { this.eventCount('c7',cc.conditionEvents) }</div>
													)}
												</li>
												<li className="collapse-row">
													<UncontrolledCollapse toggler="#toggler7" defaultOpen={isRowCollapse14}>
														<ul className="toggle-row">
															<li className="cat-row">
																<div className={classnames({ 'blue-bg': isRowCollapse14 })}>
																	<img src={child} className="svg-icon" alt="icon" />
																</div>
																{contracts.map(cc =>
																	<div>
																		<ul className="condition-list">
																			{ ReactHtmlParser(this.eventCondition('c7',cc.conditionEvents)) }
																		</ul>
																	</div>
																)}
															</li> 
														</ul>
													</UncontrolledCollapse>
												</li> 
											*/}
											</ul>
									</div>
									
									<div className="home-table cat-table">
																		
										{/* <ul className="theme-table text-uppercase">
											<li className={classnames({ 'open-collapse': isRowCollapse8 })}>
												<div>CANCER <Button id="extraFeaturePayout" id="toggler1" onClick={() => { this.setState({ isRowCollapse8: !isRowCollapse8 }) }} className={classnames('close-toggle', { 'active': isRowCollapse8 })}></Button></div>
												
												{contracts.map(cc =>
													<div>EVENT COUNT: { this.eventCount('c1',cc.conditionEvents) }</div>
												)}
												
											</li>
											<li className="collapse-row" >
												<UncontrolledCollapse toggler="#toggler1" defaultOpen={isRowCollapse8}>
													<ul className="toggle-row">
														<li>
															<div className={classnames({ 'blue-bg': isRowCollapse8 })}>
																<img src={cancer} className="svg-icon" alt="icon" />
															</div>

															{contracts.map(cc =>
																<div>
																	<ul className="condition-list">
																		{ ReactHtmlParser(this.eventCondition('c1',cc.conditionEvents)) }
																	</ul>
																</div>
															)}															
															
														</li>
													</ul>
												</UncontrolledCollapse>
											</li>

											<li className={classnames({ 'open-collapse': isRowCollapse9 })}>
												<div>HEART
													<Button id="extraFeaturePayout" id="toggler2" onClick={() => { this.setState({ isRowCollapse9: !isRowCollapse9 }) }} className={classnames('close-toggle', { 'active': isRowCollapse9 })}></Button>
												</div>
												{contracts.map(cc =>
													<div>EVENT COUNT: { this.eventCount('c2',cc.conditionEvents) }</div>
												)}
											</li>
											<li className="collapse-row">
												<UncontrolledCollapse toggler="#toggler2" defaultOpen={isRowCollapse9}>
													<ul className="toggle-row">
														<li>
															<div className={classnames({ 'blue-bg': isRowCollapse9 })}>
																<img src={heart} className="svg-icon" alt="icon" />
															</div>
															{contracts.map(cc =>
																<div>
																	<ul className="condition-list">
																		{ ReactHtmlParser(this.eventCondition('c2',cc.conditionEvents)) }
																	</ul>
																</div>
															)}	
														</li>
													</ul>
												</UncontrolledCollapse>
											</li>
											<li className={classnames({ 'open-collapse': isRowCollapse10 })} >
												<div>BRIAN & NEUROLOGICAL
													<Button id="extraFeaturePayout" id="toggler3" onClick={() => { this.setState({ isRowCollapse10: !isRowCollapse10 }) }} className={classnames('close-toggle', { 'active': isRowCollapse10 })}></Button>
												</div>
												{contracts.map(cc =>
													<div>EVENT COUNT: { this.eventCount('c3',cc.conditionEvents) }</div>
												)}
											</li>
											<li className="collapse-row">
												<UncontrolledCollapse toggler="#toggler3" defaultOpen={isRowCollapse10}>
													<ul className="toggle-row">
														<li>
															<div className={classnames({ 'blue-bg': isRowCollapse10 })}>
																<img src={brain} className="svg-icon" alt="icon" />
															</div>
															{contracts.map(cc =>
																<div>
																	<ul className="condition-list">
																		{ ReactHtmlParser(this.eventCondition('c3',cc.conditionEvents)) }
																	</ul>
																</div>
															)}	
														</li>
													</ul>
												</UncontrolledCollapse>
											</li>
											<li className={classnames({ 'open-collapse': isRowCollapse11 })}>
												<div>Organs & Nervous System
													<Button id="extraFeaturePayout" id="toggler4" onClick={() => { this.setState({ isRowCollapse11: !isRowCollapse11 }) }} className={classnames('close-toggle', { 'active': isRowCollapse11 })}></Button>
												</div>
												{contracts.map(cc =>
													<div>EVENT COUNT: { this.eventCount('c4',cc.conditionEvents) }</div>
												)}
											</li>
											<li className="collapse-row">
												<UncontrolledCollapse toggler="#toggler4" defaultOpen={isRowCollapse11}>
													<ul className="toggle-row">
														<li>
															<div className={classnames({ 'blue-bg': isRowCollapse11 })}>
																<img src={organ} className="svg-icon" alt="icon" />
															</div>
															{contracts.map(cc =>
																<div>
																	<ul className="condition-list">
																		{ ReactHtmlParser(this.eventCondition('c4',cc.conditionEvents)) }
																	</ul>
																</div>
															)}	
														</li>
													</ul>
												</UncontrolledCollapse>
											</li>
											<li className={classnames({ 'open-collapse': isRowCollapse12 })}>
												<div>Injury,Hospitalisation & Surgery
													<Button id="extraFeaturePayout" id="toggler5" onClick={() => { this.setState({ isRowCollapse12: !isRowCollapse12 }) }} className={classnames('close-toggle', { 'active': isRowCollapse12 })}></Button>
												</div>
												{contracts.map(cc =>
													<div>EVENT COUNT: { this.eventCount('c5',cc.conditionEvents) }</div>
												)}
											</li>
											<li className="collapse-row">
												<UncontrolledCollapse toggler="#toggler5" defaultOpen={isRowCollapse12}>
													<ul className="toggle-row">
														<li>
															<div className={classnames({ 'blue-bg': isRowCollapse12 })}>
																<img src={injury} className="svg-icon" alt="icon" />
															</div>
															{contracts.map(cc =>
																<div>
																	<ul className="condition-list">
																		{ ReactHtmlParser(this.eventCondition('c5',cc.conditionEvents)) }
																	</ul>
																</div>
															)}	
														</li>
													</ul>
												</UncontrolledCollapse>
											</li>
											<li className={classnames({ 'open-collapse': isRowCollapse13 })}>
												<div>SENSES
													<Button id="extraFeaturePayout" id="toggler6" onClick={() => { this.setState({ isRowCollapse13: !isRowCollapse13 }) }} className={classnames('close-toggle', { 'active': isRowCollapse13 })}></Button>
												</div>
												{contracts.map(cc =>
													<div>EVENT COUNT: { this.eventCount('c6',cc.conditionEvents) }</div>
												)}
											</li>
											<li className="collapse-row">
												<UncontrolledCollapse toggler="#toggler6" defaultOpen={isRowCollapse13}>
													<ul className="toggle-row">
														<li>
															<div className={classnames({ 'blue-bg': isRowCollapse13 })}>
																<img src={ear} className="svg-icon" alt="icon" />
															</div>
															{contracts.map(cc =>
																<div>
																	<ul className="condition-list">
																		{ ReactHtmlParser(this.eventCondition('c6',cc.conditionEvents)) }
																	</ul>
																</div>
															)}	
														</li>
													</ul>
												</UncontrolledCollapse>
											</li>
											<li className={classnames({ 'open-collapse': isRowCollapse14 })}>
												<div>CHILD SPECIFIC
											<Button id="extraFeaturePayout" id="toggler7" onClick={() => { this.setState({ isRowCollapse14: !isRowCollapse14 }) }} className={classnames('close-toggle', { 'active': isRowCollapse14 })}></Button>
												</div>
												{contracts.map(cc =>
													<div>EVENT COUNT: { this.eventCount('c7',cc.conditionEvents) }</div>
												)}
											</li>
											<li className="collapse-row">
												<UncontrolledCollapse toggler="#toggler7" defaultOpen={isRowCollapse14}>
													<ul className="toggle-row">
														<li>
															<div className={classnames({ 'blue-bg': isRowCollapse14 })}>
																<img src={child} className="svg-icon" alt="icon" />
															</div>
															{contracts.map(cc =>
																<div>
																	<ul className="condition-list">
																		{ ReactHtmlParser(this.eventCondition('c7',cc.conditionEvents)) }
																	</ul>
																</div>
															)}
														</li> 
													</ul>
												</UncontrolledCollapse>
											</li> 
										</ul> */}
								</div>
							</Scrollbars>
							</div>
							</>	 : <SelectComparison />
						}

						{/** <div className="home-table cat-table">
							<p>EVENTS COVERED BY CATEGORY</p>
							<Scrollbars className="scrollable-table" 
								renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
								renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
								renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
								renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
								<ul className="theme-table text-uppercase">
									<li >
										<div>CANCER
									<Button id="extraFeaturePayout" id="toggler1" onClick={() => { this.setState({ isRowCollapse7: !isRowCollapse7 }) }} className={classnames('close-toggle', { 'active': isRowCollapse7 })}></Button>
										</div>
										<div>
											EVENT COUNT: 3
									</div>
										<div>
											EVENT COUNT: 2
									</div>
										<div>
											EVENT COUNT: 3
									</div>
										<div>
											EVENT COUNT: 3
									</div>
									</li>
									<li className="collapse-row">
										<UncontrolledCollapse toggler="#toggler1">
											<ul className="toggle-row">
												<li>
													<div className={classnames({ 'blue-bg': isRowCollapse7 })}>
														<img src={cancer} className="svg-icon" alt="icon" />
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
																<ul className="payment-list">
																	<li>Advanced payment</li>
																	<li>Enhanced payment
																		<p className="small-font">to age 53 0% up to £</p>
																	</li>
																</ul>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
												</li>
											</ul>
										</UncontrolledCollapse>
									</li>
									<li className="open-collapse">
										<div>HEART & ARTERIES
									<Button id="extraFeaturePayout" id="toggler2" onClick={() => { this.setState({ isRowCollapse7: !isRowCollapse7 }) }} className={classnames('close-toggle', { 'active': isRowCollapse7 })}></Button>
										</div>
										<div>
											EVENT COUNT: 3
									</div>
										<div>
											EVENT COUNT: 2
									</div>
										<div>
											EVENT COUNT: 3
									</div>
										<div>
											EVENT COUNT: 3
									</div>
									</li>
									<li className="collapse-row">
										<UncontrolledCollapse toggler="#toggler2">
											<ul className="toggle-row">
												<li>
													<div className={classnames({ 'blue-bg': isRowCollapse7 })}>
														<img src={heart} className="svg-icon" alt="icon" />
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
												</li>
											</ul>
										</UncontrolledCollapse>
									</li>
									<li >
										<div>BRIAN & NEUROLOGICAL
									<Button id="extraFeaturePayout" id="toggler3" onClick={() => { this.setState({ isRowCollapse7: !isRowCollapse7 }) }} className={classnames('close-toggle', { 'active': isRowCollapse7 })}></Button>
										</div>
										<div>
											EVENT COUNT: 3
									</div>
										<div>
											EVENT COUNT: 2
									</div>
										<div>
											EVENT COUNT: 3
									</div>
										<div>
											EVENT COUNT: 3
									</div>
									</li>
									<li className="collapse-row">
										<UncontrolledCollapse toggler="#toggler3">
											<ul className="toggle-row">
												<li>
													<div className={classnames({ 'blue-bg': isRowCollapse7 })}>
														<img src={brain} className="svg-icon" alt="icon" />
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
												</li>
											</ul>
										</UncontrolledCollapse>
									</li>
									<li>
										<div>ORGAN FAILURE
									<Button id="extraFeaturePayout" id="toggler4" onClick={() => { this.setState({ isRowCollapse7: !isRowCollapse7 }) }} className={classnames('close-toggle', { 'active': isRowCollapse7 })}></Button>
										</div>
										<div>
											EVENT COUNT: 3
									</div>
										<div>
											EVENT COUNT: 2
									</div>
										<div>
											EVENT COUNT: 3
									</div>
										<div>
											EVENT COUNT: 3
									</div>
									</li>
									<li className="collapse-row">
										<UncontrolledCollapse toggler="#toggler4">
											<ul className="toggle-row">
												<li>
													<div className={classnames({ 'blue-bg': isRowCollapse7 })}>
														<img src={organ} className="svg-icon" alt="icon" />
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
												</li>
											</ul>
										</UncontrolledCollapse>
									</li>
									<li >
										<div>INJURY & HOSPITALISATION
									<Button id="extraFeaturePayout" id="toggler5" onClick={() => { this.setState({ isRowCollapse7: !isRowCollapse7 }) }} className={classnames('close-toggle', { 'active': isRowCollapse7 })}></Button>
										</div>
										<div>
											EVENT COUNT: 3
									</div>
										<div>
											EVENT COUNT: 2
									</div>
										<div>
											EVENT COUNT: 3
									</div>
										<div>
											EVENT COUNT: 3
									</div>
									</li>
									<li className="collapse-row">
										<UncontrolledCollapse toggler="#toggler5">
											<ul className="toggle-row">
												<li>
													<div className={classnames({ 'blue-bg': isRowCollapse7 })}>
														<img src={injury} className="svg-icon" alt="icon" />
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
												</li>
											</ul>
										</UncontrolledCollapse>
									</li>
									<li>
										<div>SENSES
									<Button id="extraFeaturePayout" id="toggler6" onClick={() => { this.setState({ isRowCollapse7: !isRowCollapse7 }) }} className={classnames('close-toggle', { 'active': isRowCollapse7 })}></Button>
										</div>
										<div>
											EVENT COUNT: 3
									</div>
										<div>
											EVENT COUNT: 2
									</div>
										<div>
											EVENT COUNT: 3
									</div>
										<div>
											EVENT COUNT: 3
									</div>
									</li>
									<li className="collapse-row">
										<UncontrolledCollapse toggler="#toggler6">
											<ul className="toggle-row">
												<li>
													<div className={classnames({ 'blue-bg': isRowCollapse7 })}>
														<img src={ear} className="svg-icon" alt="icon" />
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li className="yellow-bg">
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">Full payment condition</p>
															</li>
															<li>
																<p className="capital">CONDITION TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
												</li>
											</ul>
										</UncontrolledCollapse>
									</li>
									<li >
										<div>CHILD SPECIFIC
									<Button id="extraFeaturePayout" id="toggler7" onClick={() => { this.setState({ isRowCollapse7: !isRowCollapse7 }) }} className={classnames('close-toggle', { 'active': isRowCollapse7 })}></Button>
										</div>
										<div>
											EVENT COUNT: 3
									</div>
										<div>
											EVENT COUNT: 2
									</div>
										<div>
											EVENT COUNT: 3
									</div>
										<div>
											EVENT COUNT: 3
									</div>
									</li>
									<li className="collapse-row">
										<UncontrolledCollapse toggler="#toggler7">
											<ul className="toggle-row">
												<li>
													<div className={classnames({ 'blue-bg': isRowCollapse7 })}>
														<img src={child} className="svg-icon" alt="icon" />
													</div>
													<div>
														<ul className="condition-list">
															<li>
																<p className="capital">EVENT TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
															<li>
																<p className="capital">EVENT TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
															<li>
																<p className="capital">EVENT TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li>
																<p className="capital">EVENT TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
															<li>
																<p className="capital">EVENT TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li>
																<p className="capital">EVENT TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
															<li>
																<p className="capital">EVENT TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
															<li>
																<p className="capital">EVENT TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
													<div>
														<ul className="condition-list">
															<li>
																<p className="capital">EVENT TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
															<li>
																<p className="capital">EVENT TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
															<li>
																<p className="capital">EVENT TITLE</p>
																<p className="normal">% of sum assured up to £</p>
															</li>
														</ul>
													</div>
												</li>
											</ul>
										</UncontrolledCollapse>
									</li>
								</ul>
							</Scrollbars>
						</div> **/}
					</section>
					{/******* Main Content End *******/}
					{/******* Filter Section Start *******/}
					<section className={classnames('full-section filter-section', { 'active': isFilterActive })}>
						<Filter onClose={this.handleClose} />
					</section>
					{/******* Filter Section End *******/}
					{/******* Edit Section Start *******/}
					<section className={classnames('full-section edit-section', { 'active': isEditActive })}>
						<Edit onClose={this.handleClose} />
					</section>
					{/******* Edit Section End *******/}
					{/******* Main Content End *******/}
					{/******* User Section Start *******/}
					<section className={classnames('full-section user-section', { 'active': this.props.routename == 'comparison-user' ? true : false })}>
						<User onClose={this.handleClose} />
					</section>
					{/******* User Section End *******/}
				</main>
			</Fragment >
		)
	}
}


const mapDispatchToProps = (dispatch) => {
	return {
		setCondtionData: (data, type) => {
			let constDispType = '';
			if (type == 'full_payment_conditions') {
				constDispType = 'FULL_PAYMENT_LIST';
			} else if (type == 'addtional_payment_conditions') {
				constDispType = 'ADDITIONAL_LIST';
			} else if (type == 'child_payment_conditions') {
				constDispType = 'CHILD_SPECIFIC_LIST';
			} else if (type == 'extra_feature_payment_conditions') {
				constDispType = 'FURTHER_LIST';
			} else { }
			if (constDispType != '') {
				dispatch({ type: constDispType, payload: { data: data } })
			}
		}
	}
}

const mapStateToProps = state => ({
	contractComparisonData: state.comparisonContract,
})



export default connect(mapStateToProps, mapDispatchToProps)(Home)
