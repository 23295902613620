import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isLogin } from '../Auth'

export const PublicRoute = ({  component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => ( !isLogin() ? <Component {...props}  routename={ rest.routename } /> : <Redirect to={`${process.env.PUBLIC_URL}/`}/>)}
  />
)


export default PublicRoute  

