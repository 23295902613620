import React,{ Component } from 'react'
import { NavLink } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { verifyEmail ,verifyPassword } from '../../../helper';
import config from '../../../config.json';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { connect } from 'react-redux';
import { history } from '../../../index'
import Loading from '../../../components/Loading'
import http from '../../../httpService/httpService'
import { store } from '../../../store.js'


class LoginForm extends  Component{

  state = {
    account : { email : '', password: ''},
    errors :  { errEmail : '',errPasword : ''},
    loader : false,
  };

  validate = () => {
    const { account  } = this.state;    
    const errors = {};

     // Email Validation
    if(account.email === ''){
      errors.errEmail = 'Email is required field';
    }else if(!verifyEmail(account.email)){
      errors.errEmail = 'Please enter valid email';
    } 

    // Password Validation
    if(account.password === ''){
      errors.errPasword = 'Password is required field';
    }else if(!verifyPassword(account.password)){
      errors.errPasword = 'Please enter password in between 6 to 15 character';
    } 
    this.setState({ errors });

    return Object.keys(errors).length === 0 ? null : errors;

  }

  handleSubmit = e => {

    e.preventDefault();
    const { account } = this.state;

    let email =account.email; 
    let password = account.password;

    if(this.validate() === null){
        this.setState({ loader:true });
        http.post(process.env.REACT_APP_API_URL+'/login', { email , password })
        .then((response) => {   

          this.setState({ loader:false });       
          toast.success(response.data.message);
          localStorage.setItem('cccAuthToken',response.data.token);
          localStorage.setItem('cccAuthUser',JSON.stringify(response.data.data));        
          
          const token = response.data.token;
          store.dispatch({
            type: 'USER_PROFILE',
            payload: {
              data: response.data.data,
              token
            }
          })
          window.location.href = process.env.PUBLIC_URL
          // history.push(process.env.PUBLIC_URL);
        })
        .catch((error) => {
          this.setState({ loader:false });   
          if(error.response && error.response.status != 200){
             toast.error(error.response.data.message);
          }
        });
    }
   
  }

  handleChange = (e) => {
    const { name , value  } = e.target;
    const account = {...this.state.account};
    account[name]=value;
    this.setState({account });
  }

  render = (props) =>{
      const { errors,loader } =  this.state;
      
      return (
        <React.Fragment>
          {loader && <Loading />}
          <Form method="post" onSubmit= { this.handleSubmit }>
            <FormGroup>
              <Label htmlFor="userID">User ID</Label>
              <Input type="text"  name='email'  onChange={(e) => { this.handleChange(e) }}/>
              { errors.errEmail && <p className="error-text"> <em> { errors.errEmail } </em></p> }
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password">Password</Label>
              <Input type="password" id="password" name='password' onChange={(e) => { this.handleChange(e) }}/>
              { errors.errPasword && <p className="error-text"> <em> { errors.errPasword }  </em></p> }       
            </FormGroup>
            <Button type="submit" className="theme-btn submit-btn" >Log In</Button>
            <p className="mb-0"><NavLink to={`${process.env.PUBLIC_URL}/forgot-password`}>Forgotten your Password?</NavLink></p>
          </Form>
         </React.Fragment>
      );
  }
}

// export default LoginForm

const mapStateToProps = state => ({
  data: state.auth
})

export default connect(mapStateToProps)(LoginForm)
