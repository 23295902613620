import React, { Component, Fragment } from 'react'
import {
  Nav, TabContent, NavItem, Button,  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import classnames from 'classnames';
import UserProfile from './UserModule/UserProfile.jsx'
import UserManage from './UserModule/UserManage.jsx'
import UserAdd from './UserModule/UserAdd.jsx'
import { Scrollbars } from 'react-custom-scrollbars';
import plusIcon from '../../assets/images/plus-icon.png';
import deleteIcon from '../../assets/images/delete-icon.png';
import { connect } from 'react-redux';

class Filter extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      typeValue: '',
      statusValue: '',
      dispAdmin:false
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  changeUserType = (e) => {
    this.setState({ typeValue: e.currentTarget.textContent })
  }

  changeUserStatus = (e) => {
    this.setState({ statusValue: e.currentTarget.textContent })
  }

  render() {
    return (
      <Fragment>
        <div className="tab-section">
          <div className="tab-head d-flex justify-content-between align-items-center">
            <Nav tabs className="nav nav-pills">
              <NavItem>
                <a className={classnames('nav-link', { active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>MY PROFILE</a>
              </NavItem>

              { this.props.userData.type == 'admin' ?
                <Fragment>
                   <NavItem>
                    <a className={classnames('nav-link', { active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>Manage users</a>
                  </NavItem>
                  <NavItem>
                    <a className={classnames('nav-link', { active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>Add user</a>
                  </NavItem>
                </Fragment> : null
              }
             
            </Nav>
            <Button className="close-btn" onClick={() => this.props.onClose('usr')}><img src={plusIcon} alt="close" /></Button>
          </div>
          <TabContent className="tab-content" activeTab={this.state.activeTab}>
            <UserProfile />
            { this.props.userData.type == 'admin' ?
              <Fragment>
                <UserManage />
                <UserAdd toggle={ this.toggle }/> 
              </Fragment> : null }
          </TabContent>
        </div>
      </Fragment >
    )
  }
}

const mapStateToProps = state => ({
  userData: state.auth,  
})
export default connect(mapStateToProps)(Filter)
