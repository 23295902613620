import React, { Component, Fragment } from 'react'
import {
  Form, TabPane, Label, Button, UncontrolledDropdown,FormGroup, Input,  Modal, ModalBody,  DropdownToggle, DropdownMenu, DropdownItem,   Card
} from 'reactstrap';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import config from '../../../config.json';
import http from '../../../httpService/httpService'
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Loading from '../../../components/Loading'
import FullPaymentCondition from './FullPaymentCondition'
import AdditionalPaymentCondition from './AdditionalPaymentCondition'
import ChildrenCover from './ChildrenCover'
import ChildSpecificCondition from './ChildSpecificCondition'
import ExtraFeatureCondition from './ExtraFeatureCondition';
import { verifyLength } from '../../../helper';

const contractInitialState = {
    provider_id : '',
    contract_name : '',
    status:'',
    live_date:'',
    end_date:'',
    child_cover:'n',
    child_age_from:'',
    child_age_to:'',
    claim_max_percentage:'',
    claim_max_pound:'',
    benefit_reduce:'n',
    funeral_amount:'',
    non_uk_treatment:'n',
    enhanced_ci_cover:'no',
    enhanced_child_cover:'no'
}
class CreateContract extends Component {
  constructor(props) {
    super(props)
  }

  state = {  
    providerValue: '',
    statusValue: '',
    providers:[],
    file: null,
    providerData:{ name:'', picture:''},
    loader : false,
    contract : contractInitialState,
    contractErr : { 
      provider_id : '',
      contract_name : '',
      status:'',
      live_date:'',
      end_date:'',
    },
    full_payemnt_data:null,
    additional_payemnt_data:null,
    child_specific_data:null,
    extra_feature_data:null,
    ed_disp:true,
    resetFrm:false,
    ci_cover_value:'',
    child_cover_value:'',
  }

   componentWillReceiveProps(nextProps){  

      if(nextProps.providerData && nextProps.providerData.type == 'PROVIDER_LIST'){
        const providers = nextProps.providerData.data;
        this.setState({ providers });      
      }
      if(nextProps.fullPayemntData && nextProps.fullPayemntData.type == 'SET_FULLPAYMENT_DATA'){
        const full_payemnt_data = nextProps.fullPayemntData.data;
        this.setState({ full_payemnt_data }); 
      }
      if(nextProps.AdditionalPayemntData  && nextProps.AdditionalPayemntData.type == 'SET_ADDITIONAL_DATA'){        
        const additional_payemnt_data = nextProps.AdditionalPayemntData.data;
        this.setState({ additional_payemnt_data });      
      }

      if(nextProps.ChildSpecificData  && nextProps.ChildSpecificData.type == 'SET_CHILDSPECIFIC_DATA'){        
        const child_specific_data = nextProps.ChildSpecificData.data;
        this.setState({ child_specific_data });  
      }

      if(nextProps.ExtraFeatureData  && nextProps.ExtraFeatureData.type == 'SET_EXTRAFEATURE_DATA'){        
        const extra_feature_data = nextProps.ExtraFeatureData.data;
        this.setState({ extra_feature_data });  
      }
    }


  /* Create Contract Module - Start */

  validate = () => {
    const { contract  } = this.state;    
    const contractErr = {};

    if(contract.provider_id == ''){
      contractErr.provider_id = 'Provider is required field';
    }
    if(contract.contract_name == ''){
      contractErr.contract_name = 'Contract name is required field';
    }else if( verifyLength(contract.contract_name,50) ){
       contractErr.contract_name = 'Only 50 characters should be allow for contract name';
    }

    if(contract.status == ''){
      contractErr.status = 'Status is required field';
    }
    if(contract.live_date == ''){
      contractErr.live_date = 'Live date is required field';
    }
    if(contract.end_date == '' && contract.status == 'Historic'){
      contractErr.end_date = 'End date is required field';
    }
    this.setState({ contractErr });

    if(Object.keys(contractErr).length > 0){
      Object.keys(contractErr).map(c => toast.error(contractErr[c]) )
    }    
    return Object.keys(contractErr).length === 0 ? null : contractErr;
  }

  handleSubmit = e => {

    e.preventDefault();
    const { provider_id,contract_name,status,live_date,end_date,child_cover,child_age_from, child_age_to,claim_max_percentage,claim_max_pound,
      benefit_reduce,funeral_amount,non_uk_treatment,enhanced_ci_cover,enhanced_child_cover } = this.state.contract;

    const { full_payemnt_data,additional_payemnt_data,child_specific_data,extra_feature_data }= this.state;
    
    if(this.validate() === null){
        
        if(full_payemnt_data !== null){
          var fErr = 0;
          full_payemnt_data.forEach(function (value) {
            if(!value.specified_cover){             
              fErr = 1;              
            }
          });
        }

        if(additional_payemnt_data !== null){
          var aErr = 0;
          additional_payemnt_data.forEach(function (value) {
            if(!value.specified_cover){             
              aErr = 1;
            }
          });
        }
        
        if(fErr == 1 || aErr == 1){
         

          if(aErr == 1 )
            this.setState({ errMsg : "You need to identify if the SPECIFIC CONDITION is covered or if the CONDITION COVERED BY OTHER HEADING."});

           if(fErr == 1)
            this.setState({ errMsg : "You need to identify if the SPECIFIC CONDITION is covered or if the CONDITION COVERED BY OTHER HEADING."});

          this.setState({
            errPopup: !this.state.errPopup
          });

        }else{

          this.setState({ loader:true });
          const token = localStorage.getItem('cccAuthToken');
          http.post(process.env.REACT_APP_API_URL+'/create-contract', { 
          provider_id,
          contract_name,
          status,
          live_date,
          end_date,
          child_cover,
          child_age_from, 
          child_age_to,
          claim_max_percentage,
          claim_max_pound,
          benefit_reduce,
          funeral_amount,
          non_uk_treatment,
          enhanced_child_cover,
          enhanced_ci_cover,
          full_payemnt_data:JSON.stringify(full_payemnt_data),
          additional_payemnt_data:JSON.stringify(additional_payemnt_data),
          child_specific_data:JSON.stringify(child_specific_data),
          extra_feature_data:JSON.stringify(extra_feature_data)
          },{
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
          .then((response) => {  
            console.log(response.data.data);
            this.props.setContractData( response.data.data );
            this.setState({ 
              contract : contractInitialState,
              full_payemnt_data:null,
              additional_payemnt_data:null,
              child_specific_data:null,
              extra_feature_data:null,
              providerValue:null,
              ci_cover_value:null,
              child_cover_value:null,
              statusValue:null,
              resetFrm: true
            });

            toast.success(response.data.message);
            this.setState({ loader:false });  
            // window.location.reload();

          })
          .catch((error) => {
            this.setState({ loader:false });   
            if(error.response && error.response.status != 200){
               toast.error(error.response.data.message);
            }
          });
        }        
    }   
  }
  /* Create Contract Module - End */
  
  /* Provider Creation Section Start */

  toggleAddProviderModal = () => {
    this.setState({
      addProviderModal: !this.state.addProviderModal
    });

    this.setState({  providerData: { name:'', picture:''}  });          
    this.setState({  file: null  });
  }

  changeProvider = (e) => {
    const contract = this.state.contract;
    contract['provider_id'] = e.currentTarget.value;
    this.setState({ providerValue: e.currentTarget.textContent })
    this.setState({contract })
    
  }

  changeAddOne = (type, e) => {
    const contract = this.state.contract;

    if(type == 'enhanced_ci_cover'){
      contract['enhanced_ci_cover'] = e.currentTarget.value;
      this.setState({ ci_cover_value: e.currentTarget.textContent })
      this.setState({contract })
    }

    if(type == 'enhanced_child_cover'){
      contract['enhanced_child_cover'] = e.currentTarget.value;
      this.setState({ child_cover_value: e.currentTarget.textContent })
      this.setState({contract })
    }
    
  }


  // handlePhotoChange = (event) =>{
  //   this.setState({
  //     file: URL.createObjectURL(event.target.files[0])
  //   })

  //   const providerData = {...this.state.providerData};
  //   providerData['picture']=event.target.files[0];
  //   this.setState({providerData });
  // }

  handlePhotoChange = (event) =>{
    if(event.target.files[0]){
      this.setState({
        file: URL.createObjectURL(event.target.files[0])
      })

      const providerData = {...this.state.providerData};
      providerData['picture']=event.target.files[0];
      this.setState({providerData });
    }else{
      const providerData = {...this.state.providerData};
      providerData['picture']='';
      this.setState({providerData });
      this.setState({
        file:''
      })
    } 
  }

  handleProviderChange = (e) => {
    const { name , value  } = e.target;
    const providerData = {...this.state.providerData};
    providerData[name]=value;
    this.setState({providerData });
  }

  validateProvider = () => {
    const { providerData  } = this.state;    
    const providerDataErr = {};

    if(providerData.name == ''){
      providerDataErr.errname = 'Name is required field';
    }

    if(providerData.picture == ''){
      providerDataErr.errpicture = 'Picture is required field';
    }   

    if(Object.keys(providerDataErr).length > 0){
      Object.keys(providerDataErr).map(c => toast.error(providerDataErr[c]) )
    }

    return Object.keys(providerDataErr).length === 0 ? null : providerDataErr;

  }

  handleSubmitProvider = e => {

    e.preventDefault();
    const { providerData } = this.state;    

    if(this.validateProvider() === null){
        this.setState({ loader:true });

        const fd = new FormData();
        fd.append("picture", providerData.picture, providerData.picture.name);
        fd.append("name", providerData.name);

        const token = localStorage.getItem('cccAuthToken');
        http.post(process.env.REACT_APP_API_URL+'/create-provider', fd ,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => { 
          toast.success(response.data.message);
          this.setState({ loader:false }); 
          
          const providers = [...this.state.providers,{
            id : response.data.data.id,
            name : response.data.data.name,
            picture : response.data.data.picture 
          }];

          this.props.setProviderData(providers)
          this.setState({ providers  });          
          this.setState({  providerData: { name:'', picture:''}  });          
          this.setState({  file: null  });          
          this.toggleAddProviderModal()
        })
        .catch((error) => {
          this.setState({ loader:false });   
          if(error.response && error.response.status != 200){
             toast.error(error.response.data.message);
          }
        });
    }
   
  }
  /* Provider Creation Section End */  

  changeContractStatus = (e) => {
    this.setState({ statusValue: e.currentTarget.textContent })
    const contract = this.state.contract;
    contract['status'] = e.currentTarget.value;   
    this.setState({contract }) 

    if(e.currentTarget.value == 'Current'){
      this.setState({ ed_disp : false }) 
    }else{
      this.setState({ ed_disp : true }) 
    }

  }

  handleChange = (e) => {
    const { name , value  } = e.target;
    const contract = {...this.state.contract};
    contract[name]=value;
    this.setState({ contract });
  }

  handleChecked = (e,type) => {  
      const contract = {...this.state.contract};  
      if(e.target.checked == true){       
        contract[type]='y';        
      }else{
        contract[type]='n';        
      } 
      this.setState({ contract });
      
  }

  render() {
    
    const { loader,file,providers,ed_disp,contract } = this.state
    
    return (
      <Fragment>
        {loader && <Loading />}
        <TabPane tabId="1">
          <Form id='contractForm'>
          <div className="sub-title d-flex justify-content-between align-items-center">
            <em>All sections must be completed</em>
            <a href="javascript:void(0)" onClick={ this.handleSubmit }>SAVE CONTACT TO DATABASE</a>
          </div>
          <ul className="common-input-list common-inputs mb-0">
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>Contract provider</Label>
                <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                  <DropdownToggle className="dropdown-toggle">{this.state.providerValue}</DropdownToggle>
                  <DropdownMenu>
                    <Scrollbars className="scrollable-dropdown"
                      renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                      renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                      renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                      renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                      <p className="new-provider d-flex justify-content-between align-items-center">Choose a Provider
                      <a onClick={this.toggleAddProviderModal}>Add new provider</a>
                      </p>
                      { providers.map(p => <DropdownItem key={p.id}  onClick={ this.changeProvider } value={p.id} >{ p.name } </DropdownItem> )}                
                    </Scrollbars>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FormGroup>
            </li>
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>Contract Name</Label>
                <Input type="text" name='contract_name' value={contract.contract_name}  onChange={(e) => { this.handleChange(e) }} />
              </FormGroup>
            </li>
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>Enhanced Ci Cover Add On</Label>
                <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                  <DropdownToggle className="dropdown-toggle">{this.state.ci_cover_value}</DropdownToggle>
                  <DropdownMenu>
                    <Scrollbars className="scrollable-dropdown"
                      renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                      renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                      renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                      renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                      <p className="new-provider d-flex justify-content-between align-items-center">Choose An Option</p>
                      <DropdownItem  onClick={ (e) => this.changeAddOne('enhanced_ci_cover',e) } value='Yes' >Yes</DropdownItem>                
                      <DropdownItem onClick={ (e) => this.changeAddOne('enhanced_ci_cover',e) } value='No' >No</DropdownItem>                
                    </Scrollbars>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FormGroup>
            </li>
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>Enhanced Child Cover Add On</Label>
                <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                  <DropdownToggle className="dropdown-toggle">{this.state.child_cover_value}</DropdownToggle>
                  <DropdownMenu>
                    <Scrollbars className="scrollable-dropdown"
                      renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                      renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                      renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                      renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                      <p className="new-provider d-flex justify-content-between align-items-center">Choose An Option</p>
                      <DropdownItem  onClick={ (e) => this.changeAddOne('enhanced_child_cover',e) } value='Yes' >Yes</DropdownItem>                
                      <DropdownItem onClick={ (e) => this.changeAddOne('enhanced_child_cover',e) } value='No' >No</DropdownItem>                
                    </Scrollbars>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FormGroup>
            </li>
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>Contract status</Label>
                <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                  <DropdownToggle className="dropdown-toggle">{this.state.statusValue}</DropdownToggle>
                  <DropdownMenu>
                    <Scrollbars className="scrollable-dropdown"
                      renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                      renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                      renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                      renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                      <DropdownItem value='Current' onClick={this.changeContractStatus}>Current</DropdownItem>
                      <DropdownItem value='Historic' onClick={this.changeContractStatus}>Historic</DropdownItem>
                    </Scrollbars>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FormGroup>
            </li>
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>Contract live date</Label>
                <input type="date" className="form-control" value={contract.live_date} onChange={(e) => { this.handleChange(e) }} name='live_date'/>
              </FormGroup>
            </li>
            { ed_disp == true ?
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>Contract end date</Label>
                <input type="date" className="form-control" value={contract.end_date} onChange={(e) => { this.handleChange(e) }} name='end_date'/>
              </FormGroup>
            </li>
            : null}
          </ul>
          <div className="common-accordion">
            <FullPaymentCondition resetFrm = {this.state.resetFrm }/>
            {/* <AdditionalPaymentCondition resetFrm = {this.state.resetFrm }/>    */}        
            <ChildrenCover onChange = { this.handleChange } onChecked = { this.handleChecked } contract={ this.state.contract }/>
            <ChildSpecificCondition resetFrm = {this.state.resetFrm } />
            <ExtraFeatureCondition  resetFrm = {this.state.resetFrm }/>
          </div>
          </Form>
        </TabPane>
        <Modal isOpen={this.state.addProviderModal} toggle={this.toggleAddProviderModal} className="add-provider-modal">
          <ModalBody>
             { loader && <Loading /> }    
             <Form method="post" onSubmit= { this.handleSubmitProvider } id='addProvider'>
              <div className="action-button d-flex justify-content-end">
                <Button type="button" onClick={this.toggleAddProviderModal}>Abandon</Button>
                <Button type="submit">SAVE & CLOSE</Button>
              </div>
              <div className="modal-body">
                <FormGroup>
                  <Label htmlFor="contractFeature"><em>Provider name</em></Label>
                  <Input type="text" className="form-control" name="name" onChange={(e) => { this.handleProviderChange(e) }} />
                </FormGroup>
                <FormGroup>
                  <Label><em>Provider logo</em></Label>
                  <div className="logo-preview" style={{ backgroundImage: `url(${file})`}} >
                  </div>
                  <div className="custom-file">
                    <Input type="file" accept="image/*" className="custom-file-input" name="picture" onChange={(e) => this.handlePhotoChange(e)}/>
                    <Label className="custom-file-label" htmlFor="picture">upload</Label>
                  </div>
                </FormGroup>
              </div>
            </Form>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.errPopup} toggle={() => this.setState({ errPopup : false })} className="add-provider-modal">
          <ModalBody>            
              <div className="action-button d-flex justify-content-end">
                <Button type="button" onClick={() => this.setState({ errPopup : false })}>Ok</Button>                
              </div>
              <div className="modal-body">
                <FormGroup>
                  <Label htmlFor="contractFeature"><em>{ this.state.errMsg}</em></Label>                  
                </FormGroup>                
              </div>           
          </ModalBody>
        </Modal>

      </Fragment>
    );
  }
}
// export default CreateContract

const mapDispatchToProps = (dispatch) =>{
  return {
    setProviderData:(data) => { dispatch({ type: 'PROVIDER_LIST',payload:{ data : data } }) },
    setContractData:(data) => { dispatch({ type: 'SET_CREATE_CONTRACT',payload:{ data : data } }) }    
  }
}
const mapStateToProps = state => ({
  providerData: state.provider,
  fullPayemntData: state.fullPaymentCondition,
  AdditionalPayemntData: state.AdditionalPaymentCondition,
  ChildSpecificData: state.childSpecificCondition,
  ExtraFeatureData: state.ExtraFeatureCondition,
})
export default connect(mapStateToProps,mapDispatchToProps)(CreateContract)