import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap';
import NotFoundArtwork from '../assets/images/404-artwork.svg'

const SelectComparison = () => (
  <Fragment>
    <section className="small-section error-section not-found-section text-center">
      <div className="container">        
        <h4>Please select comparison</h4>
      </div>
    </section>
  </Fragment>
)

export default SelectComparison
