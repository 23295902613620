import React, { Component, Fragment } from 'react'
import {
  Form,TabPane, Label, Button, FormGroup, Input, Modal, ModalBody
} from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import deleteIcon from '../../../assets/images/delete-icon.png';
import aigLogo from '../../../assets/images/aig-logo.png';
import config from '../../../config.json';
import { ToastContainer,toast } from 'react-toastify';
import http from '../../../httpService/httpService';
import { history } from '../../../index';
import Loading from '../../../components/Loading';
import { connect } from 'react-redux';

class EditProviders extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    EditProviders:[],
    dispProivder:false,
    providerData:{ name:'', picture:'',file:''},
    pData:{ name:'', picture:''},
    file: null,
    p_id:'',
    providers:[],
    token:localStorage.getItem('cccAuthToken'),
    loader:false
  }

  componentWillReceiveProps(nextProps){ 
    if(nextProps.providerData && nextProps.providerData.type == 'PROVIDER_LIST'){
      const providers = nextProps.providerData.data;
      this.setState({ providers });      
    }
  }

   async componentDidMount(){
    const token = this.state.token;
    await http.get(process.env.REACT_APP_API_URL+'/get-provider-list',{
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => { 
      const providers = response.data.data;
      this.setState( { providers });
      this.props.setProviderData( providers );
    })
    .catch((error) => {
      this.setState({ loader:false });   
      if(error.response && error.response.status != 200){
         // toast.error(error.response.data.message);
      }
    });
    
  }

  deleteProvider = async (providerId) => {
      
      
      this.setState({ loader:true });  
      this.setState({ dispProvider : false});
       
      const token = this.state.token;
      await http.post(process.env.REACT_APP_API_URL+'/delete-provider', {'id' : providerId},{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => { 

        const pls = this.state.providers;
        const providers =pls.filter(usr => usr.id != providerId);
        this.setState( { providers });
        this.props.setProviderData(providers);

        toast.success(response.data.message);
        this.setState({  deleteModal: false });  
        this.setState({ loader:false });        
      })
      .catch((error) => {
        this.setState({ loader:false });   
        if(error.response && error.response.status != 200){
           toast.error(error.response.data.message);
        }
      });
     
  } 

  getProvderData = (userId) =>{
    const pList =this.state.providers;
    const pdata = pList.find(pdata => pdata.id == userId);
    const providerData =  { 
      name:pdata.name,
      picture : pdata.picture,
      file:pdata.picture,
      id:pdata.id,
    };
    this.setState({ providerData });
    this.setState({ 'dispProvider': true });
  }

   handlePhotoChange = (event) =>{
    if(event.target.files[0]){
      this.setState({
        file: URL.createObjectURL(event.target.files[0])
      })

      const pData = {...this.state.pData};
      pData['picture']=event.target.files[0];
      this.setState({pData });
    }else{
      const pData = {...this.state.pData};
      pData['picture']='';
      this.setState({pData });
      this.setState({
        file:''
      })
    } 
  }

  handleProviderChange = (e) => {
    const { name , value  } = e.target;
    const pData = {...this.state.pData};
    pData[name]=value;
    this.setState({pData });
  }

  validateProvider = () => {
    const { pData  } = this.state;    
    const pDataErr = {};

    if(pData.name == ''){
      pDataErr.errname = 'Name is required field';
    }

    if(pData.picture == ''){
      pDataErr.errpicture = 'Picture is required field';
    }   

    if(Object.keys(pDataErr).length > 0){
      Object.keys(pDataErr).map(c => toast.error(pDataErr[c]) )
    }

    return Object.keys(pDataErr).length === 0 ? null : pDataErr;

  }

  handleSubmitProvider = e => {

    e.preventDefault();
    const { pData,providers } = this.state;    

    if(this.validateProvider() === null){        
        this.setState({ loader:true });

        const fd = new FormData();
        fd.append("picture", pData.picture, pData.picture.name);
        fd.append("name", pData.name);

        const token = this.state.token;
        http.post(process.env.REACT_APP_API_URL+'/create-provider', fd ,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => { 
          toast.success(response.data.message);
          this.setState({ loader:false }); 

          const providers = [...this.state.providers,{
            id : response.data.data.id,
            name : response.data.data.name,
            picture : response.data.data.picture 
          }];

          this.props.setProviderData(providers)
          this.setState({ providers  });
          this.toggleAddProviderModal()

          this.setState({  pData: { name:'', picture:''}  });          
          this.setState({  file: null  });   

        })
        .catch((error) => {
          this.setState({ loader:false });   
          if(error.response && error.response.status != 200){
             toast.error(error.response.data.message);
          }
        });
    }   
  }

  // Modal
  toggleAddProviderModal = () => {
    this.setState({
      addProviderModal: !this.state.addProviderModal
    });
    this.setState({  pData: { name:'', picture:''}  });          
    this.setState({  file: null  });  
  }

  toggledeleteModal = (p_id) => {
    this.setState({ p_id : p_id });
    this.setState({
      deleteModal: !this.state.deleteModal
    });
  }

  // Update

  edthandleProviderChange = (e) => {
    const { name , value  } = e.target;
    const providerData = {...this.state.providerData};
    providerData[name]=value;
    this.setState({providerData });
  }

  edthandlePhotoChange = (event) =>{
    if(event.target.files[0]){

      const providerData = {...this.state.providerData};
      providerData['picture']=event.target.files[0];
      this.setState({providerData });

    }else{

      const providerData = {...this.state.providerData};
      providerData['picture']='';
      this.setState({providerData });
      
    } 
  }

  validateUpdateProvider = () => {
    const { providerData  } = this.state; 
    const providerDataErr = {};

    if(providerData.name == ''){
      providerDataErr.errname = 'Name is required field';
    }  

    if(Object.keys(providerDataErr).length > 0){
      Object.keys(providerDataErr).map(c => toast.error(providerDataErr[c]) )
    }
    return Object.keys(providerDataErr).length === 0 ? null : providerDataErr;
  }


  handleUpdateProvider = e => {

    e.preventDefault();
    const { providerData,providers } = this.state;    

    if(this.validateUpdateProvider() === null){        
        this.setState({ loader:true });

        const fd = new FormData();
        if( document.getElementById("providerFile").files.length > 0 ){
          fd.append("picture", providerData.picture, providerData.picture.name);
        }        
        fd.append("name", providerData.name);
        fd.append("id", providerData.id);

        const token = this.state.token;
        http.post(process.env.REACT_APP_API_URL+'/edit-provider', fd ,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => { 
          toast.success(response.data.message);
          this.setState({ loader:false }); 

          const cl = providers.find(cls => cls.id == response.data.data.id);     
          const index = providers.indexOf(cl);   
          providers[index]={
            id : response.data.data.id,
            name : response.data.data.name,
            picture : response.data.data.picture 
          };
          
          this.props.setProviderData(providers)
          this.setState({ providers  });
          this.setState({ dispProvider : false});
        })
        .catch((error) => {
          this.setState({ loader:false });   
          if(error.response && error.response.status != 200){
             toast.error(error.response.data.message);
          }
        });
    }   
  }

  
  render() {
    const { providerDataErr,loader,providers,dispProvider,providerData,file,toggledeleteModal,deleteModal,p_id } = this.state;
    return (
      <Fragment>        
        {loader && <Loading /> }
        <TabPane tabId="3">
          <div className="sub-title d-flex justify-content-between align-items-center">
            <em>Select a provider to edit</em>
            <Button onClick={this.toggleAddProviderModal}>ADD PROVIDER</Button>
          </div>
          <Scrollbars className="scrollable-list"
            renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
            renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
            renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
            renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
            <ul className="check-list text-uppercase">
              { providers.map(p => <li key={p.id} className="d-flex justify-content-between align-items-center">
                <span onClick={ () => this.getProvderData(p.id) }  >{ p.name }</span>
                <a className="delete-icon" onClick={ () => this.toggledeleteModal(p.id) }><img src={deleteIcon} alt="delete" /></a>
              </li>)}            
             
            </ul>
          </Scrollbars>
          { dispProvider == true ? (
          <Fragment>            
            <div className="provider-logo d-flex justify-content-between align-items-end">
              <img src={ providerData.file } alt="aig" />
              <div className="custom-file">
                <Input type="file" accept="image/*" className="custom-file-input" id="providerFile" name="picture" onChange={(e) => this.edthandlePhotoChange(e)} />
                <Label className="custom-file-label" htmlFor="upload">UPDATE LOGO</Label>
              </div>
            </div>
            <ul className="common-input-list">
              <li className="d-flex justify-content-between align-items-center">
                <Label>PROVIDER NAME</Label>
                <Input type="text"  name="name" value={ providerData.name } onChange={(e) => { this.edthandleProviderChange(e) }}/>
              </li>
            </ul>
            <div className="sub-title d-flex justify-content-between align-items-center">
              <em>&nbsp;</em>
              <Button onClick={this.handleUpdateProvider}>Update</Button>
            </div>                   
          </Fragment>           
          ) : null }

        </TabPane>

        <Modal isOpen={this.state.addProviderModal} toggle={this.toggleAddProviderModal} className="add-provider-modal">
          <ModalBody>
            {loader && <Loading /> }
            <Form method="post" onSubmit= { this.handleSubmitProvider } id='addProvider'>
              <div className="action-button d-flex justify-content-end">
                <Button type="button" onClick={this.toggleAddProviderModal}>Abandon</Button>
                <Button type="submit" >SAVE & CLOSE</Button>
              </div>
              <div className="modal-body">
                <FormGroup>
                  <Label htmlFor="contractFeature"><em>Provider name</em></Label>
                  <Input type="text" className="form-control" name="name" onChange={(e) => { this.handleProviderChange(e) }} />                  
                </FormGroup>
                <FormGroup>
                  <Label><em>Provider logo</em></Label>
                  <div className="logo-preview" style={{ backgroundImage: `url(${file})`}} >
                  </div>
                  <div className="custom-file">
                    <Input type="file" accept="image/*" className="custom-file-input" name="picture" onChange={(e) => this.handlePhotoChange(e)}/>
                    <Label className="custom-file-label" htmlFor="upload">upload</Label>
                  </div>
                </FormGroup>
              </div>
            </Form>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.deleteModal} toggle={this.toggledeleteModal} className="delete-condition-modal">
          <ModalBody>
              {loader && <Loading /> }            
              <div className="action-button d-flex justify-content-end">
                <Button type="button" onClick={this.toggledeleteModal}>No</Button>
                <Button type="button" onClick={ () => this.deleteProvider(p_id) } >Yes</Button>
              </div>
              <div className="modal-body">
                <FormGroup>
                  <Label htmlFor="contractFeature"><em>Are you sure want to delete this?</em></Label>                  
                </FormGroup>                
              </div>           
          </ModalBody>
        </Modal> 
      </Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setProviderData:(data) => { dispatch({ type: 'PROVIDER_LIST', payload:{ data : data } }) },
  }
}
const mapStateToProps = state => ({
  providerData: state.provider
})
export default connect(mapStateToProps,mapDispatchToProps)(EditProviders)