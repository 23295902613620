import React, { Component, Fragment } from 'react'
import {
  Form,Nav, TabContent, TabPane, NavItem, Label, Button, FormGroup, CustomInput, Input, Modal, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import plusIcon from '../../../assets/images/plus-icon.png';
import deleteIcon from '../../../assets/images/delete-icon.png';
import { verifyLength, verifyEmail ,verifyPassword } from '../../../helper';
import config from '../../../config.json';
import { ToastContainer,toast } from 'react-toastify';
import http from '../../../httpService/httpService';
import { history } from '../../../index';
import Loading from '../../../components/Loading'
import { connect } from 'react-redux';

const initialState = {
  activeTab: '1',
  typeText: '',
  typeValue: 'admin',
  statusText: '',
  statusValue: 'y',
  account : { name:'',email : '',password:'', type:'',status:''},
  errors :  { errEmail : '',errName : '',errPassword:'',errType : '',errStatus : ''},
  loader : false,
};

class UserAdd extends Component {
  constructor(props) {
    super(props)
    this.state = initialState;
  }

  changeUserType = (e) => {
    this.setState({ typeText: e.currentTarget.textContent })
    this.setState({ typeValue: e.currentTarget.value })

    const account = {...this.state.account};
    account['type']=e.currentTarget.value;
    this.setState({account });
  }

  changeUserStatus = (e) => {
    this.setState({ statusText: e.currentTarget.textContent })
    this.setState({ statusValue: e.currentTarget.value })

    const account = {...this.state.account};
    account['status']=e.currentTarget.value;
    this.setState({account });
  }

  handleChange = (e) => {
    const { name , value  } = e.target;
    const account = {...this.state.account};
    account[name]=value;
    this.setState({account });
  }

  validate = () => {
    const { account  } = this.state;    
    const errors = {};

    if(account.name == ''){
      errors.errName = 'Name is required field';
    }

    if(account.type == ''){
      errors.errType = 'Type is required field';
    }

    if(account.status == ''){
      errors.errStatus = 'Status is required field';
    }

    if(account.password === ''){
      errors.errPassword = 'Password is required field';
    }else if(!verifyPassword(account.password)){
      errors.errPassword = 'Please enter password in between 6 to 15 character';
    } 

     // Email Validation
    if(account.email == ''){
      errors.errEmail = 'Email is required field';
    }else if(!verifyEmail(account.email)){
      errors.errEmail = 'Please enter valid email';
    } 

    this.setState({ errors });

    if(Object.keys(errors).length > 0){
      Object.keys(errors).map(c => toast.error(errors[c]) )
    }
    
    return Object.keys(errors).length === 0 ? null : errors;

  }

  handleSubmit = e => {

    e.preventDefault();
    const { account } = this.state;

    let email =account.email; 
    let name = account.name;
    let type = account.type;
    let status = account.status;
    let password = account.password;

    if(this.validate() === null){
        this.setState({ loader:true });
        const token = localStorage.getItem('cccAuthToken');
        http.post(process.env.REACT_APP_API_URL+'/create-user', { email ,name, type, status,password },{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => {  
          this.setState(initialState);
          toast.success(response.data.message);

          this.props.setUserData(response.data.data);

          document.getElementById("addUser").reset();
          this.props.toggle('2');
        })
        .catch((error) => {
          this.setState({ loader:false });   
          if(error.response && error.response.status != 200){
             toast.error(error.response.data.message);
          }
        });
    }
   
  }

  render() {
    const { statusValue , statusText , typeValue, typeText,loader } = this.state;
    return (
      <Fragment>
        {loader && <Loading />}
        <TabPane tabId="3">
        <Form method="post" onSubmit= { this.handleSubmit } id='addUser'>
          <ul className="common-input-list common-inputs">
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>User name</Label>
                <Input type="text" name='name' onChange={(e) => { this.handleChange(e) }}/>
              </FormGroup>
            </li>
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>email address</Label>
                <Input type="text" name='email' onChange={(e) => { this.handleChange(e) }}/>
              </FormGroup>
            </li>
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>Password</Label>
                <Input type="text" name='password' style={{ "text-transform":"none" }} onChange={(e) => { this.handleChange(e) }}/>
              </FormGroup>
            </li>            
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>user type</Label>
                <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                  <DropdownToggle className="dropdown-toggle">{ typeText }</DropdownToggle>
                  <DropdownMenu name='type'>
                    <DropdownItem value='admin' className={ typeValue == 'admin' ? 'active' : ''} onClick={this.changeUserType}>Admin</DropdownItem>
                    <DropdownItem value='basic' className={ typeValue == 'basic' ? 'active' : ''} onClick={this.changeUserType}>Basic</DropdownItem>                    
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FormGroup>
            </li>
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>user status</Label>
                <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                  <DropdownToggle className="dropdown-toggle">{ statusText }</DropdownToggle>
                  <DropdownMenu name='status'>
                    <DropdownItem value='y' className={ statusValue == 'y' ? 'active' : ''} onClick={this.changeUserStatus}>Active</DropdownItem>
                    <DropdownItem value='n' className={ statusValue == 'n' ? 'active' : ''} onClick={this.changeUserStatus}>Inactive</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FormGroup>
            </li>
          </ul>
          <div className="text-right">
            <Button type="submit" className="line-btn">add user</Button>
          </div>
          </Form>
        </TabPane>
      </Fragment >
    )
  }
}

const mapDispatchToProps = (dispatch) =>{
  return {
    setUserData:(data) => { dispatch({ type:'USER_LIST' , payload: { data: data } } ) }
  }
}
export default connect(null,mapDispatchToProps)(UserAdd)