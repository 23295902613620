import React, { Component, Fragment } from 'react'
import {
  Form,Nav, TabContent, TabPane, NavItem, Label, Button, FormGroup, Input, CustomInput, Modal, ModalBody, CardHeader, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, UncontrolledCollapse, Card
} from 'reactstrap';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import config from '../../../config.json';
import http from '../../../httpService/httpService'
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Loading from '../../../components/Loading'
import FullPaymentCondition from './FullPaymentCondition'
import AdditionalPaymentCondition from './AdditionalPaymentCondition'
import ChildSpecificCondition from './ChildSpecificCondition'
import ExtraFeatureCondition from './ExtraFeatureCondition'
import { verifyLength } from '../../../helper';

import EditChildrenCover from './EditContractModule/EditChildrenCover'
import EditFullPaymentCondition from './EditContractModule/EditFullPaymentCondition'
import EditAdditionalPaymentCondition from './EditContractModule/EditAdditionalPaymentCondition'
import EditChildSpecificCondition from './EditContractModule/EditChildSpecificCondition'
import EditExtraFeatureCondition from './EditContractModule/EditExtraFeatureCondition'

const contractInitialState = {
    provider_id : '',
    contract_name : '',
    status:'',
    live_date:'',
    end_date:'',
    child_cover:'n',
    child_age_from:'',
    child_age_to:'',
    claim_max_percentage:'',
    claim_max_pound:'',
    benefit_reduce:'n',
    non_uk_treatment:'n',
    funeral_amount:'',
}
class EditContract extends Component {
  constructor(props) {
    super(props)
  }  

  state = {  
    contractList:[],
    token:localStorage.getItem('cccAuthToken'),
    providerValue: 'Select Contract',
    contract : contractInitialState,
    loader : false,  
    contractErr : { 
      provider_id : '',
      contract_name : '',
      status:'',
      live_date:'',
      end_date:'',
    },
    statusValue: '',
    isEdit:false,
    full_payemnt_data:null,
    additional_payemnt_data:null,
    extra_feature_data:null,
    ed_disp:false,
    contract_id:'',
    dispDel:false,
    ci_cover_value:'',
    child_cover_value:'',

  }

  componentWillReceiveProps(nextProps){  

      if(nextProps.fullPayemntData && nextProps.fullPayemntData.type == 'SET_EDIT_FULLPAYMENT_DATA'){
        const full_payemnt_data = nextProps.fullPayemntData.data;
        this.setState({ full_payemnt_data }); 
      }
      if(nextProps.additionalPayemntData && nextProps.additionalPayemntData.type == 'SET_EDIT_ADDITIONAL_DATA'){
        const additional_payemnt_data = nextProps.additionalPayemntData.data;
        this.setState({ additional_payemnt_data }); 
      }
      if(nextProps.childPayemntData && nextProps.childPayemntData.type == 'SET_EDIT_CHILD_SPECIFIC_DATA'){
        const child_specific_data = nextProps.childPayemntData.data;
        this.setState({ child_specific_data }); 
      }
      if(nextProps.extraFeatureData && nextProps.extraFeatureData.type == 'SET_EDIT_EXTRAFEATURE_DATA'){
        const extra_feature_data = nextProps.extraFeatureData.data;
        this.setState({ extra_feature_data }); 
      }
      if(nextProps.createContractData && nextProps.createContractData.type == 'SET_CREATE_CONTRACT'){
        const cData = nextProps.createContractData.data;
        const conlist = this.state.contractList.find(cl => cl.id == cData.id);
        if(!conlist){
          const cc = [...this.state.contractList,cData];
          const contractList = cc.sort((a, b) => a.provider.localeCompare(b.provider))
          this.setState({ contractList });
        }
      }
      
   }

  async componentDidMount(){

    const token = this.state.token;
    await http.get(process.env.REACT_APP_API_URL+'/get-contracts',{
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => { 
      const cc = response.data.data;
      const contractList = cc.sort((a, b) => a.provider.localeCompare(b.provider))
      this.setState( { contractList });
    })
    .catch((error) => {  
      if(error.response && error.response.status != 200){
         // toast.error(error.response.data.message);
      }
    }); 
   
  }

  changeContract = async (e) => {
    this.setState({ providerValue: e.currentTarget.textContent })
    this.setState({ contract_id: e.currentTarget.value })
    const contract_id = e.currentTarget.value; 

    this.setState({ loader:true }); 
    const token = this.state.token;
    await http.post(process.env.REACT_APP_API_URL+'/get-contracts-detail',
    { contract_id },
    {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => { 
      this.setState({ loader:false }); 
      const contract = response.data.data;

      this.setState( { contract });
      this.setState({ 'statusValue': contract.status,'ci_cover_value' :  contract.enhanced_ci_cover,'child_cover_value' : contract.enhanced_child_cover });
      this.setState({ isEdit : true, dispDel: true })
      this.setState({ ed_disp : contract.status == 'Current' ? false : true })
    })
    .catch((error) => {  
       this.setState({ loader:false }); 
      if(error.response && error.response.status != 200){
         toast.error(error.response.data.message);
      }
    }); 


    // const contractList =this.state.contractList;
    // const contract = contractList.find(con => con.id == contract_id);
    // this.setState({ contract });
    // this.setState({ 'statusValue': contract.status });
    // this.setState({ isEdit : true})
    // this.setState({ ed_disp : contract.status == 'Current' ? false : true })

  }  


  /* Create Contract Module - Start */

  validate = () => {

    const contract_id = this.state.contract_id;
    const { contract  } = this.state;    
    const contractErr = {};

    if(contract_id == ''){
        contractErr.con = 'Please select contract to edit';
        this.setState({ contractErr });
    }else{

       if(contract.provider_id == ''){
          contractErr.provider_id = 'Provider is required field';
        }
        if(contract.contract_name == ''){
          contractErr.contract_name = 'Contract name is required field';
        }else if( verifyLength(contract.contract_name,50) ){
           contractErr.contract_name = 'Only 50 characters should be allow for contract name';
        }

        if(contract.status == ''){
          contractErr.status = 'Status is required field';
        }
        if(contract.live_date == ''){
          contractErr.live_date = 'Live date is required field';
        }
        if(contract.end_date == '' && contract.status == 'Historic'){
          contractErr.end_date = 'End date is required field';
        }
        this.setState({ contractErr });
    }  

    if(Object.keys(contractErr).length > 0){
      Object.keys(contractErr).map(c => toast.error(contractErr[c]) )
    }    
    return Object.keys(contractErr).length === 0 ? null : contractErr;
  }

   changeAddOne = (type, e) => {
    const contract = this.state.contract;

    if(type == 'enhanced_ci_cover'){
      contract['enhanced_ci_cover'] = e.currentTarget.value;
      this.setState({ ci_cover_value: e.currentTarget.textContent })
      this.setState({contract })
    }

    if(type == 'enhanced_child_cover'){
      contract['enhanced_child_cover'] = e.currentTarget.value;
      this.setState({ child_cover_value: e.currentTarget.textContent })
      this.setState({contract })
    }
    
  }


  handleSubmit = e => {

    e.preventDefault();
      const { funeral_amount,non_uk_treatment,id,provider_id,contract_name,status,live_date,end_date,child_cover,child_age_from, child_age_to,claim_max_percentage,claim_max_pound,
      benefit_reduce,enhanced_ci_cover,enhanced_child_cover } = this.state.contract;

      const { full_payemnt_data,additional_payemnt_data,child_specific_data,extra_feature_data }= this.state;

    if(this.validate() === null){

        if(full_payemnt_data !== null){
          var fErr = 0;
          full_payemnt_data.forEach(function (value) {
            if(!value.specified_cover || (value.specified_cover == 'n' && value.other_cover == 'n') ){             
              fErr = 1;              
            }
          });
        }

        if(additional_payemnt_data !== null){
          var aErr = 0;
          additional_payemnt_data.forEach(function (value) {
            if(!value.specified_cover || (value.specified_cover == 'n' && value.other_cover == 'n')){             
              aErr = 1;
            }
          });
        }

        if(fErr == 1 || aErr == 1){
         

          if(aErr == 1 )
            this.setState({ errMsg : "You need to identify if the SPECIFIC CONDITION is covered or if the CONDITION COVERED BY OTHER HEADING."});

          if(fErr == 1)
            this.setState({ errMsg : "You need to identify if the SPECIFIC CONDITION is covered or if the CONDITION COVERED BY OTHER HEADING."});

          this.setState({
            errPopup: !this.state.errPopup
          });

        }else{
          this.setState({ loader:true });
          const token = localStorage.getItem('cccAuthToken');
          http.post(process.env.REACT_APP_API_URL+'/edit-contract', { 
          id,
          provider_id,
          contract_name,
          status,
          live_date,
          end_date,
          child_cover,
          child_age_from, 
          child_age_to,
          claim_max_percentage,
          claim_max_pound,
          benefit_reduce,
          funeral_amount,
          non_uk_treatment,
          enhanced_child_cover,
          enhanced_ci_cover,
          full_payemnt_data:JSON.stringify(full_payemnt_data),
          additional_payemnt_data:JSON.stringify(additional_payemnt_data),
          child_specific_data:JSON.stringify(child_specific_data),
          extra_feature_data:JSON.stringify(extra_feature_data),
          },{
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
          .then((response) => {  

            toast.success(response.data.message);
            this.setState({ loader:false, isEdit : false,contract_id:'', providerValue: 'Select Contract' });  
             
            const contractList = this.state.contractList;
            const con = contractList.find(con => con.id == id);     
            const index = contractList.indexOf(con);

            con['status'] = status;
            con['live_date'] = live_date;
            con['end_date'] = (status == 'Historic' ? end_date : '');
            con['contract_name'] = contract_name;
            con['child_cover'] = child_cover;

            contractList[index]=con;
            this.setState({ contractList });


            // window.location.reload();
          })
          .catch((error) => {
            this.setState({ loader:false });   
            if(error.response && error.response.status != 200){
               toast.error(error.response.data.message);
            }
          });
        }
    }   
  }
  /* Create Contract Module - End */ 
  
  changeContractStatus = (e) => {
    this.setState({ statusValue: e.currentTarget.textContent })
    const contract = this.state.contract;
    contract['status'] = e.currentTarget.value;   
    this.setState({contract }) 

    if(e.currentTarget.value == 'Current'){
      this.setState({ ed_disp : false }) 
    }else{
      this.setState({ ed_disp : true }) 
    }
  }

  handleChange = (e) => {
    const { name , value  } = e.target;
    const contract = {...this.state.contract};
    contract[name]=value;
    this.setState({ contract });
  }

  handleChecked = (e,type) => {  
      const contract = {...this.state.contract};  
      if(e.target.checked == true){       
        contract[type]='y';        
      }else{
        contract[type]='n';        
      } 
      this.setState({ contract }); 
  }

  handleDeleteContract = (contractId) =>{
      const token = localStorage.getItem('cccAuthToken');
      this.setState({ loader:true });  
      http.post(process.env.REACT_APP_API_URL+'/delete-contract', { id : contractId},{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => {  

          toast.success(response.data.message);          

          const con = this.state.contractList;
          const contractList =con.filter(cl => cl.id != contractId);
          this.setState( { contractList });
          this.setState({ loader:false, isEdit : false,contract_id:'', providerValue: 'Select Contract',deleteModal:false,dispDel:false });  
          this.props.deleteContract(contractId)
        })
        .catch((error) => {
          this.setState({ loader:false });   
          if(error.response && error.response.status != 200){
             toast.error(error.response.data.message);
          }
        });
  }

  toggledeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
  }

  render() {
    const { deleteModal,ed_disp,loader,contractList,contract,isEdit,dispDel} = this.state
    return (
      <Fragment>
        {loader && <Loading />}
        <TabPane tabId="2">
          <div className="sub-title d-flex justify-content-between align-items-center">
            <em>Choose contract to edit </em>
            
            <span><a href="javascript:void(0)" onClick={ this.handleSubmit }>SAVE CHANGES</a>
            { dispDel == true ? 
             <a href="javascript:void(0)" onClick={ () => this.toggledeleteModal() }> &nbsp;&nbsp;|&nbsp;&nbsp;DELETE</a> : '' }</span>
          </div>
          <UncontrolledDropdown setActiveFromChild className="custom-dropdown selected-edit-contract">
            <DropdownToggle className="dropdown-toggle d-flex justify-content-between align-items-center">{this.state.providerValue}</DropdownToggle>
            <DropdownMenu>
              <Scrollbars className="scrollable-dropdown"
                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                { contractList.map(con => <DropdownItem  onClick={ this.changeContract } value={con.id} >{ con.provider +'(' + con.status + ' ' +con.c_live_date  + ' ' + (con.c_end_date != null ? con.c_end_date : '')+ ')' + ' ' +con.contract_name }</DropdownItem> )}
              </Scrollbars>
            </DropdownMenu>
          </UncontrolledDropdown>
         
         { isEdit == true ? 
         <Fragment>
         <input type="hidden" className="form-control" value={ contract.id }  name='id'/>
         <ul className="common-input-list common-inputs mb-0">
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>Contract Name</Label>
                <Input type="text" name='contract_name' value={ contract.contract_name } onChange={(e) => { this.handleChange(e) }} />
              </FormGroup>
            </li>
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>Enhanced Ci Cover Add On</Label>
                <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                  <DropdownToggle className="dropdown-toggle">{this.state.ci_cover_value}</DropdownToggle>
                  <DropdownMenu>
                    <Scrollbars className="scrollable-dropdown"
                      renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                      renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                      renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                      renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                      <p className="new-provider d-flex justify-content-between align-items-center">Choose An Option</p>
                      <DropdownItem  onClick={ (e) => this.changeAddOne('enhanced_ci_cover',e) } value='Yes' >Yes</DropdownItem>                
                      <DropdownItem onClick={ (e) => this.changeAddOne('enhanced_ci_cover',e) } value='No' >No</DropdownItem>                
                    </Scrollbars>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FormGroup>
            </li>
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>Enhanced Child Cover Add On</Label>
                <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                  <DropdownToggle className="dropdown-toggle">{this.state.child_cover_value}</DropdownToggle>
                  <DropdownMenu>
                    <Scrollbars className="scrollable-dropdown"
                      renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                      renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                      renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                      renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                      <p className="new-provider d-flex justify-content-between align-items-center">Choose An Option</p>
                      <DropdownItem  onClick={ (e) => this.changeAddOne('enhanced_child_cover',e) } value='Yes' >Yes</DropdownItem>                
                      <DropdownItem onClick={ (e) => this.changeAddOne('enhanced_child_cover',e) } value='No' >No</DropdownItem>                
                    </Scrollbars>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FormGroup>
            </li>
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>Contract status</Label>
                <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                  <DropdownToggle className="dropdown-toggle">{this.state.statusValue}</DropdownToggle>
                  <DropdownMenu>
                    <Scrollbars className="scrollable-dropdown"
                      renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                      renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                      renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                      renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                      <DropdownItem value='Current'  onClick={this.changeContractStatus}>Current</DropdownItem>
                      <DropdownItem value='Historic' onClick={this.changeContractStatus}>Historic</DropdownItem>
                    </Scrollbars>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FormGroup>
            </li>
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>Contract live date</Label>
                <input type="date" className="form-control" value={ contract.live_date } onChange={(e) => { this.handleChange(e) }} name='live_date'/>
              </FormGroup>
            </li>
            { ed_disp == true ? 
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>Contract end date</Label>
                <input type="date" className="form-control" value={ contract.end_date } onChange={(e) => { this.handleChange(e) }} name='end_date'/>
              </FormGroup>
            </li> : false 
           }
          </ul>
          <div className="common-accordion">   
            <EditFullPaymentCondition fpd = { contract.full_payment_data } />    
            {/* <EditAdditionalPaymentCondition apd = { contract.additional_payment_data } />  */}                    
            <EditChildrenCover onChange = { this.handleChange } onChecked = { this.handleChecked } contract={ contract } /> 
            <EditChildSpecificCondition csc = { contract.child_payment_data } />    
            <EditExtraFeatureCondition efd = { contract.extra_feature_data } />    
          </div>
          </Fragment>
          : null}
        </TabPane> 

          <Modal isOpen={this.state.deleteModal} toggle={this.toggledeleteModal} className="add-provider-modal">
            <ModalBody>            
                <div className="action-button d-flex justify-content-end">
                  <Button type="button" onClick={this.toggledeleteModal}>No</Button>
                  <Button type="button" onClick={ () => this.handleDeleteContract(contract.id) } >Yes</Button>
                </div>
                <div className="modal-body">
                  <FormGroup>
                    <Label htmlFor="contractFeature"><em>Are you sure want to delete this?</em></Label>                  
                  </FormGroup>                
                </div>           
            </ModalBody>
          </Modal>

          <Modal isOpen={this.state.errPopup} toggle={() => this.setState({ errPopup : false })} className="add-provider-modal">
          <ModalBody>            
              <div className="action-button d-flex justify-content-end">
                <Button type="button" onClick={() => this.setState({ errPopup : false })}>Ok</Button>                
              </div>
              <div className="modal-body">
                <FormGroup>
                  <Label htmlFor="contractFeature"><em>{ this.state.errMsg}</em></Label>                  
                </FormGroup>                
              </div>           
          </ModalBody>
        </Modal>

      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  fullPayemntData: state.editfullPaymentCondition,
  additionalPayemntData: state.editAditionalPaymentCondition,
  childPayemntData: state.editChildSpecificCondition,
  extraFeatureData: state.editExtraFeatureCondition,
  createContractData: state.CreateContract,
})

const mapDispatchToProps = (dispatch) =>{
  return {
    deleteContract:(data) => { dispatch({ type: 'DELETE_CONTRACT',payload:{ data : data } }) }   
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(EditContract)
