import React, { Component, Fragment } from 'react'
import {
  Form,Nav, TabContent, TabPane, NavItem, Label, Button, FormGroup, Input, CustomInput, Modal, ModalBody, CardHeader, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, UncontrolledCollapse, Card
} from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import Loading from '../../../../components/Loading'
import config from '../../../../config.json';
import { ToastContainer,toast } from 'react-toastify';
import http from '../../../../httpService/httpService';
import autosize from 'autosize';

const initialState = {
  edittoggleAdditionalPayment: false,
  toggleBtn: '',
  conditionTitle: 'Additional payment conditions',
  conditionItem : [],
  type:'addtional_payment_conditions',
  loader:false,
  cData:{ title:'',event_description:'',event_category_id:'',gender:'',status:'',id:''},
  errors :  { errTitle : ''},
  token:localStorage.getItem('cccAuthToken'),
  checkedBtn : [],
  additional_payemnt_data:[],
  eventcategorytitle: 'Select Category',
  eventCategoryItem: []
}

class EditAdditionalPaymentCondition extends Component {
  constructor(props) {
    super(props)
  }

  state = initialState

  componentWillMount() {
     
   if(this.props.apd){
      this.setState({ initialState });
      const apd = this.props.apd;
      let additional_payemnt_data  = [];
      const checkedBtn = [];
     

      apd.map(f => 
          { additional_payemnt_data.push({ 
            fid : f.condition_id , 
            contract_id :f.contract_id,
            max_claim_amount_pound : f.max_claim_amount_pound ,
            max_claim_amount_percentage:f.max_claim_amount_percentage,
            benefit_reduced:f.benefit_reduced,
            defination:f.defination,
            specified_cover:f.specified_cover,
            other_cover:f.other_cover,
            id:f.id
          })
          checkedBtn.push(parseInt(f.condition_id));        
          
        }

      )
      
      this.setState({ additional_payemnt_data });      
      this.props.setEditAdditionalConditionData(additional_payemnt_data);
      this.setState({ checkedBtn });  
   
    }
  }

  componentWillReceiveProps(nextProps){     

    if(nextProps.conditions && nextProps.conditions.type == 'ADDITIONAL_LIST'){
      const conditionItem = nextProps.conditions.data;
      this.setState({ conditionItem });   
      autosize(document.querySelectorAll('textarea'));  
      console.log('dev') 
    }
    
    if(nextProps.apd){
      this.setState({  initialState });
      const apd = nextProps.apd;
      let additional_payemnt_data  = [];
      const checkedBtn = [];
      
      apd.map(f => 
        { 

          additional_payemnt_data.push({ 
            fid : f.condition_id , 
            contract_id :f.contract_id,
            max_claim_amount_pound : f.max_claim_amount_pound ,
            max_claim_amount_percentage:f.max_claim_amount_percentage,
            benefit_reduced:f.benefit_reduced,
            defination:f.defination,
            specified_cover:f.specified_cover,
            other_cover:f.other_cover,
            id:f.id
          })

          checkedBtn.push(parseInt(f.condition_id));       

        }
      )
      this.props.setEditAdditionalConditionData(additional_payemnt_data);
      this.setState({ additional_payemnt_data });
      this.setState({ checkedBtn });  

    }
    
  }

  handleAdClick = (e,id,field) =>{
    let additional_payemnt_data  = [];
    if(field == 'apc'){
     
      if(e.target.checked == true){
        additional_payemnt_data = this.state.additional_payemnt_data.concat({ fid : id});
        this.setState({ additional_payemnt_data });
      }else{
        const ofl = this.state.additional_payemnt_data;
        additional_payemnt_data = ofl.filter(fl => fl.fid != id);
        this.setState({ additional_payemnt_data });
      }
      
    }

    if(field == 'prc'){
      additional_payemnt_data = this.state.additional_payemnt_data;
      const apc = additional_payemnt_data.find(fl => fl.fid == id);  
      
      if(apc){
        const index = additional_payemnt_data.indexOf(apc);
        additional_payemnt_data[index]={...apc,benefit_reduced : e.target.checked == true ? 'y' :'n' };
        this.setState({ additional_payemnt_data });
      }
     
    }

    if(field == 'scc'){
      additional_payemnt_data = this.state.additional_payemnt_data;
      const apc = additional_payemnt_data.find(fl => fl.fid == id);  
      
      if(apc){
        const index = additional_payemnt_data.indexOf(apc);
        additional_payemnt_data[index]={...apc,specified_cover : e.target.checked == true ? 'y' :'n','other_cover':'n' };
        this.setState({ additional_payemnt_data });
      }
     
    }

    if(field == 'ccboh'){
      additional_payemnt_data = this.state.additional_payemnt_data;
      const apc = additional_payemnt_data.find(fl => fl.fid == id);  
      
      if(apc){
        const index = additional_payemnt_data.indexOf(apc);
        additional_payemnt_data[index]={...apc,other_cover : e.target.checked == true ? 'y' :'n','specified_cover':'n' };
        this.setState({ additional_payemnt_data });
      }
     
    }

    this.props.setEditAdditionalConditionData(additional_payemnt_data);
  }

  handleAcChange = (e,id) =>{

    const { name , value  } = e.target;
    const additional_payemnt_data = this.state.additional_payemnt_data;
    const apd = additional_payemnt_data.find(fl => fl.fid == id);  

    if(apd){
      const index = additional_payemnt_data.indexOf(apd);
      additional_payemnt_data[index][name] = value;
      this.setState({additional_payemnt_data });
    }
    this.props.setEditAdditionalConditionData(additional_payemnt_data);

  }

  async componentDidMount(){

    await http.post(process.env.REACT_APP_API_URL+'/get-conditions-lists', { type :this.state.type },{
      headers: {
        'Authorization': `Bearer ${this.state.token}`
      }
    })
    .then((response) => { 
      const conditionItem = response.data.data;
      this.setState( { conditionItem });
      autosize(document.querySelectorAll('textarea'));
    })
    .catch((error) => {
      if(error.response && error.response.status != 200){
         toast.error(error.response.data.message);
      }
    });

    const token = localStorage.getItem('cccAuthToken');
      //Get Event Category
    await http.get(process.env.REACT_APP_API_URL + '/get-event-categories', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      const category = response.data.data;
      this.setState({'eventCategoryItem': category})
    });
    
  }


  toggleDiv = (id) => {
    const checkedBtn = this.state.checkedBtn;
    if(this.state.toggleBtn == id){
      this.setState({ toggleBtn: ''})
    }else{
      if(checkedBtn.includes(id)){
         this.setState({ toggleBtn: id})
       } 
    }
      
  }

  handleChecked = (id) => {
    const checkedBtn = this.state.checkedBtn;
    if(checkedBtn.includes(id)){
      const filteredBtn= checkedBtn.filter(item => item !== id)
      this.setState({ checkedBtn: filteredBtn })

    }else{
      const filteredBtn = [...this.state.checkedBtn,id];
      this.setState({ checkedBtn: filteredBtn })
    }    
  }

   validateCreateCondition = () => {
    const { cData  } = this.state;    
    const errors = {};

    
    if (!cData.title) {
      errors.errTitle = 'The condition needs a name';
    }
    if (!cData.event_category_id) {
      errors.errEventCategory = 'Please select event category';
    }
    if (!cData.gender) {
      errors.errGender = 'Please select gender Specific';
    }
    if (!cData.event_description) {
      errors.errEventDescription = 'Please enter event description';
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0 ? null : errors;
  }

  handleCreateCondition = e => {
    e.preventDefault();
    const { cData } = this.state;

    let title = cData.title;
    let event_category_id = cData.event_category_id;
    let gender = cData.gender;
    let event_description = cData.event_description;
    let type = this.state.type;

    if(this.validateCreateCondition() === null){
      this.setState({ loader:true });      
      http.post(process.env.REACT_APP_API_URL+'/create-condition', { title,event_category_id,gender,event_description, type   },{
        headers: {
          'Authorization': `Bearer ${this.state.token}`
        }
      })
      .then((response) => { 
        this.setState({ loader:false });       
        toast.success(response.data.message);
         const cc = [...this.state.conditionItem,{
            title: response.data.data.title,
            event_description: response.data.data.event_description,
            event_category_id: response.data.data.event_category_id,
            gender: response.data.data.gender,
            id: response.data.data.id,
            status: response.data.data.status
         }];

        const conditionItem = cc.sort((a, b) => a.title.localeCompare(b.title))
        this.toggleAddConditionModal();
        this.setState({ conditionItem });
        this.props.setCondtionData(conditionItem)
        autosize(document.querySelectorAll('textarea'));    
      })
      .catch((error) => {
        this.setState({ loader:false });   
        if(error.response && error.response.status != 200){
           toast.error(error.response.data.message);
        }
      });
    }   
  }


  handleChange = (e) => {
    const { name , value  } = e.target;
    const cData = {...this.state.cData};
    cData[name]=value;
    this.setState({cData });
  }

  toggleAddConditionModal = () => {
    this.setState({
      addConditionModal: !this.state.addConditionModal
    });
    this.setState({ errors : { errTitle : ''} });
    this.setState({ 
      cData:{ title:'',id:''},
      eventcategorytitle: 'Select Category',
      gendertitle : 'Select Gender'
    });
  }

  moveCaretAtEnd = (e) =>{
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`; 
  }  


  handleChangeCategory = (e) => {
    const { value } = e.target;
    const cData = { ...this.state.cData };
    cData['event_category_id'] = value;
    this.setState({ cData });
    this.setState({ 'eventcategorytitle' : e.target.textContent })
  }

   handleChangeGender = (e) => {
    const { value } = e.target;
    const cData = { ...this.state.cData };
    cData['gender'] = value;
    this.setState({ cData });
    this.setState({ 'gendertitle' : value })
  }

  render() {    
    const { eventCategoryItem,eventcategorytitle,checkedBtn,conditionItem,loader,errors,edittoggleAdditionalPayment,toggleBtn } = this.state
    const acd = this.state.additional_payemnt_data.filter(fc => fc.specified_cover == 'y')
    return (
      <Fragment>
         {loader && <Loading />}
         <Card>
          <CardHeader>
            <Button id="edittoggleAdditionalPayment" className={classnames('card-link', { 'active': edittoggleAdditionalPayment })} onClick={() => { this.setState({ edittoggleAdditionalPayment: !edittoggleAdditionalPayment }) }}>{ this.state.conditionTitle} ({ acd.length })</Button>
          </CardHeader>
          <UncontrolledCollapse toggler="#edittoggleAdditionalPayment" className="inner-collapse">
            <div className="sub-title d-flex justify-content-end align-items-center">
              <Button onClick={this.toggleAddConditionModal}>Add condition to database</Button>
            </div>           
            <Scrollbars className={classnames('scrollable-inner-accordion', { 'long-scroll': false })}
              renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
              renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
              renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
              renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
              <div id="EditNewAdditionalCon" className="inner-accordion">
                { conditionItem.map(cl => {

                  const additional_payemnt_data = this.state.additional_payemnt_data;
                  let apd = additional_payemnt_data.find(fl => fl.fid == cl.id); 
                  
                  if(!apd){                   
                    apd = {
                      fid : '', 
                      contract_id :'',
                      max_claim_amount_pound : '' ,
                      max_claim_amount_percentage:'',
                      benefit_reduced:'',
                      defination:'',
                      specified_cover:'',
                      other_cover:'',
                      id:''
                    }                    
                  }

                  return <Card className={classnames({ 'active': (toggleBtn == cl.id ? true :false) })}>
                    <CardHeader>
                      <CustomInput type="checkbox"  onClick={ (e) => { this.handleChecked(cl.id); this.handleAdClick(e,cl.id,'apc') } }  checked = { (checkedBtn.includes(cl.id)? true :false) } id={`editnewAdditionalCheckHead1-`+cl.id} />
                      <Button id={`edittoggleAdditionalPayment-`+cl.id} className={classnames('card-link', { 'active': (toggleBtn == cl.id ? true :false) })} onClick={() => this.toggleDiv(cl.id)}>{ cl.title }</Button>
                    </CardHeader>
                    <UncontrolledCollapse isOpen={ (toggleBtn == cl.id ? true :false) }  toggler={`#edittoggleAdditionalPayment-`+cl.id}>
                      <ul className="common-input-list mb-0">                      
                        
                        <li className="d-flex justify-content-between align-items-center">
                          <Label className="form-check-label" htmlFor={`editAAnewContractCheck1ss-`+cl.id}>Specific Condition Covered</Label>
                          <CustomInput type="radio" name={`eascc-`+cl.id} checked = { apd.specified_cover == 'y'  ? 'checked': '' }  id={`editAAnewContractCheck1ss-`+cl.id } checked={ apd.specified_cover == 'y' ? 'checked' : '' } onClick = { (e) => { this.handleAdClick(e,cl.id,'scc') } }/>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <Label className="form-check-label" htmlFor={`editAAnewContractCheck1oc-`+cl.id}>Condition Covered By Other Heading</Label>
                          <CustomInput type="radio" name={`eascc-`+cl.id} checked = { apd.other_cover == 'y'  ? 'checked': '' }  id={`editAAnewContractCheck1oc-`+cl.id } checked={ apd.other_cover == 'y' ? 'checked' : '' } onClick = { (e) => { this.handleAdClick(e,cl.id,'ccboh') } }/>
                        </li>

                        <li className="d-flex justify-content-between align-items-center">
                          <Label>MAXIMUM CLAIM AMOUNT(£)</Label>
                          <Input type="text" name='max_claim_amount_pound' value={ apd.max_claim_amount_pound } onChange={ (e) => this.handleAcChange(e,cl.id) } />
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <Label>MAXIMUM CLAIM AMOUNT(%)</Label>
                          <Input type="text" name='max_claim_amount_percentage' value={ apd.max_claim_amount_percentage }  onChange={ (e) => this.handleAcChange(e,cl.id) }/>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                          <Label className="form-check-label" htmlFor={`EditnewAdditionChk2-`+cl.id} >
                            Plan benefit reduced
                            </Label>
                          <CustomInput type="checkbox" checked = { apd.benefit_reduced == 'y'  ? 'checked': '' } name='benefit_reduced' onClick={ (e) => this.handleAdClick(e,cl.id,'prc') } id={`EditnewAdditionChk2-`+cl.id} />
                        </li>
                        <li className="definition-input">
                          <Label>condition definition</Label>
                          <Scrollbars className="scrollable-textarea"
                            renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                            renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                            renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                            renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                            <Input type="textarea" rows={10} onFocus={this.moveCaretAtEnd} value={ apd.defination } name='defination' placeholder="You can write condition definition here...." onChange={ (e) => this.handleAcChange(e,cl.id) } />
                          </Scrollbars>
                        </li>
                      </ul>
                    </UncontrolledCollapse>
                  </Card> 
                }
                )}                      
              </div>
            </Scrollbars>
          </UncontrolledCollapse>
        </Card>  
        <Modal isOpen={this.state.addConditionModal} toggle={this.toggleAddConditionModal}>
          <ModalBody>
            {loader && <Loading />}
            <Form method="post" onSubmit= { this.handleCreateCondition } id='createCondition'>
              <div className="action-button d-flex justify-content-end">
                <Button type="button" onClick={this.toggleAddConditionModal }>Abandon</Button>
                <Button type="submit">SAVE & CLOSE</Button>
              </div>
              <div className="modal-body">
                <FormGroup>
                  <Label for="conditionName"><em>Condition name</em></Label>
                  <Input type="text" id="title" name='title' onChange={(e) => { this.handleChange(e) }}/>
                  { errors.errTitle && <p className="error-text"> <em> { errors.errTitle } </em></p> }
                </FormGroup>
                 <FormGroup>
                    <Label for="eventCat"><em>Event category</em></Label>
                    <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                      <DropdownToggle className="dropdown-toggle d-flex justify-content-between align-items-center">{this.state.eventcategorytitle}</DropdownToggle>
                      <DropdownMenu>
                        <Scrollbars className="scrollable-dropdown"
                          renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                          renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                          renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                          renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                          { eventCategoryItem.map(eventCat =>
                            <DropdownItem value={ eventCat.id} onClick={(e) => { this.handleChangeCategory(e) }} >{ eventCat.title }</DropdownItem>
                          )}                        
                          
                        </Scrollbars>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {errors.errEventCategory && <p className="error-text"> <em> {errors.errEventCategory} </em></p>}
                  </FormGroup>


                  <FormGroup>
                    <Label for="eventCat"><em>Gender Specific</em></Label>
                    <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                      <DropdownToggle className="dropdown-toggle d-flex justify-content-between align-items-center">{ this.state.gendertitle ?? 'Select Gender'}</DropdownToggle>
                      <DropdownMenu>
                        <Scrollbars className="scrollable-dropdown"
                          renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                          renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                          renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                          renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                        
                          <DropdownItem value='Male' onClick={(e) => { this.handleChangeGender(e) }}>Male</DropdownItem>
                          <DropdownItem value='Female' onClick={(e) => { this.handleChangeGender(e) }}>Female</DropdownItem>
                          <DropdownItem value='All' onClick={(e) => { this.handleChangeGender(e) }}>All</DropdownItem>
                          
                        </Scrollbars>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {errors.errGender && <p className="error-text"> <em> {errors.errGender} </em></p>}
                  </FormGroup>

                  <FormGroup>
                    <Label for="eventDesc"><em>Event description</em></Label>
                    <Input type="textarea" name="event_description" onChange={(e) => { this.handleChange(e) }} id="EventDescription" />
                    {errors.errEventDescription && <p className="error-text"> <em> {errors.errEventDescription} </em></p>}
                  </FormGroup>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setEditAdditionalConditionData:(data) => { dispatch({ type: 'SET_EDIT_ADDITIONAL_DATA', payload:{ data : data } }) },
    setCondtionData:(data) => {dispatch({ type: 'ADDITIONAL_LIST', payload:{ data : data } }) }
  }
}
const mapStateToProps = state => ({
  conditions: state.conditions
})
export default connect(mapStateToProps,mapDispatchToProps)(EditAdditionalPaymentCondition)