import React, { Component, Fragment } from 'react'
import {
  Form, TabPane, Label, Button, FormGroup, Input, Modal, ModalBody, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from 'reactstrap';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import deleteIcon from '../../../assets/images/delete-icon.png';
import Loading from '../../../components/Loading'
import config from '../../../config.json';
import { ToastContainer, toast } from 'react-toastify';
import http from '../../../httpService/httpService';
import { connect } from 'react-redux';

class EditConditions extends Component {
  constructor(props) {
    super(props)
  }


  state = {
    conditionTitle: 'ADULT EVENTS',
    conditionItem: [],
    cEditDisp: false,
    type: 'full_payment_conditions',
    loader: false,
    cData: { title: '', id: '',event_category_id:'',gender:'',event_description:'' },
    errors: { errTitle: '',errEventCategory:'',errGender:'',errEventDescription:'' },
    con_id: '',
    eventCategoryItem: [],
    eventcategorytitle: 'Select Category',
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.conditions) {
        const token = localStorage.getItem('cccAuthToken');
        await http.post(process.env.REACT_APP_API_URL + '/get-conditions-lists', { type: this.state.type }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => {
          const conditionItem = response.data.data;
          this.setState({ conditionItem });
          this.setState({ loader: false });
          this.setState({ cEditDisp: false });
        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error.response && error.response.status != 200) {
            // toast.error(error.response.data.message);
          }
        });
    }
  }

  async componentDidMount() {

      const token = localStorage.getItem('cccAuthToken');
      await http.post(process.env.REACT_APP_API_URL + '/get-conditions-lists', { type: this.state.type }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        const conditionItem = response.data.data;
        this.setState({ conditionItem });
      })
      .catch((error) => {
        this.setState({ loader: false });
        if (error.response && error.response.status != 200) {
          toast.error(error.response.data.message);
        }
      });


      //Get Event Category
      await http.get(process.env.REACT_APP_API_URL + '/get-event-categories', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        const category = response.data.data;
        this.setState({'eventCategoryItem': category})
      });

  }

  

  // Modal
  toggleAddConditionModal = () => {
    this.setState({
      addConditionModal: !this.state.addConditionModal
    });
    this.setState({ 
      errors: { errTitle: '',errEventCategory:'',errGender:'',errEventDescription:'' },
      cData: { title: '', id: '',event_category_id:'',gender:'',event_description:'' },
      cEditDisp: false,
      eventcategorytitle: 'Select Category',
      gendertitle : 'Select Gender'
    });
    
  }

  changeCondition = async (e) => {
    this.setState({ loader: true });
    this.setState({ conditionTitle: e.currentTarget.textContent })
    const type = e.currentTarget.value;

    this.setState({ type });
    const token = localStorage.getItem('cccAuthToken');
    await http.post(process.env.REACT_APP_API_URL + '/get-conditions-lists', { type: type }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        const conditionItem = response.data.data;
        this.setState({ conditionItem });
        this.setState({ loader: false });
        this.setState({ cEditDisp: false });
      })
      .catch((error) => {
        this.setState({ loader: false });
        if (error.response && error.response.status != 200) {
          toast.error(error.response.data.message);
        }
      });

  }
  /* Delete Conditions */
  deleteCon = async (cId) => {
    this.setState({ cEditDisp: false });
    this.setState({ loader: true });

    const token = localStorage.getItem('cccAuthToken');
    await http.post(process.env.REACT_APP_API_URL + '/delete-condition', { 'id': cId, 'type': this.state.type }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        this.setState({ loader: false });
        toast.success(response.data.message);
        this.setState({ deleteModal: false });

        const citem = this.state.conditionItem;
        const conditionItem = citem.filter(ci => ci.id != cId);
        this.setState({ conditionItem });
        this.props.setCondtionData(conditionItem, this.state.type)

      })
      .catch((error) => {
        this.setState({ loader: false });
        if (error.response && error.response.status != 200) {
          toast.error(error.response.data.message);
        }
      });

  }

  getConData = (cId) => {
    const cList = this.state.conditionItem;    
    const cdata = cList.find(ci => ci.id == cId);
    const cData = {
      title: cdata.title,
      id: cdata.id,
      event_category_id: cdata.event_category_id,
      event_description: cdata.event_description,
      gender: cdata.gender,
    };

    this.setState({ cData });
    this.setState({ 
      'cEditDisp': true,
      'eventcategorytitle' : (cdata.event_category && cdata.event_category.title) ? cdata.event_category.title :  'Select Category',
      'gendertitle' : cdata.gender ?? 'Select Gender'
    });
  }

  

  validate = () => {
    const { cData } = this.state;
    const errors = {};

    // Email Validation
    if (!cData.title) {
      errors.errTitle = 'The condition needs a name';
    }
    if (!cData.event_category_id && this.state.type !== 'extra_feature_payment_conditions') {
      errors.errEventCategory = 'Please select event category';
    }
    if (!cData.gender) {
      errors.errGender = 'Please select gender Specific';
    }
    if (!cData.event_description) {
      errors.errEventDescription = 'Please enter event description';
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0 ? null : errors;
  }

  /* Create Conditions */
  handleCreateCondition = e => {
    e.preventDefault();
    const { cData } = this.state;
    
    let title = cData.title;
    let event_category_id = cData.event_category_id;
    let gender = cData.gender;
    let event_description = cData.event_description;
    let type = this.state.type;

    if (this.validate() === null) {
      this.setState({ loader: true });
      const token = localStorage.getItem('cccAuthToken');
      http.post(process.env.REACT_APP_API_URL + '/create-condition', { title,event_category_id,gender,event_description, type }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then((response) => {
          this.setState({ loader: false });
          toast.success(response.data.message);

          const conditionItem = [...this.state.conditionItem, {
            title: response.data.data.title,
            event_description: response.data.data.event_description,
            event_category_id: response.data.data.event_category_id,
            gender: response.data.data.gender,
            id: response.data.data.id,
            status: response.data.data.status
          }];
          this.toggleAddConditionModal();
          this.setState({ conditionItem });
          this.props.setCondtionData(conditionItem, this.state.type)
        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error.response && error.response.status != 200) {
            toast.error(error.response.data.message);
          }
        });
    }
  }

  /* Edit Condition */
  handleSubmit = e => {
    e.preventDefault();
    const { cData, conditionItem } = this.state;
    let title = cData.title;
    let id = cData.id;
    let event_category_id = cData.event_category_id;
    let gender = cData.gender;
    let event_description = cData.event_description;
    let type = this.state.type;

    if (this.validate() === null) {
      this.setState({ loader: true });
      const token = localStorage.getItem('cccAuthToken');
      http.post(process.env.REACT_APP_API_URL + '/edit-condition', { id, title, type,event_category_id ,gender,event_description}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then((response) => {
          this.setState({ loader: false });
          toast.success(response.data.message);

          const cl = conditionItem.find(cls => cls.id == id);
          const index = conditionItem.indexOf(cl);
          conditionItem[index] = {
            title: title,
            event_category_id: event_category_id,
            gender: gender,
            event_description: event_description,
            id: id,
            status: cData.status
          };
          this.setState({ conditionItem });
          this.setState({ cEditDisp: false });
          this.props.setCondtionData(conditionItem, this.state.type)
        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error.response && error.response.status != 200) {
            toast.error(error.response.data.message);
          }
        });
    }
  }

  /* Add field Data for Update & Create */
  handleChange = (e) => {
    const { name, value } = e.target;
    const cData = { ...this.state.cData };
    cData[name] = value;
    this.setState({ cData });
  }

  handleChangeCategory = (e) => {
    const { value } = e.target;
    const cData = { ...this.state.cData };
    cData['event_category_id'] = value;
    this.setState({ cData });
    this.setState({ 'eventcategorytitle' : e.target.textContent })
  }

  handleChangeGender = (e) => {
    const { value } = e.target;
    const cData = { ...this.state.cData };
    cData['gender'] = value;
    this.setState({ cData });
    this.setState({ 'gendertitle' : value })
  }

  toggledeleteModal = (con_id) => {
    this.setState({ con_id: con_id });
    this.setState({
      deleteModal: !this.state.deleteModal
    });
  }

  render() {
    const { eventCategoryItem,cData, loader, cEditDisp, conditionItem, errors, toggledeleteModal, deleteModal, con_id } = this.state;
    return (
      <Fragment>
        {loader && <Loading />}
        <TabPane tabId="4">
          <div className="sub-title d-flex justify-content-between align-items-center">
            <em>Choose conditions to edit </em>
            <Button onClick={this.toggleAddConditionModal}>ADD CONDITION</Button>
          </div>
          <UncontrolledDropdown setActiveFromChild className="custom-dropdown selected-edit-contract">
            <DropdownToggle className="dropdown-toggle d-flex justify-content-between align-items-center dev">{this.state.conditionTitle}</DropdownToggle>
            <DropdownMenu>
              <Scrollbars className="scrollable-dropdown"
                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                <DropdownItem value='full_payment_conditions' onClick={this.changeCondition}>ADULT EVENTS</DropdownItem>
                {/* <DropdownItem value='addtional_payment_conditions' onClick={this.changeCondition}>Additional/partial payment conditions</DropdownItem> */}
                <DropdownItem value='child_payment_conditions' onClick={this.changeCondition}>Child Specific conditions </DropdownItem>
                <DropdownItem value='extra_feature_payment_conditions' onClick={this.changeCondition}>Further contract features</DropdownItem>
              </Scrollbars>
            </DropdownMenu>
          </UncontrolledDropdown>
          <Scrollbars className="scrollable-list"
            renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
            renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
            renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
            renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
            <ul className="check-list text-uppercase">
              {conditionItem.map(cl => <li  className="d-flex justify-content-between align-items-center"><span onClick={() => this.getConData(cl.id)} >{cl.title}</span><a className="delete-icon" onClick={() => this.toggledeleteModal(cl.id)}><img src={deleteIcon} alt="delete" /></a>
              </li>)}
            </ul>
          </Scrollbars>
         
          
          {cEditDisp == true ?
            <Fragment>
              <Form method="post" onSubmit={this.handleSubmit}>
                <div className="sub-title d-flex justify-content-between align-items-center">
                  <em>Condition selected</em>
                  <Button onClick={this.toggleSaveComprModal}>Update</Button>
                </div>
                <ul className="common-input-list">
                  <li className="d-flex justify-content-between align-items-center">
                    <Label>CONDITION TITLE</Label>
                    <Input type="text" value={cData.title} name='title' onChange={(e) => { this.handleChange(e) }} />
                    <Input type="hidden" value={cData.id} name='id' />
                  </li>
                </ul>{errors.errTitle && <p className="error-text"> <em> {errors.errTitle} </em></p>}

                { this.state.type !== 'extra_feature_payment_conditions' ? 
                <FormGroup> 
                  <Label for="eventDesc"><em>Event category</em></Label>               
                  <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                    <DropdownToggle className="dropdown-toggle d-flex justify-content-between align-items-center">{ this.state.eventcategorytitle }</DropdownToggle>
                    <DropdownMenu>
                      <Scrollbars className="scrollable-dropdown"
                        renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                        renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                        renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                        renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                        { eventCategoryItem.map(eventCat =>
                          <DropdownItem value={ eventCat.id} onClick={(e) => { this.handleChangeCategory(e) }}  >{ eventCat.title }</DropdownItem>
                        )}                        
                        
                      </Scrollbars>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {errors.errEventCategory && <p className="error-text"> <em> {errors.errEventCategory} </em></p>}
                </FormGroup>
                : ''
                }

                <FormGroup>
                  <Label for="eventDesc"><em>Gender Specific</em></Label>            
                  <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                    <DropdownToggle className="dropdown-toggle d-flex justify-content-between align-items-center">{ this.state.gendertitle ?? 'Select Gender'}</DropdownToggle>
                    <DropdownMenu>
                      <Scrollbars className="scrollable-dropdown"
                        renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                        renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                        renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                        renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                      
                        <DropdownItem value='Male'  onClick={(e) => { this.handleChangeGender(e) }}>Male</DropdownItem>
                        <DropdownItem value='Female'  onClick={(e) => { this.handleChangeGender(e) }}>Female</DropdownItem>
                        <DropdownItem value='All'  onClick={(e) => { this.handleChangeGender(e) }}>All</DropdownItem>
                        
                      </Scrollbars>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {errors.errGender && <p className="error-text"> <em> {errors.errGender} </em></p>}
                </FormGroup>
                <FormGroup>
                  <Label for="eventDesc"><em>Event description</em></Label>
                  <Input type="textarea" name="event_description" value={ cData.event_description ?? '' } onChange={(e) => { this.handleChange(e) }} id="EventDescription" />
                  {errors.errEventDescription && <p className="error-text"> <em> {errors.errEventDescription} </em></p>}
                </FormGroup>
                </Form></Fragment> : null
          }

        </TabPane>
        <Modal isOpen={this.state.addConditionModal} toggle={this.toggleAddConditionModal}>
          <ModalBody>
            {loader && <Loading />}
            <Form method="post" onSubmit={this.handleCreateCondition} id='createCondition'>
              <div className="action-button d-flex justify-content-end">
                <Button type="button" onClick={this.toggleAddConditionModal}>Abandon</Button>
                <Button type="submit">SAVE & CLOSE</Button>
              </div>
              <div className="modal-body">
                <FormGroup>
                  <Label for="conditionName"><em>Condition name</em></Label>
                  <Input type="text" id="title" name='title' onChange={(e) => { this.handleChange(e) }} />
                  {errors.errTitle && <p className="error-text"> <em> {errors.errTitle} </em></p>}
                </FormGroup>

                { this.state.type !== 'extra_feature_payment_conditions' ? 
                <FormGroup>
                  <Label for="eventCat"><em>Event category</em></Label>
                  <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                    <DropdownToggle className="dropdown-toggle d-flex justify-content-between align-items-center">{this.state.eventcategorytitle}</DropdownToggle>
                    <DropdownMenu>
                      <Scrollbars className="scrollable-dropdown"
                        renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                        renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                        renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                        renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                        { eventCategoryItem.map(eventCat =>
                          <DropdownItem value={ eventCat.id} onClick={(e) => { this.handleChangeCategory(e) }} >{ eventCat.title }</DropdownItem>
                        )}                        
                        
                      </Scrollbars>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {errors.errEventCategory && <p className="error-text"> <em> {errors.errEventCategory} </em></p>}
                </FormGroup>

                : '' }
                <FormGroup>
                  <Label for="eventCat"><em>Gender Specific</em></Label>
                  <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                    <DropdownToggle className="dropdown-toggle d-flex justify-content-between align-items-center">{ this.state.gendertitle ?? 'Select Gender'}</DropdownToggle>
                    <DropdownMenu>
                      <Scrollbars className="scrollable-dropdown"
                        renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                        renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                        renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                        renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                      
                        <DropdownItem value='Male' onClick={(e) => { this.handleChangeGender(e) }}>Male</DropdownItem>
                        <DropdownItem value='Female' onClick={(e) => { this.handleChangeGender(e) }}>Female</DropdownItem>
                        <DropdownItem value='All' onClick={(e) => { this.handleChangeGender(e) }}>All</DropdownItem>
                        
                      </Scrollbars>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {errors.errGender && <p className="error-text"> <em> {errors.errGender} </em></p>}
                </FormGroup>
                <FormGroup>
                  <Label for="eventDesc"><em>Event description</em></Label>
                  <Input type="textarea" name="event_description" onChange={(e) => { this.handleChange(e) }} id="EventDescription" />
                  {errors.errEventDescription && <p className="error-text"> <em> {errors.errEventDescription} </em></p>}
                </FormGroup>

              </div>
            </Form>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.deleteModal} toggle={this.toggledeleteModal} className="delete-condition-modal">
          <ModalBody>
            {loader && <Loading />}
            <div className="action-button d-flex justify-content-end">
              <Button type="button" onClick={this.toggledeleteModal}>No</Button>
              <Button type="button" onClick={() => this.deleteCon(con_id)} >Yes</Button>
            </div>
            <div className="modal-body">
              <FormGroup>
                <Label htmlFor="contractFeature"><em>Are you sure want to delete this?</em></Label>
              </FormGroup>
            </div>
          </ModalBody>
        </Modal>

      </Fragment>
    );
  }
}



const mapDispatchToProps = (dispatch) => {
  return {
    setCondtionData: (data, type) => {
      let constDispType = '';
      if (type == 'full_payment_conditions') {
        constDispType = 'FULL_PAYMENT_LIST';
      } else if (type == 'addtional_payment_conditions') {
        constDispType = 'ADDITIONAL_LIST';
      } else if (type == 'child_payment_conditions') {
        constDispType = 'CHILD_SPECIFIC_LIST';
      } else if (type == 'extra_feature_payment_conditions') {
        constDispType = 'FURTHER_LIST';
      } else { }
      if (constDispType != '') {
        dispatch({ type: constDispType, payload: { data: data } })
      }
    }
  }
}

const mapStateToProps = state => ({
  conditions: state.conditions
})

export default connect(mapStateToProps, mapDispatchToProps)(EditConditions)