import React, { Component, Fragment } from 'react'
import {
 Form, Nav, TabContent, TabPane, NavItem, Label, Button, FormGroup, CustomInput, Input, Modal, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import plusIcon from '../../../assets/images/plus-icon.png';
import deleteIcon from '../../../assets/images/delete-icon.png';
import http from '../../../httpService/httpService';
import { history } from '../../../index';
import Loading from '../../../components/Loading'
import config from '../../../config.json';
import { ToastContainer,toast } from 'react-toastify';
import { verifyLength, verifyEmail ,verifyPassword } from '../../../helper';
import { connect } from 'react-redux';

const initialState = {
  activeTab: '1',
  typeText: '',
  typeValue: '',
  statusText: '',
  statusValue: '',
  userList:[],
  dispUser:false,
  usr:[],
  account : { name:'',email : '',password:'', type:'',status:'',id:''},
  errors :  { errEmail : '',errPassword:'',errName : '',errType : '',errStatus : ''},
  selectedUser:''
};


class UserManage extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this);
    this.state = initialState
  }

  componentWillReceiveProps(nextProps){   
    if(nextProps.userData){
      const userList = [...this.state.userList,nextProps.userData];
      this.setState({ userList });
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  changeUserType = (e) => {
    this.setState({ typeText: e.currentTarget.textContent })
    this.setState({ typeValue: e.currentTarget.value })

    const account = {...this.state.account};
    account['type']=e.currentTarget.value;
    this.setState({account });
  }

  changeUserStatus = (e) => {
    this.setState({ statusText: e.currentTarget.textContent })
    this.setState({ statusValue: e.currentTarget.value })

    const account = {...this.state.account};
    account['status']=e.currentTarget.value;
    this.setState({account });
  }

  async componentDidMount(){

    const token = localStorage.getItem('cccAuthToken');
    await http.post(process.env.REACT_APP_API_URL+'/get-users', {},{
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => { 
      const userList = response.data.data;
      this.setState( { userList });
    })
    .catch((error) => {
      this.setState({ loader:false });   
      if(error.response && error.response.status != 200){
         toast.error(error.response.data.message);
      }
    });
    
  }

   deleteUser = async (userId) => {
      const uls = this.state.userList;
      const userList =uls.filter(usr => usr.id != userId);
      this.setState( { userList });
      this.setState( { dispUser : false} );
      const token = localStorage.getItem('cccAuthToken');
      await http.post(process.env.REACT_APP_API_URL+'/delete-user', {'id' : userId},{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => { 
        toast.success(response.data.message);
        this.setState({  deleteModal: false });
      })
      .catch((error) => {
        this.setState({ loader:false });   
        if(error.response && error.response.status != 200){
           toast.error(error.response.data.message);
        }
      });

  } 

  getUserData = (userId) =>{
    const usrList =this.state.userList;
    const usr = usrList.find(usr => usr.id == userId);
    const account =  { 
      name:usr.name,
      email : usr.email, 
      type:usr.type,
      status:usr.status,
      id:usr.id,
      password:''
    };
    this.setState({ account});
    this.setState({ 'dispUser': true });
    this.setState({ 'statusValue': account.status });
    this.setState({ 'statusText':  account.status == 'y' ? 'Active': 'Inactive' });
    this.setState({ 'typeValue': account.type });
    this.setState({ 'typeText': account.type });
    this.setState({ 'selectedUser': usr.id });
  }

  //Update user Data

  validate = () => {
    const { account  } = this.state;    
    const errors = {};
    if(account.name == ''){
      errors.errName = 'Name is required field';
    }

    if(account.type == ''){
      errors.errType = 'Type is required field';
    }

    if(account.status == ''){
      errors.errStatus = 'Status is required field';
    }

    if(account.password != ''){
      if(!verifyPassword(account.password)){
        errors.errPassword = 'Please enter password in between 6 to 15 character';
      } 
    }

     // Email Validation
    if(account.email == ''){
      errors.errEmail = 'Email is required field';
    }else if(!verifyEmail(account.email)){
      errors.errEmail = 'Please enter valid email';
    } 

    this.setState({ errors });

    if(Object.keys(errors).length > 0){
      Object.keys(errors).map(c => toast.error(errors[c]) )
    }
    
    return Object.keys(errors).length === 0 ? null : errors;

  }

  handleSubmit = e => {

    e.preventDefault();
    const { account,userList } = this.state;

    // let email =account.email; 
    let name = account.name;
    let type = account.type;
    let status = account.status;
    let id = account.id;
    let password = account.password;
    
    if(this.validate() === null){
        this.setState({ loader:true });
        const token = localStorage.getItem('cccAuthToken');
        http.post(process.env.REACT_APP_API_URL+'/edit-user', { id,name, type, status,password },{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => {  

          const usr = userList.find(usr => usr.id == id);     
          const index = userList.indexOf(usr);   
          userList[index]={
            name : account.name,
            type : account.type,
            status : account.status,
            id : account.id,
            email : account.email,
          };
          this.setState({ userList });

          toast.success(response.data.message); 
          this.setState({ 'dispUser': false });
          this.setState({  deleteModal: false });

        })
        .catch((error) => {
          this.setState({ loader:false });   
          if(error.response && error.response.status != 200){
             toast.error(error.response.data.message);
          }
        });
    }
   
  }

  handleChange = (e) => {
    const { name , value  } = e.target;
    const account = {...this.state.account};
    account[name]=value;
    this.setState({account });
  }

  toggledeleteModal = (user_id) => {
    this.setState({ user_id : user_id });
    this.setState({
      deleteModal: !this.state.deleteModal
    });
  }

  render() {
    const { selectedUser,userList,dispUser,account, statusValue , statusText , typeValue, typeText,toggledeleteModal,deleteModal,user_id} = this.state;
    return (
      <Fragment>
        <TabPane tabId="2">
          {/* {this.state.activeTab == 2 ? <h4>Tab 2 Contents</h4> : null} */}
          <div className="sub-title d-flex justify-content-between align-items-center">
            <em>Select to view user details</em>
          </div>
          <ul className="user-list user-list-head text-center text-uppercase">
            <li className="d-flex">
              <div>USER NAME</div>
              <div>USER type</div>
              <div>USER status</div>
            </li>
          </ul>
          <Scrollbars className="scrollable-list"
            renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
            renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
            renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
            renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
            <ul className="user-list text-center text-uppercase">
              
              { userList.map( user => 
                <li className={classnames('d-flex', { 'selected-user': (selectedUser==user.id?true:false) })}  onClick={ () => this.getUserData(user.id) }>
                  <div>{ user.name }</div>
                  <div>{ user.type }</div>
                  <div>{ user.status == 'y' ? 'Active' : 'Inactive' }</div>
                </li>
              )}              
              
            </ul>
          </Scrollbars>
          { dispUser == true ? ( <Fragment> <div className="sub-title d-flex justify-content-between align-items-center">
            <p>SELECTED USER PROFILE</p>
          </div>
          <Form method="post" onSubmit= { this.handleSubmit } id='editUser'>
          <ul className="common-input-list common-inputs">
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>User name</Label>
                <Input type="text" value={ account.name } name='name' onChange={(e) => { this.handleChange(e) }}/>
              </FormGroup>
            </li>
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>email address</Label>
                <Input type="email" value={ account.email } name='email'/>
              </FormGroup>
            </li>
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>Password</Label>
                <Input type="text" name='password' style={{ "text-transform":"none" }} onChange={(e) => { this.handleChange(e) }}/>
              </FormGroup>
            </li>             
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>user type</Label>
                <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                  <DropdownToggle className="dropdown-toggle">{ typeText }</DropdownToggle>
                  <DropdownMenu name='type'>
                    <DropdownItem value='admin' className={ typeValue == 'admin' ? 'active' : ''} onClick={this.changeUserType}>Admin</DropdownItem>
                    <DropdownItem value='basic' className={ typeValue == 'basic' ? 'active' : ''} onClick={this.changeUserType}>Basic</DropdownItem>                    
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FormGroup>
            </li>
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>user status</Label>
                <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                  <DropdownToggle className="dropdown-toggle">{ statusText }</DropdownToggle>
                  <DropdownMenu name='status'>
                    <DropdownItem value='y' className={ statusValue == 'y' ? 'active' : ''} onClick={this.changeUserStatus}>Active</DropdownItem>
                    <DropdownItem value='n' className={ statusValue == 'n' ? 'active' : ''} onClick={this.changeUserStatus}>Inactive</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FormGroup>
            </li>
          </ul>
          <div className="bottom-section text-right">
            <Button className="line-btn" onClick={ () => {this.setState({ dispUser : !dispUser }) } }>Close</Button>
            <Button className="line-btn" onClick={ () => this.toggledeleteModal(account.id) }>Delete</Button>
            <Button type="submit" className="line-btn">update</Button>
          </div></Form>

          <Modal isOpen={this.state.deleteModal} toggle={this.toggledeleteModal} className="add-provider-modal">
            <ModalBody>            
                <div className="action-button d-flex justify-content-end">
                  <Button type="button" onClick={this.toggledeleteModal}>No</Button>
                  <Button type="button" onClick={ () => this.deleteUser(user_id) } >Yes</Button>
                </div>
                <div className="modal-body">
                  <FormGroup>
                    <Label htmlFor="contractFeature"><em>Are you sure want to delete this?</em></Label>                  
                  </FormGroup>                
                </div>           
            </ModalBody>
          </Modal>

          </Fragment>) : null
          }        

        </TabPane>
      </Fragment >
    )
  }
}

const mapStateToProps = state => ({
  userData: state.user,  
})
export default connect(mapStateToProps)(UserManage)