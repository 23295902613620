import React, { Component, Fragment } from 'react'
import {
  Nav, TabContent, NavItem, Label, Button, FormGroup, Input, CustomInput, Modal, ModalBody, CardHeader, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, UncontrolledCollapse, Card
} from 'reactstrap';
import classnames from 'classnames';
import autosize from 'autosize';
import { Scrollbars } from 'react-custom-scrollbars';
import plusIcon from '../../assets/images/plus-icon.png';
import CreateContract from './CreateEditContract/CreateContract.jsx';
import EditContract from './CreateEditContract/EditContract.jsx';
import EditProviders from './CreateEditContract/EditProviders.jsx';
import EditConditions from './CreateEditContract/EditConditions.jsx';

class Filter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: '1',
      actions: [],
    };
  }  

  // Tab
  toggle = (tab) =>  {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }
  
  // Autosize textarea
  componentDidMount() {
    autosize(document.querySelectorAll('textarea'));
  }  

  render() {
    return (
      <Fragment>
        <div className="tab-section">
          <div className="tab-head d-flex justify-content-between align-items-center">
            <Nav tabs className="nav nav-pills">
              <NavItem>
                <a className={classnames('nav-link', { active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>Add new contract</a>
              </NavItem>
              <NavItem>
                <a className={classnames('nav-link', { active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>Edit exisiting contract</a>
              </NavItem>
              <NavItem>
                <a className={classnames('nav-link', { active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>providers</a>
              </NavItem>
              <NavItem>
                <a className={classnames('nav-link', { active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>conditions</a>
              </NavItem>
            </Nav>
            <Button className="close-btn" onClick={ () => this.props.onClose('filter') }><img src={plusIcon} alt="close" /></Button>
          </div>
          <TabContent className="tab-content" activeTab={this.state.activeTab}>
            <CreateContract />
            <EditContract />
            <EditProviders />
            <EditConditions />
          </TabContent>
        </div>
      </Fragment >
    )
  }
}
export default Filter
