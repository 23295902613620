import React, { Component, Fragment } from 'react'
import Header from '../../components/Header'
import { Link } from 'react-router-dom'
import { Button, FormGroup, Label, Input,Form} from 'reactstrap'
import closeIcon from '../../assets/images/close-icon.png';
import { ToastContainer,toast } from 'react-toastify';
import http from '../../httpService/httpService';
import Loading from '../../components/Loading'

const initialState = {    
	account : { first_name:'',last_name:'',dob:'',home_post_code:'',home_tel:''},
	loader : false,
};

class Search extends Component {
	constructor(props) {
		super(props)
		this.state = {
			results : [],			
			account : { first_name:'',last_name:'',dob:'',home_post_code:'',home_tel:''},		
			loader : false,
		}
	}

	validate = () => {
	    const { account  } = this.state;    
	    const errors = {};

	    if(account.first_name == '' && account.last_name == '' && account.dob == '' && account.home_post_code == '' && account.home_tel == ''){
	      errors.err = 'Please enter any search criteria';
	    }

	    this.setState({ errors });

	    if(Object.keys(errors).length > 0){
	      Object.keys(errors).map(c => toast.error(errors[c]) )
	    }
	    
	    return Object.keys(errors).length === 0 ? null : errors;
	}

	handleSubmit = e => {

	    e.preventDefault();
	    const { account } = this.state;
	   
	    let first_name = account.first_name;
	    let last_name = account.last_name;
	    let dob = account.dob;
	    let home_post_code = account.home_post_code;
	    let home_tel = account.home_tel;

	    if(this.validate() === null){
	        this.setState({ loader:true });
	        const token = localStorage.getItem('cccAuthToken');
	        http.post(process.env.REACT_APP_API_URL+'/search-record', { first_name,last_name,dob,home_post_code,home_tel },{
	          headers: {
	            'Authorization': `Bearer ${token}`
	          }
	        })
	        .then((response) => {  
	        	this.setState({ loader : false });   
				this.setState({ results : response.data.data})
				toast.success(response.data.message);
	        })
	        .catch((error) => {
	          this.setState({ loader:false });   
	          if(error.response && error.response.status != 200){
	             toast.error(error.response.data.message);
	          }
	        });
	    }	   
	}

	handleChange = (e) => {
	    const { name , value  } = e.target;
	    const account = {...this.state.account};
	    account[name]=value;
	    this.setState({account });
	}

	render() {
		const { results,loader } = this.state
		return (
			<Fragment>
				{loader && <Loading />}
				<Header onOpen={this.handleOpen} routename={ this.props.routename } />
				<main className="main-content">
					<div className="serach-section common-side-section">
						<section className="inner-section">
							<p className="d-flex justify-content-between">
								<b className="text-uppercase">Search for a contact</b>
								<Button color="link" tag={Link} to={`${process.env.PUBLIC_URL}`} className="close-btn">
									<img src={closeIcon} alt="close" />
								</Button>
							</p>
							<Form method="post" onSubmit= { this.handleSubmit } autocomplete="off">
								<div className="common-form">
									<FormGroup className="d-flex align-items-center">
										<Label for="firstName">First name</Label>
										<Input type="text" id="firstName" name="first_name" onChange={(e) => { this.handleChange(e) }}  placeholder="Enter First name" />
									</FormGroup>
									<FormGroup className="d-flex align-items-center">
										<Label for="lastName">last name</Label>
										<Input type="text" id="lastName" name="last_name" onChange={(e) => { this.handleChange(e) }} placeholder="Enter last name" />
									</FormGroup>
									<FormGroup className="d-flex align-items-center">
										<Label for="dob">DOB</Label>
										<Input type="date" id="dob" name="dob" onChange={(e) => { this.handleChange(e) }} placeholder="Enter DOB" />
									</FormGroup>
									<FormGroup className="d-flex align-items-center">
										<Label for="postcode">Postcode</Label>
										<Input type="text" id="postcode" name="home_post_code" onChange={(e) => { this.handleChange(e) }} placeholder="Enter Postcode" />
									</FormGroup>
									<FormGroup className="d-flex align-items-center">
										<Label for="telephone">Telephone</Label>
										<Input type="tel" id="telephone" name="home_tel" onChange={(e) => { this.handleChange(e) }} placeholder="Enter Telephone" />
									</FormGroup>
									<div className="text-right">
										<Button type="submit" className="succes-btn">Search</Button>
									</div>
								</div>
							</Form>
							{ results.length > 0 ?
							<Fragment>
								<h4>We’ve found the following records containing a contact matching that description</h4>
								<div className="record-list">
									{ results.map(res =>
										<div className="item">
											<p className="d-flex justify-content-between">
												<b>RECORD ID: { res.id }</b>
												<b className="light-text">ADVISING FIRM: { res.advising_firm }</b>
											</p>
											{/* Custoemr loop*/}
											{ res.customers.map(cust =>
												<div className="desc">
													<p>{cust.title+" "+cust.first_name+" "+cust.last_name } | {cust.fdob}</p>
													<p>{cust.home_address_1 }</p>
												</div>
											)}
											
											<div className="text-right"><Button tag={Link} to={`${process.env.PUBLIC_URL}/get-record/`+ res.id }  className="succes-btn" link>open record</Button></div>
										</div>
									)}
								</div>
							</Fragment> : '' }
						</section>
					</div>
				</main>
			</Fragment>
		)
	}
}

export default Search