import React from 'react'
import bannerImg from '../../../assets/images/login-banner.jpg';
import ForgotForm from './main-content';

const Forgot = (props) => {
  document.title = 'Forgot Password'
  return (

    <main>
        <section className="login-section d-flex">
          <div className="form-block">
            <h1>Forgot Password</h1>
            <p className="info">Let us know your email address, and we will send you a new one time passsword.</p>
            <ForgotForm />
          </div>
          <div className="img-block" style={{ backgroundImage: 'url(' + bannerImg + ')' }}>
          </div>
        </section>
      </main>
  )
}

export default Forgot