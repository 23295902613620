import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom'
import Routes from './routes'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import reducers from './reducers'
import { createStore, applyMiddleware, compose } from 'redux'
import ReduxThunk from 'redux-thunk'
import './assets/css/fontawesome.css';
import './assets/css/bootstrap.min.css';
import './assets/css/style.scss';
import Loading from './components/Loading'
import http from './httpService/httpService';
import config from './config.json';
import { ToastContainer,toast } from 'react-toastify';


const componentEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// export const store = createStore(reducers, {}, componentEnhancers(applyMiddleware(ReduxThunk)))
export const store = createStore(reducers, {}, (applyMiddleware(ReduxThunk)))

export const history = createBrowserHistory()

const jsx = (
	<Suspense fallback={<Loading />}>
		<Provider store={store}>
			<Router history={history}>
				<Routes />
			</Router>
			<ToastContainer />
		</Provider>
	</Suspense>
)

const hasRendered = false
const isCookieEnabled = navigator.cookieEnabled
if (isCookieEnabled) {
  ReactDOM.render(<Loading />, document.getElementById('root'))
}

const renderApp = () => {
  if (navigator.cookieEnabled) {
    ReactDOM.render(jsx, document.getElementById('root'));
  }
}

const token = localStorage.getItem('cccAuthToken');
if (token) {
  http.get(process.env.REACT_APP_API_URL+'/my-profile', { headers: { Authorization: `Bearer ${token}` } }).then((response) => {
    store.dispatch({
      type: 'USER_PROFILE',
      payload: {
        data: response.data.data,
        token
      }
    })
    renderApp()
  }).catch((error) => {
    renderApp()
  })
} else {
  renderApp()
}
