import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames';
import { Button } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
// import NotFoundArtwork from '../assets/images/404-artwork.svg'

class Footer extends Component {
  constructor(props) {
    super(props)
    this.toggleFooterTab = this.toggleFooterTab.bind(this);
    this.state = {
      isFooterTab: false,
      logs : props.logs
    };
  }
  toggleFooterTab() {
    const currentState = this.state.isFooterTab;
    this.setState({ isFooterTab: !currentState });
  };
  render() {
    const { isFooterTab,logs } = this.state
    return (
      <Fragment>
        <footer className="footer">
          <div className="footer-tab d-flex">
            <Button>products (0)</Button>
            <Button>enquiries (0)</Button>
            <Button>Tasks (0)</Button>
            <Button className={classnames({ 'active': isFooterTab })} onClick={this.toggleFooterTab}> Change log({logs.length})</Button>
          </div>
          <div className={classnames('footer-info', { 'active': isFooterTab })}>
            <div className="footer-inner">
              <h2>Change Logs</h2>
              <Scrollbars className="footer-scroll vertical-scrollable side-scroll"
                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                <div className="log-list">
                { logs.map(lg=>
                  <div className="item d-flex">
                    <p className="title">{lg.username} | {lg.date} | {lg.time}</p>
                    <p>{lg.content}</p>
                  </div>
                )}                 
                  
                </div>
              </Scrollbars>
            </div>
          </div>
        </footer>
      </Fragment>
    )
  }
}

export default Footer
