import React from 'react'
import bannerImg from '../../../assets/images/login-banner.jpg';
import ResetForm from './main-content';

const ResetPassword = (props) => {
  document.title = 'Reset Password'
  return (
    <main>
      <section className="login-section d-flex">
        <div className="form-block">
          <h1>Reset Password.</h1>
          <p className="info">Please enter new password to access the service.</p>
          <ResetForm {...props} />
        </div>
        <div className="img-block" style={{ backgroundImage: 'url(' + bannerImg + ')' }}>
        </div>
      </section>

    </main>
  )
}

export default ResetPassword