import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import classnames from 'classnames';
import logo from '../assets/images/logo.png';
import calenderIcon from '../assets/images/calender-icon.png';
import messageIcon from '../assets/images/message-icon.png';
import workloadIcon from '../assets/images/workload-icon.png';
import searchIcon from '../assets/images/search-icon.png';
import reportIcon from '../assets/images/report-icon.png';
import recordIcon from '../assets/images/add-record-icon.png';
import contactIcon from '../assets/images/contact-icon.png';
import toolIcon from '../assets/images/tool-icon.png';
import userIcon from '../assets/images/user-icon.png';
import LogoutIcon from '../assets/images/logout-icon.png';
import Loading from '../components/Loading';
import http from '../httpService/httpService';
import config from '../config.json';
import { history } from '../index'
import { connect } from 'react-redux';

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      loader: false,
    }
    this.toggle = this.toggle.bind(this)

  }
  toggle() {
    const { isOpen } = this.state
    this.setState({
      isOpen: !isOpen,
      loading: false
    })
  }

  handleLogout = () => {
    const token = localStorage.getItem('cccAuthToken');
    localStorage.removeItem('cccAuthToken')
    http.post(process.env.REACT_APP_API_URL + '/logout', {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      this.setState({ loader: false });
      history.push(process.env.PUBLIC_URL + '/login');
    })
      .catch((error) => {
        this.setState({ loader: false });
        history.push(process.env.PUBLIC_URL + '/login');
      });

    return;
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.userData.dispatchType === 'USER_PROFILE') {
      console.log(nextProps.userData.dispatchType);
    }
  }

  render(props) {
    const { isOpen, loader } = this.state
    const { userData,routename } = this.props
    return (
      <Fragment>
        {loader && <Loading />}
        <header>
          <Navbar expand="md">           
            <NavLink to={`${process.env.PUBLIC_URL}`} className="navbar-brand" >
              <img src={logo} alt="logo" />
            </NavLink>            
            <NavbarToggler onClick={this.toggle} className={classnames({ 'active': isOpen })}>
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
            </NavbarToggler>
            <Collapse isOpen={isOpen} navbar>
              <Nav className="navbar-nav user-nav ml-auto" navbar>
                {/* <li className="nav-item">
                  <NavLink to="#" className="nav-link d-flex align-items-center" activeClassName="active1">
                    <span className="icon-box"><img className="nav-icon" src={calenderIcon} /><span className="count">0</span></span>
                    <span className="item-text">calender</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="#" className="nav-link d-flex align-items-center" activeClassName="active1">
                    <span className="icon-box"><img className="nav-icon" src={messageIcon} /><span className="count">0</span></span>
                    <span className="item-text">message</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="#" className="nav-link d-flex align-items-center" activeClassName="active1">
                    <span className="icon-box"><img className="nav-icon" src={workloadIcon} /><span className="count">0</span></span>
                    <span className="item-text">workload</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="#" className={classnames('nav-link d-flex align-items-center', { 'active': (routename == 'search' ? 'active' : '') })}  activeClassName="active1">
                    <span className="icon-box"><img className="nav-icon" src={searchIcon} /></span>
                    <span className="item-text">search</span>
                  </NavLink>

                  <NavLink to={`${process.env.PUBLIC_URL}/search`} className={classnames('nav-link d-flex align-items-center', { 'active': (routename == 'search' ? 'active' : '') })}  activeClassName="active1">
                    <span className="icon-box"><img className="nav-icon" src={searchIcon} /></span>
                    <span className="item-text">search</span>
                  </NavLink> 

                </li>
                <li className="nav-item">
                  <NavLink to="#" className="nav-link d-flex align-items-center" activeClassName="active1">
                    <span className="icon-box"><img className="nav-icon" src={reportIcon} /></span>
                    <span className="item-text">report</span>
                  </NavLink>
                </li> */}
                {/* <li className="nav-item">
                  <NavLink to={`${process.env.PUBLIC_URL}/add-record`} className="nav-link d-flex align-items-center" activeClassName="active1">
                    <span className="icon-box"><img className="nav-icon" src={recordIcon} /></span>
                    <span className="item-text">record</span>
                  </NavLink>
                </li> */}

                {/*  <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav className={classnames('d-flex align-items-center', { 'active': (routename == 'add-record' ? 'active' : '') })} >
                    <span className="icon-box"><img className="nav-icon" src={recordIcon} /></span>
                    <span className="item-text">tool</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem className={ routename == 'add-record' ? 'active' : '' }><NavLink to={`${process.env.PUBLIC_URL}/add-record`}>CREATE RECORD</NavLink></DropdownItem>
                    <DropdownItem className={ routename == 'csv' ? 'active' : '' }><NavLink to={`${process.env.PUBLIC_URL}/csv`}>UPLOAD CVS FILE</NavLink></DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}


                {/*<UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav className={classnames('d-flex align-items-center', { 'active': (routename == 'add-record' ? 'active' : '') })} >
                    <span className="icon-box"><img className="nav-icon" src={recordIcon} /></span>
                    <span className="item-text">tool</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem className={ routename == 'add-record' ? 'active' : '' }><NavLink to='#' >CREATE RECORD</NavLink></DropdownItem>
                    <DropdownItem className={ routename == 'csv' ? 'active' : '' }><NavLink to='#' >UPLOAD CVS FILE</NavLink></DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <li className="nav-item">
                  <NavLink to="#" className="nav-link d-flex align-items-center" activeClassName="active1">
                    <span className="icon-box"><img className="nav-icon" src={contactIcon} /></span>
                    <span className="item-text">contact</span>
                  </NavLink>
                </li> */}
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav  className={classnames('d-flex align-items-center', { 'active': (routename == 'comparison' ? 'active' : '') })}>
                    <span className="icon-box"><img className="nav-icon" src={toolIcon} /></span>
                    <span className="item-text">tool</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem className={ (routename == 'comparison' ? 'active' : '') }><NavLink to={`${process.env.PUBLIC_URL}/comparison`}>CI contract comparison tool</NavLink></DropdownItem>
                    <DropdownItem>BMI CALCULATOR</DropdownItem>
                    <DropdownItem>UNDERWRITING REQUIREMENTS</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <li className="nav-item user-item">
                  <NavLink to={`${process.env.PUBLIC_URL}/comparison/user`} className="nav-link d-flex align-items-center" activeClassName={ (routename == 'comparison-user' ? 'active' : '') }>
                    <span className="icon-box"><img className="nav-icon" src={userIcon} /></span>
                    <span className="item-text">Logged in as:<b>{userData.name ? userData.name : 'Admin'}</b></span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/" onClick={this.handleLogout} activeClassName="active1" className="nav-link d-flex align-items-center">
                    <span className="icon-box"><img className="nav-icon" src={LogoutIcon} /></span>
                    <span className="item-text">Logout</span>
                  </NavLink>
                </li>
              </Nav>
            </Collapse>
          </Navbar>
        </header>
      </Fragment>
    )
  }
}


const mapStateToProps = state => ({
  userData: state.auth,
})


export default connect(mapStateToProps)(Header)
