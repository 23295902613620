import React from 'react'
import bannerImg from '../../../assets/images/login-banner.jpg';
import LoginForm from './main-content';

const Login = (props) => {
  document.title = 'Login'
  return (
    <main>
      <section className="login-section d-flex">
        <div className="form-block">
          <h1>Log in.</h1>
          <p className="info">Please enter your LoginID and password to access the service.</p>
          <LoginForm {...props}/>
        </div>
        <div className="img-block" style={{ backgroundImage: 'url(' + bannerImg + ')' }}>
        </div>
      </section>

    </main>
  )
}

export default Login