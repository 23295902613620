import React, { Component, Fragment } from 'react'
import {
  Form,Nav, TabContent, TabPane, NavItem, Label, Button, FormGroup, Input, CustomInput, Modal, ModalBody, CardHeader, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, UncontrolledCollapse, Card
} from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import Loading from '../../../components/Loading'
import config from '../../../config.json';
import { ToastContainer,toast } from 'react-toastify';
import http from '../../../httpService/httpService';
import autosize from 'autosize';

class ChildSpecificCondition extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    toggleChildPecificPayment: false,
    toggleBtn: '',  
    conditionTitle: 'Child Specific conditions',
    conditionItem : [],
    type:'child_payment_conditions',
    loader:false,
    cData:{ title:'',event_description:'',event_category_id:'',gender:'',status:'',id:''},
    errors :  { errTitle : ''},
    totalCon:0,
    token:localStorage.getItem('cccAuthToken'),
    checkedBtn : [],
    child_specific_data : [],
    eventcategorytitle: 'Select Category',
    eventCategoryItem: []
  }

   componentWillReceiveProps(nextProps){ 
    if(nextProps.conditions && nextProps.conditions.type == 'CHILD_SPECIFIC_LIST'){
      const cc = nextProps.conditions.data;
      const conditionItem = cc.sort((a, b) => a.title.localeCompare(b.title))
      this.setState({ conditionItem });  
      autosize(document.querySelectorAll('textarea'));      
    }

    if(nextProps.resetFrm == true ){
         this.setState({
            checkedBtn : [],
            totalCon:0,
            child_specific_data : []
         })

        Array.from(document.getElementsByClassName('dev-txt')).forEach(el => el.value = '');
        var inputs = document.querySelectorAll('.dev-check .custom-control-input');
        for (var i = 0; i < inputs.length; i++) {
          inputs[i].checked = false;
        }
    }
  }

  handleCSCClick = (e,id,field) =>{
    let child_specific_data  = [];
    if(field == 'csc'){
     
      if(e.target.checked == true){
        child_specific_data = this.state.child_specific_data.concat({ fid : id});
        this.setState({ child_specific_data });
      }else{
        const ofl = this.state.child_specific_data;
        child_specific_data = ofl.filter(fl => fl.fid != id);
        this.setState({ child_specific_data });
      }
      
    }

    if(field == 'prc'){
      child_specific_data = this.state.child_specific_data;
      const apc = child_specific_data.find(fl => fl.fid == id);     

      if(apc){
        const index = child_specific_data.indexOf(apc);
        child_specific_data[index]={...apc,benefit_reduced : e.target.checked == true ? 'y' :'n' };
        this.setState({ child_specific_data });
      }
    }

    this.props.setChildSpecificData(child_specific_data);
  }

   handleCSCChange = (e,id) =>{

    const { name , value  } = e.target;
    const child_specific_data = this.state.child_specific_data;
    const fpc = child_specific_data.find(fl => fl.fid == id);  

    if(fpc){
      const index = child_specific_data.indexOf(fpc);
      child_specific_data[index][name] = value;
      this.setState({child_specific_data });
      this.props.setChildSpecificData(child_specific_data);
    }

  }

  handleChange = (e) => {
    const { name , value  } = e.target;
    const cData = {...this.state.cData};
    cData[name]=value;
    this.setState({cData });
  }

  toggleAddConditionModal = () => {
    this.setState({
      addConditionModal: !this.state.addConditionModal
    });
    this.setState({ errors : { errTitle : ''} });
    this.setState({ 
      cData:{ title:'',id:''},
      eventcategorytitle: 'Select Category',
      gendertitle : 'Select Gender'
    });
  }

  async componentDidMount(){
    autosize(document.querySelectorAll('textarea')); 

    const token = localStorage.getItem('cccAuthToken');
    //Get Event Category
    await http.get(process.env.REACT_APP_API_URL + '/get-event-categories', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      const category = response.data.data;
      this.setState({'eventCategoryItem': category})
    });


  }

  validateCreateCondition = () => {
    const { cData  } = this.state;    
    const errors = {};

     // Email Validation
     if (!cData.title) {
      errors.errTitle = 'The condition needs a name';
    }
    if (!cData.event_category_id) {
      errors.errEventCategory = 'Please select event category';
    }
    if (!cData.gender) {
      errors.errGender = 'Please select gender Specific';
    }
    if (!cData.event_description) {
      errors.errEventDescription = 'Please enter event description';
    }


    this.setState({ errors });
    return Object.keys(errors).length === 0 ? null : errors;
  }

  handleCreateCondition = e => {
    e.preventDefault();
    const { cData } = this.state;

    let title = cData.title;
    let event_category_id = cData.event_category_id;
    let gender = cData.gender;
    let event_description = cData.event_description;
    let type = this.state.type;


    if(this.validateCreateCondition() === null){
      this.setState({ loader:true });      
      http.post(process.env.REACT_APP_API_URL+'/create-condition', { title,event_category_id,gender,event_description, type },{
        headers: {
          'Authorization': `Bearer ${this.state.token}`
        }
      })
      .then((response) => { 
        this.setState({ loader:false });       
        toast.success(response.data.message);
         const cc = [...this.state.conditionItem,{
            title: response.data.data.title,
            event_description: response.data.data.event_description,
            event_category_id: response.data.data.event_category_id,
            gender: response.data.data.gender,
            id: response.data.data.id,
            status: response.data.data.status
         }];

        const conditionItem = cc.sort((a, b) => a.title.localeCompare(b.title))
        this.toggleAddConditionModal();
        this.setState({ conditionItem });
        this.props.setCondtionData(conditionItem)
        autosize(document.querySelectorAll('textarea'));    
      })
      .catch((error) => {
        this.setState({ loader:false });   
        if(error.response && error.response.status != 200){
           toast.error(error.response.data.message);
        }
      });
    }   
  }

  toggleDiv = (id) => {
    const checkedBtn = this.state.checkedBtn;
    if(this.state.toggleBtn == id){
      this.setState({ toggleBtn: ''})
    }else{
      if(checkedBtn.includes(id)){
         this.setState({ toggleBtn: id})
       } 
    }
      
  }

  handleChecked = (id) => {
    const checkedBtn = this.state.checkedBtn;
    if(checkedBtn.includes(id)){
      const filteredBtn= checkedBtn.filter(item => item !== id)
      this.setState({ checkedBtn: filteredBtn })

      const totalCon = this.state.totalCon - 1;
      this.setState({ totalCon });

    }else{
      const filteredBtn = [...this.state.checkedBtn,id];
      this.setState({ checkedBtn: filteredBtn })

      const totalCon = this.state.totalCon + 1;
      this.setState({ totalCon });
    }    
  }

  handleChangeCategory = (e) => {
    const { value } = e.target;
    const cData = { ...this.state.cData };
    cData['event_category_id'] = value;
    this.setState({ cData });
    this.setState({ 'eventcategorytitle' : e.target.textContent })
  }

   handleChangeGender = (e) => {
    const { value } = e.target;
    const cData = { ...this.state.cData };
    cData['gender'] = value;
    this.setState({ cData });
    this.setState({ 'gendertitle' : value })
  }

  render() {    
    const { eventCategoryItem,eventcategorytitle,checkedBtn,conditionItem,totalCon,loader,errors,toggleChildPecificPayment,toggleBtn } = this.state
    return (
      <Fragment>
         {loader && <Loading />}
         <Card>
          <CardHeader>
            <Button id="toggleChildPecificPayment" className={classnames('card-link', { 'active': toggleChildPecificPayment })} onClick={() => { this.setState({ toggleChildPecificPayment: !toggleChildPecificPayment }) }}>{ this.state.conditionTitle} ({ totalCon })</Button>
          </CardHeader>
          <UncontrolledCollapse toggler="#toggleChildPecificPayment" className="inner-collapse">
            <div className="sub-title d-flex justify-content-end align-items-center">
              <Button onClick={this.toggleAddConditionModal}>Add condition to database</Button>
            </div>
            <Scrollbars className={classnames('scrollable-inner-accordion', { 'long-scroll': false })}
              renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
              renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
              renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
              renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
              <div id="newChildSpecificAccordion1" className="inner-accordion">
                { conditionItem.map(cl =>
                <Card className={classnames({ 'active': (toggleBtn === cl.id ? true :false) })}>
                  <CardHeader>
                    <CustomInput type="checkbox" onClick={ (e) => { this.handleChecked(cl.id); this.handleCSCClick(e,cl.id,'csc') } }  checked = { (checkedBtn.includes(cl.id)? true :false) } id={`newChildCheckHead1-`+cl.id} />
                    <Button id={`toggleChildPecificPayment-`+cl.id} className={classnames('card-link', { 'active': (toggleBtn === cl.id ? true :false) })} onClick={() => this.toggleDiv(cl.id)}>{ cl.title }</Button>
                  </CardHeader>
                  <UncontrolledCollapse isOpen={ (toggleBtn === cl.id ? true :false) }  toggler={`#toggleChildPecificPayment-`+cl.id}>
                    <ul className="common-input-list mb-0">                      
                      
                      <li className="d-flex justify-content-between align-items-center">
                        <Label>MAXIMUM CLAIM AMOUNT(£)</Label>
                        <Input type="text" className='dev-txt' name='max_claim_amount_pound' onChange={ (e) => this.handleCSCChange(e,cl.id) }/>
                      </li>
                      <li className="d-flex justify-content-between align-items-center">
                        <Label>MAXIMUM CLAIM AMOUNT(%)</Label>
                        <Input type="text" className='dev-txt' name='max_claim_amount_percentage' onChange={ (e) => this.handleCSCChange(e,cl.id) }/>
                      </li>
                      <li className="d-flex justify-content-between align-items-center">
                        <Label className="form-check-label" htmlFor={`newCSChk2-`+cl.id} >
                          Plan benefit reduced
                          </Label>
                        <CustomInput className='dev-check' type="checkbox" onClick={ (e) => this.handleCSCClick(e,cl.id,'prc')} name='benefit_reduced' id={`newCSChk2-`+cl.id}  />
                      </li>
                      <li className="definition-input">
                        <Label>condition definition</Label>
                        <Scrollbars className="scrollable-textarea"
                          renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                          renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                          renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                          renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                          <Input type="textarea" className='dev-txt' name='defination' onChange={ (e) => this.handleCSCChange(e,cl.id) } placeholder="You can write condition definition here...."  />
                        </Scrollbars>
                      </li>
                    </ul>
                  </UncontrolledCollapse>
                </Card> 
                )}                      
              </div>
            </Scrollbars>
          </UncontrolledCollapse>
        </Card>            
        <Modal isOpen={this.state.addConditionModal} toggle={this.toggleAddConditionModal}>
          <ModalBody>
            {loader && <Loading />}
            <Form method="post" onSubmit= { this.handleCreateCondition } id='createCondition'>
              <div className="action-button d-flex justify-content-end">
                <Button type="button" onClick={this.toggleAddConditionModal }>Abandon</Button>
                <Button type="submit">SAVE & CLOSE</Button>
              </div>
              <div className="modal-body">
                <FormGroup>
                  <Label for="conditionName"><em>Condition name</em></Label>
                  <Input type="text" id="title" name='title' onChange={(e) => { this.handleChange(e) }}/>
                  { errors.errTitle && <p className="error-text"> <em> { errors.errTitle } </em></p> }
                </FormGroup>

                <FormGroup>
                    <Label for="eventCat"><em>Event category</em></Label>
                    <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                      <DropdownToggle className="dropdown-toggle d-flex justify-content-between align-items-center">{this.state.eventcategorytitle}</DropdownToggle>
                      <DropdownMenu>
                        <Scrollbars className="scrollable-dropdown"
                          renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                          renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                          renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                          renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                          { eventCategoryItem.map(eventCat =>
                            <DropdownItem value={ eventCat.id} onClick={(e) => { this.handleChangeCategory(e) }} >{ eventCat.title }</DropdownItem>
                          )}                        
                          
                        </Scrollbars>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {errors.errEventCategory && <p className="error-text"> <em> {errors.errEventCategory} </em></p>}
                  </FormGroup>


                  <FormGroup>
                    <Label for="eventCat"><em>Gender Specific</em></Label>
                    <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                      <DropdownToggle className="dropdown-toggle d-flex justify-content-between align-items-center">{ this.state.gendertitle ?? 'Select Gender'}</DropdownToggle>
                      <DropdownMenu>
                        <Scrollbars className="scrollable-dropdown"
                          renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                          renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                          renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                          renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                        
                          <DropdownItem value='Male' onClick={(e) => { this.handleChangeGender(e) }}>Male</DropdownItem>
                          <DropdownItem value='Female' onClick={(e) => { this.handleChangeGender(e) }}>Female</DropdownItem>
                          <DropdownItem value='All' onClick={(e) => { this.handleChangeGender(e) }}>All</DropdownItem>
                          
                        </Scrollbars>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {errors.errGender && <p className="error-text"> <em> {errors.errGender} </em></p>}
                  </FormGroup>

                  <FormGroup>
                    <Label for="eventDesc"><em>Event description</em></Label>
                    <Input type="textarea" name="event_description" onChange={(e) => { this.handleChange(e) }} id="EventDescription" />
                    {errors.errEventDescription && <p className="error-text"> <em> {errors.errEventDescription} </em></p>}
                  </FormGroup>
                  
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setChildSpecificData:(data) => { dispatch({ type: 'SET_CHILDSPECIFIC_DATA', payload:{ data : data } }) },
    setCondtionData:(data) => {dispatch({ type: 'CHILD_SPECIFIC_LIST', payload:{ data : data } }) }
  }
}
const mapStateToProps = state => ({
  conditions: state.conditions
})

export default connect(mapStateToProps,mapDispatchToProps)(ChildSpecificCondition)