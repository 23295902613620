import React, { Component, Fragment } from 'react'
import {
  Form,Nav, TabContent, TabPane, NavItem, Label, Button, FormGroup, CustomInput, Input, Modal, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import plusIcon from '../../../assets/images/plus-icon.png';
import deleteIcon from '../../../assets/images/delete-icon.png';
import http from '../../../httpService/httpService';
import { history } from '../../../index';
import Loading from '../../../components/Loading'
import { ToastContainer,toast } from 'react-toastify';
import config from '../../../config.json';
import { store } from '../../../store.js'
import { verifyLength, verifyEmail ,verifyPassword } from '../../../helper';
import { connect } from 'react-redux';

const initialState = {
  activeTab: '1',
  account : { name:'',email : '', type:'',status:''},
  errors :  { errEmail : '',errName : '',errType : '',errStatus : ''},
  loader : false
};

class UserProfile extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this);
    this.state = initialState;
  }

  async componentDidMount(){

    const token = localStorage.getItem('cccAuthToken');
    await http.get(process.env.REACT_APP_API_URL+'/my-profile',{
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => { 
      const account = response.data.data;
      this.setState( { account });

    })
    .catch((error) => {
      this.setState({ loader:false });   
      if(error.response && error.response.status != 200){
         // toast.error(error.response.data.message);
      }
    });
    
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }  

  handleChange = (e) => {
    const { name , value  } = e.target;
    const account = {...this.state.account};
    account[name]=value;
    this.setState({account });
  }

  //Update user Data

  validate = () => {
    const { account  } = this.state;    
    const errors = {};
    if(account.name == ''){
      errors.errName = 'Name is required field';
    }
     // Email Validation
    if(account.email == ''){
      errors.errEmail = 'Email is required field';
    }else if(!verifyEmail(account.email)){
      errors.errEmail = 'Please enter valid email';
    } 

    this.setState({ errors });

    if(Object.keys(errors).length > 0){
      Object.keys(errors).map(c => toast.error(errors[c]) )
    }    
    return Object.keys(errors).length === 0 ? null : errors;
  }

  handleSubmit = e => {

    e.preventDefault();
    const { account,userList } = this.state;

    let email =account.email; 
    let name = account.name; 
    
    if(this.validate() === null){
        this.setState({ loader:true });
        const token = localStorage.getItem('cccAuthToken');
        http.post(process.env.REACT_APP_API_URL+'/update-profile', { email,name},{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => {  
          toast.success(response.data.message);         
          this.setState({ loader:false });
          this.props.setUserProfiledata(response.data.data);

        })
        .catch((error) => {
          this.setState({ loader:false });   
          if(error.response && error.response.status != 200){
             toast.error(error.response.data.message);
          }
        });
    }
   
  }

  render() {
    const { account,loader } = this.state;
    return (
      <Fragment>
        {loader && <Loading />}
        <TabPane tabId="1">
        <Form method="post" onSubmit= { this.handleSubmit } id='editUser'>
          <ul className="common-input-list common-inputs">
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>User name</Label>
                <Input type="text" value={ account.name } name='name' onChange={(e) => { this.handleChange(e) }} />
              </FormGroup>
            </li>
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>email address</Label>
                <Input type="email" value={ account.email } name='email' onChange={(e) => { this.handleChange(e) }} />
              </FormGroup>
            </li> 
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>type</Label>
                <Input type="text" defaultValue={ account.type } name='type'  disabled  />
              </FormGroup>
            </li>  
            <li>
              <FormGroup className="d-flex justify-content-between align-items-center">
                <Label>Status</Label>
                <Input type="text" disabled defaultValue={ account.status == 'y' ? 'Active' : 'Inactive' } name='status' />
              </FormGroup>
            </li>     
          </ul>         
          <div className="text-right">
            <Button type="submit" className="line-btn">update</Button>
          </div>
         </Form>
        </TabPane>
      </Fragment >
    )
  }
}

const mapDispatchToProps = (dispatch) =>{
  return {
    setUserProfiledata:(data) => { dispatch({ type:'USER_PROFILE' , payload: { data: data } } ) }
  }
}

export default connect(null,mapDispatchToProps)(UserProfile)
