import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import filterIcon from '../assets/images/filter-icon.png';
import editIcon from '../assets/images/edit-icon.png';
import { connect } from 'react-redux';

class Sidebar extends Component {
  render() {    
    return (
      <Fragment>
        <section className="sidebar">
          <ul className="mb-0">
            <li>
              <NavLink to={`${process.env.PUBLIC_URL}/comparison`} className="filter-item" onClick={() => this.props.onOpen('filter') }><img src={filterIcon} alt="filter" /></NavLink>
            </li>
            { this.props.userData.type == 'admin' ?
            <li>
              <NavLink to={`${process.env.PUBLIC_URL}/comparison`} className="edit-item" onClick={() => this.props.onOpen('editItem') }><img src={editIcon} alt="edit" /></NavLink>
            </li> : null
            }
          </ul>
        </section>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userData: state.auth,  
})
export default connect(mapStateToProps)(Sidebar)
