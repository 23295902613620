import React, { Component, Fragment } from 'react'
import Header from '../../components/Header'
import { Link } from 'react-router-dom'
import classnames from 'classnames';
import { Button, FormGroup, Label, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import closeIcon from '../../assets/images/close-icon.png';
import rightIcon from '../../assets/images/right-round-icon.png';
import fileIcon from '../../assets/images/file-icon.png';
import Loading from '../../components/Loading'
import http from '../../httpService/httpService';
import { ToastContainer,toast } from 'react-toastify';
import { Scrollbars } from 'react-custom-scrollbars';


class CSV extends Component {
	constructor(props) {
		super(props)
		this.toggleStep1 = this.toggleStep1.bind(this);
		this.toggleStep2 = this.toggleStep2.bind(this);
		this.toggleStep3 = this.toggleStep3.bind(this);
		this.toggleStep4 = this.toggleStep4.bind(this);
		this.toggleStep5 = this.toggleStep5.bind(this);
		this.state = {
			isStep1: false,
			isStep2: false,
			isStep3: false,
			isStep4: false,
			isStep5: false,
			isStep1Completed : false,
			isStep2Completed : false,
			loader:false,
			csvfields:[],
			filename : '',
			record: { lead_type:'',record_status:'',source:'',channel:'',assigned_agent:'',campaign:'',csv_id:'',customers:[],advising_firm:''},
			file:false,
			noc:0,
			custData : [],
			validatemsg : '2/3 records succesful',
			isStepLast:false,
			csv_id : ''
		}
	}
	toggleStep1() {
		this.setState({ 
			isStep1: true, 
			isStep2: false, 
			isStep3: false, 
			isStep4: false 
		});
	};
	toggleStep2() {
		if(this.state.isStep1Completed == true ){
			this.setState({ 
				isStep1: false, 
				isStep2: true, 
				isStep3: false, 
				isStep4: false,
				isStepLast: false, 
			});
		}		
	};
	toggleStep3() {
		if(this.state.isStep1Completed == true){
			this.setState({ 
				isStep1: false, 
				isStep2: false, 
				isStep3: true, 
				isStep4: false,
				isStepLast: false, 
			});
		}
	};
	async toggleStep4() {
		if(this.state.isStep1Completed == true){
			if(this.validateSelection() === null){

				//send api call for backend
				this.setState({ loader : true })
				const data = this.state.record
				const token = localStorage.getItem('cccAuthToken');
				await http.post(process.env.REACT_APP_API_URL + '/validate-customers', { ...data }, {
					headers: {
						'Authorization': `Bearer ${token}`
					}
				})
				.then((response) => {
					
					this.setState({
						loader : false,
						isStep1: false, 
						isStep2: false,
						isStep2Completed: true,
						isStep3Completed: true,
						custData :  response.data.data,
						validatemsg :  response.data.validatemsg
					});

					if(response.data.total > 0 ){
						if(response.data.passed > 0 ){
							toast.success(response.data.message);
							this.setState({ 
								isStep4: true,
								isStepLast: true,
								isStep3: false,	 
							});
						}else{
							toast.error('Data already exists.');
							this.setState({ 
								isStepLast: true,
								isStep4: false,
								isStep3: false,	 
							});
						}
						
					}else{
						toast.error('Invalid data in csv for customer.');
						this.setState({ 
							isStep4: false,
							isStepLast: false, 
						});
					}
				}).catch(error => {
					this.setState({ loader : false })
			        if(error.response && error.response.status != 200){
			            toast.error(error.response.data.message);
			        }
			    });
				
			}			
		}
	};

	async toggleStep5() {
		if(this.state.isStep4 == true){
			if(this.validateSelection() === null){

				//send api call for backend
				this.setState({ loader : true })
				const data = this.state.record
				const token = localStorage.getItem('cccAuthToken');
				await http.post(process.env.REACT_APP_API_URL + '/import-customers', { ...data }, {
					headers: {
						'Authorization': `Bearer ${token}`
					}
				})
				.then((response) => {

					this.setState({ 
						isStep1: false, 
						isStep2: false, 
						isStep3: false, 
						isStep4: false,						
						isStepLast: false,
						loader : false,
						isStep1Completed : false,
						isStep2Completed : false,
						isStep3Completed : false,
						record : { lead_type:'',record_status:'',source:'',channel:'',assigned_agent:'',campaign:'',csv_id:'',customers:[],advising_firm:''},
						filename : '',
						file:false,
						noc:0,
						custData : [],
						validatemsg : ''	
					});

					toast.success(response.data.message);
					setTimeout(function(){ window.location.reload(); }, 3000);
									
				}).catch(error => {
					this.setState({ loader : false })
			        if(error.response && error.response.status != 200){
			             toast.error(error.response.data.message);
			        }
			    });
				
			}
		}
	};

	handleChange = (e) => {
		if(e.target.name == 'file' && e.target.files && e.target.files[0] !== undefined){
			const token = localStorage.getItem('cccAuthToken'); 
			var formData = new FormData();
	  		formData.append("csv", e.target.files[0]);
			this.setState({ loader:true });
	        
	        const filename = e.target.files[0].name;
	        http.post(process.env.REACT_APP_API_URL+'/uploaded-csv-headers', formData,{
	          headers: {
	            'Authorization': `Bearer ${token}`
	          }
	        })
	        .then((response) => { 
	        	const record = { lead_type:'',record_status:'',source:'',channel:'',assigned_agent:'',campaign:'',csv_id:'',customers:[],advising_firm:''}
	        	record['csv_id'] = response.data.csv_id 
	        	this.setState({ 
	        		loader:false,
	        		csvfields:response.data.data,
	        		filename: filename,
	        		file:true,
	        		record:record,
					isStep2: false, 
					isStep3: false, 
					isStep4: false,
					isStep5: false,
					isStepLast: false, 
					csv_id: response.data.csv_id, 
	        	});	        	
	        	if(this.state.noc > 0){
	        		
		    		let customers = [];
		    		for(let i=0; i<this.state.noc; i++){
		    			customers.push({ title:'',first_name:'',last_name:'',dob:''})				
		    		}
		    		record['customers'] = customers;
	        		this.setState({ isStep1Completed:true,record:record});
	        		this.toggleStep2();
	        	}

	        	var x=document.getElementsByClassName("default-text");  // Find the elements
			    for(var i = 0; i < x.length; i++){
			    	x[i].innerText="Select Map file";
			    }
	        })
	        .catch((error) => {
	          this.setState({ loader:false });   
	          if(error.response && error.response.status != 200){
	             toast.error(error.response.data.message);
	          }
	        });
    	}

    	if(e.target.name == 'noc' && e.target.value > 0){
    		const record = { lead_type:'',record_status:'',source:'',channel:'',assigned_agent:'',campaign:'',csv_id:'',customers:[],advising_firm:''};
    		record['csv_id'] = this.state.csv_id
    		let customers = [];
    		for(let i=0; i<e.target.value; i++){
    			customers.push({ title:'',first_name:'',last_name:'',dob:''})				
    		}
    		record['customers'] = customers;
    		this.setState({ noc : e.target.value,record:record})
    		
    		if(this.state.file == true){
        		this.setState({ isStep1Completed:true});
        	}
        	var x=document.getElementsByClassName("default-text");  // Find the elements
		    for(var i = 0; i < x.length; i++){
		    	x[i].innerText="Select Map file";
		    }
    	}
    }

    setText = (e,type) => {
    	// if(e.currentTarget.textContent == 'Select Map file'){
	    // 	this.setState({ [type+"_text"]: 'Select Map file'})
    	// }else{
	    // 	this.setState({ [type+"_text"]: e.currentTarget.textContent})
    	// }
    	this.setState({ [type+"_text"]: e.currentTarget.textContent})
	    const record = this.state.record;
	    record[type]=e.currentTarget.textContent == 'Select Map file' ? '' : e.currentTarget.textContent;
	    this.setState({ record });

	}
	changeCon = (e,type,i) => {
		var val = '';
		if(e.currentTarget.textContent == 'Select Map file'){
	    	val = ''
    	}else{
	    	val = e.currentTarget.textContent
    	}

		let record = this.state.record;
		let customers = record.customers;
		let customer = customers[i];
		customer[type] = val;
		customers[i] = customer;
		record['customers'] = customers;
	    this.setState({ [type+i] : val,record:record})
	   
	}

	// Validate if proper select or not 

	validateSelection = () =>{
		const record = this.state.record;
		const customers = record.customers;
		const errors = {};
	    if(record.lead_type == ''){
	      errors.errlead_type = 'Lead Type is required field';
	    }

	    if(record.source == ''){
	      errors.errsource = 'Source is required field';
	    }

	    if(record.channel == ''){
	      errors.errchannel = 'Channel is required field';
	    }

	    if(record.campaign === ''){
	      errors.errcampaign = 'Campaign is required field';
	    }
	    if(record.advising_firm === ''){
	      errors.erradvisingfirm = 'Advising Firm is required field';
	    }	    
	    customers.map(con =>{
	    	if(con.title === ''){
		      errors.errtitle = 'Title is required field';
		    }
		    if(con.first_name === ''){
		      errors.errfirst_name = 'First name is required field';
		    }
		    if(con.last_name === ''){
		      errors.errlast_name = 'Last name is required field';
		    }
		    if(con.dob === ''){
		      errors.errdob = 'Dob is required field';
		    }
	    })
	    if(Object.keys(errors).length > 0){
	      Object.keys(errors).map(c => toast.error(errors[c]) )
	    }
	    
	    return Object.keys(errors).length === 0 ? null : errors;

	}

	customer = (csvfields) =>{
		let cust = [];
		let noc = this.state.noc > 0 ? this.state.noc : 0;
		for(let i=0; i < noc ; i++){
			cust.push(
				<div className="record-contact">
					<p className="step-head">Contact { i+1 }</p>
					<div className="common-form m-0">
						<FormGroup className="d-flex align-items-center">
							<Label for="leadType">Title</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text  text-uppercase">{ this.state['title'+i]  ? this.state['title'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
									
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'title',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'title',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="firstName">first name</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['first_name'+i]  ? this.state['first_name'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'first_name',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'first_name',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="lastName">last name</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['last_name'+i]  ? this.state['last_name'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'last_name',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1'  onClick={ (e) => this.changeCon(e ,'last_name',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="dob">dob</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['dob'+i]  ? this.state['dob'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'dob',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'dob',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="gender">gender</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['gender'+i]  ? this.state['gender'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'gender',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'gender',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="nationality">nationality</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['nationality'+i]  ? this.state['nationality'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'nationality',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'nationality',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="ukResidencyStatus">uk residency status</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['uk_residency_status'+i]  ? this.state['uk_residency_status'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'uk_residency_status',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'uk_residency_status',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="ukResidentSince">uk resident since</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['uk_resident_since'+i]  ? this.state['uk_resident_since'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'uk_resident_since',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'uk_resident_since',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="personalEmail">personal email</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['personal_email'+i]  ? this.state['personal_email'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'personal_email',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'personal_email',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="workEmail">work email</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['work_email'+i]  ? this.state['work_email'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'work_email',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'work_email',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="mobileTel">mobile telephone number</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['mob_tel'+i]  ? this.state['mob_tel'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'mob_tel',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'mob_tel',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="homeTel">Home telephone number</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['home_tel'+i]  ? this.state['home_tel'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'home_tel',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'home_tel',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="workTel">work telephone number</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['work_tel'+i]  ? this.state['work_tel'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'work_tel',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'work_tel',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="homeAddress1">Home address</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['home_address_1'+i]  ? this.state['home_address_1'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'home_address_1',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'home_address_1',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						{/* <FormGroup className="d-flex align-items-center">
							<Label for="homeAddress2">Home address line 2</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">Select Map file</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={this.changeTitle}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="homeAddress3">Home address line 3</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">Select Map file</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={this.changeTitle}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="homeAddress4">Home address line 4</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">Select Map file</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={this.changeTitle}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup> */}
						<FormGroup className="d-flex align-items-center">
							<Label for="homePostcode">home address postcode</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['home_post_code'+i]  ? this.state['home_post_code'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'home_post_code',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'home_post_code',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="ownerStatus">owner/occupier status</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['occupier_status'+i]  ? this.state['occupier_status'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'occupier_status',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'occupier_status',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="dateMoved">date moved in</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['date_moved'+i]  ? this.state['date_moved'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'date_moved',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'date_moved',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="consentTelephone">Consent to telephone</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['consent_to_telephone'+i]  ? this.state['consent_to_telephone'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'consent_to_telephone',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'consent_to_telephone',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="consentEmail">Consent to Email</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['consent_to_email'+i]  ? this.state['consent_to_email'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'consent_to_email',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'consent_to_email',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="consentMail">Consent to Mail</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['consent_to_mail'+i]  ? this.state['consent_to_mail'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'consent_to_mail',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'consent_to_mail',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
						<FormGroup className="d-flex align-items-center">
							<Label for="consentText">Consent to Text</Label>
							<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
								<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{ this.state['consent_to_text'+i]  ? this.state['consent_to_text'+i] : 'Select Map file'}</DropdownToggle>
								<DropdownMenu>
									<Scrollbars className="scrollable-dropdown"
											                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
											                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
											                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
											                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
									<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'consent_to_text',i)}>Select Map file</DropdownItem>
									{ csvfields.map(fl=>
										<DropdownItem value='file1' onClick={ (e) => this.changeCon(e ,'consent_to_text',i)}>{ fl }</DropdownItem>
									)}
									</Scrollbars>
								</DropdownMenu>
							</UncontrolledDropdown>
						</FormGroup>
					</div>
				</div>
			);
		}
		return cust;
	}
	render() {
		const { custData,csvfields,loader,isStep1, isStep2, isStep3, isStep4,isStep5,validatemsg,isStepLast } = this.state
		return (
			<Fragment>
				{loader && <Loading />}
				<Header onOpen={this.handleOpen} />
				<main className="main-content record-main csv-main d-flex">
					<section className="record-sidebar">
						<h4 className="text-uppercase">BULK REcord creation</h4>
						<div className="item">
							<p className="title d-flex justify-content-between align-items-center">
								<span>STEP 1: Upload a CSV file</span>
								{ this.state.isStep1Completed && <img src={rightIcon} alt="check" /> }
							</p>
							
							<Label for="lastName">Maximum Number of Contacts in record</Label>
							<Input type="number" id="noc" name="noc" value={this.state.noc} onChange={(e) => { this.handleChange(e) }} />
							<Button className={classnames({ 'active': isStep1 })} onClick={this.toggleStep1}>FIND FILE
								<Input type="file" name="file" onChange={ (e) => this.handleChange(e) } accept="csv/*"  />
							</Button>
							{ this.state.filename && <p className="file-name m-0 d-flex align-items-center"><img src={fileIcon} alt="check" />this is a { this.state.filename }</p> }
						</div>
						<div className="item">
							<p className="title d-flex justify-content-between align-items-center">
								<span>STEP 2: Map the CSV fields to record</span>
								{ this.state.isStep2Completed && <img src={rightIcon} alt="check" /> }
							</p>
							<Button className={classnames({ 'active': isStep2 })} onClick={this.toggleStep2}>RECORD/lead details</Button>
							<Button className={classnames({ 'active': isStep3 })} onClick={this.toggleStep3}>contacts personal details</Button>
						</div>
						<div className="item">
							<p className="title d-flex justify-content-between align-items-center">							
								<span>STEP 3: Run validation check</span>
								{ this.state.isStep3Completed && <img src={rightIcon} alt="check" /> }
							</p>
							<Button className={classnames({ 'active': isStep4 })} onClick={this.toggleStep4}>create records</Button>
						</div>
						<div className="item">
							<p className="title d-flex justify-content-between align-items-center">STEP 4: Create new records</p>
							<Button className={classnames({ 'active': isStep5 })} onClick={this.toggleStep5} >CREATE</Button>
						</div>
					</section>
					<section className="record-section d-flex justify-content-center">
						<div className="inner-section">
							<div className={classnames('marketing-section', { 'active': isStep2 })}>
								<p className="">STEP 2: Match the columns in the CSV file to the corresponding record field.</p>
								<div className="record-contact">
									<p className="step-head">RECORD/lead details</p>
									<div className="common-form m-0">
										<FormGroup className="d-flex align-items-center">
											<Label for="leadType">Lead type</Label>
											<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
												<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{this.state.lead_type_text ? this.state.lead_type_text :'Select Map file'}</DropdownToggle>
												<DropdownMenu>
													<Scrollbars className="scrollable-dropdown"
															                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
															                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
															                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
															                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
													<DropdownItem value='file1' onClick={(e) =>this.setText(e,'lead_type')}>Select Map file</DropdownItem>
													{ csvfields.map(fl=>
														<DropdownItem value='file1' onClick={(e) =>this.setText(e,'lead_type')}>{ fl }</DropdownItem>
													)}
													
													</Scrollbars>
												</DropdownMenu>
											</UncontrolledDropdown>
										</FormGroup>
										<FormGroup className="d-flex align-items-center">
											<Label for="recordStatus">Record status</Label>
											<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
												<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{this.state.record_status_text ? this.state.record_status_text :'Select Map file'}</DropdownToggle>
												<DropdownMenu>
													<Scrollbars className="scrollable-dropdown"
															                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
															                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
															                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
															                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
													<DropdownItem value='file1' onClick={(e) =>this.setText(e,'record_status')}>Select Map file</DropdownItem>
													{ csvfields.map(fl=>
														<DropdownItem value='file1' onClick={(e) =>this.setText(e,'record_status')}>{ fl }</DropdownItem>
													)}
													</Scrollbars>
												</DropdownMenu>
											</UncontrolledDropdown>
										</FormGroup>
										{/* <FormGroup className="d-flex align-items-center">
											<Label for="timeReceived">date/time received</Label>
											<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
												<DropdownToggle className="dropdown-toggle">{this.state.record_status_text ? this.state.record_status_text :'Select Map file'}</DropdownToggle>
												<DropdownMenu>
													<Scrollbars className="scrollable-dropdown"
															                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
															                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
															                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
															                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
													{ csvfields.map(fl=>
														<DropdownItem value='file1' onClick={this.changeTitle}>{ fl }</DropdownItem>
													)}
													</Scrollbars>
												</DropdownMenu>
											</UncontrolledDropdown>
										</FormGroup> */} 
										<FormGroup className="d-flex align-items-center">
											<Label for="source">Source</Label>
											<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
												<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{this.state.source_text ? this.state.source_text :'Select Map file'}</DropdownToggle>
												<DropdownMenu>
													<Scrollbars className="scrollable-dropdown"
															                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
															                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
															                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
															                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
													<DropdownItem value='file1' onClick={(e) =>this.setText(e,'source')}>Select Map file</DropdownItem>
													{ csvfields.map(fl=>
														<DropdownItem value='file1' onClick={(e) =>this.setText(e,'source')}>{ fl }</DropdownItem>
													)}
													</Scrollbars>
												</DropdownMenu>
											</UncontrolledDropdown>
										</FormGroup>
										<FormGroup className="d-flex align-items-center">
											<Label for="channel">Channel</Label>
											<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
												<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{this.state.channel_text ? this.state.channel_text :'Select Map file'}</DropdownToggle>
												<DropdownMenu>
													<Scrollbars className="scrollable-dropdown"
															                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
															                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
															                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
															                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
													<DropdownItem value='file1' onClick={(e) =>this.setText(e,'channel')}>Select Map file</DropdownItem>
													{ csvfields.map(fl=>
														<DropdownItem value='file1' onClick={(e) =>this.setText(e,'channel')}>{ fl }</DropdownItem>
													)}
													</Scrollbars>
												</DropdownMenu>
											</UncontrolledDropdown>
										</FormGroup>
										<FormGroup className="d-flex align-items-center">
											<Label for="campaign">Campaign</Label>
											<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
												<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{this.state.campaign_text ? this.state.campaign_text :'Select Map file'}</DropdownToggle>
												<DropdownMenu>
													<Scrollbars className="scrollable-dropdown"
															                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
															                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
															                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
															                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
													<DropdownItem value='file1' onClick={(e) =>this.setText(e,'campaign')}>Select Map file</DropdownItem>
													{ csvfields.map(fl=>
														<DropdownItem value='file1' onClick={(e) =>this.setText(e,'campaign')}>{ fl }</DropdownItem>
													)}
													</Scrollbars>
												</DropdownMenu>
											</UncontrolledDropdown>
										</FormGroup>
										<FormGroup className="d-flex align-items-center">
											<Label for="assignedAgent">assigned agent</Label>
											<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
												<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{this.state.assigned_agent_text ? this.state.assigned_agent_text :'Select Map file'}</DropdownToggle>
												<DropdownMenu>
													<Scrollbars className="scrollable-dropdown"
															                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
															                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
															                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
															                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
													<DropdownItem value='file1' onClick={(e) =>this.setText(e,'assigned_agent')}>Select Map file</DropdownItem>
													{ csvfields.map(fl=>
														<DropdownItem value='file1' onClick={(e) =>this.setText(e,'assigned_agent')}>{ fl }</DropdownItem>
													)}
													</Scrollbars>
												</DropdownMenu>
											</UncontrolledDropdown>
										</FormGroup>
										<FormGroup className="d-flex align-items-center">
											<Label for="assignedAgent">ADVISING FIRM</Label>
											<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
												<DropdownToggle className="dropdown-toggle text-uppercase default-text ">{this.state.advising_firm_text ? this.state.advising_firm_text :'Select Map file'}</DropdownToggle>
												<DropdownMenu>
													<Scrollbars className="scrollable-dropdown"
															                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
															                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
															                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
															                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
													<DropdownItem value='file1' onClick={(e) =>this.setText(e,'advising_firm')}>Select Map file</DropdownItem>
													{ csvfields.map(fl=>
														<DropdownItem value='file1' onClick={(e) =>this.setText(e,'advising_firm')}>{ fl }</DropdownItem>
													)}
													</Scrollbars>
												</DropdownMenu>
											</UncontrolledDropdown>
										</FormGroup>
										{/* <FormGroup className="d-flex align-items-center">
											<Label for="advisingFirm">Advising firm</Label>
											<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
												<DropdownToggle className="dropdown-toggle">{this.state.advising_firm_text ? this.state.advising_firm_text :'Select Map file'}</DropdownToggle>
												<DropdownMenu>
													<Scrollbars className="scrollable-dropdown"
															                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
															                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
															                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
															                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
													{ csvfields.map(fl=>
														<DropdownItem value='file1' onClick={(e) =>this.setText(e,'advising_firm')}>{ fl }</DropdownItem>
													)}
													</Scrollbars>
												</DropdownMenu>
											</UncontrolledDropdown>
										</FormGroup>
										<FormGroup className="d-flex align-items-center">
											<Label for="lastAttempted">last attempted</Label>
											<UncontrolledDropdown setActiveFromChild className="custom-dropdown">
												<DropdownToggle className="dropdown-toggle">Select Map file</DropdownToggle>
												<DropdownMenu>
													<Scrollbars className="scrollable-dropdown"
															                renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
															                renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
															                renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
															                renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
													{ csvfields.map(fl=>
														<DropdownItem value='file1' onClick={this.changeTitle}>{ fl }</DropdownItem>
													)}
													</Scrollbars>
												</DropdownMenu>
											</UncontrolledDropdown>
										</FormGroup> */}
									</div>
								</div>
							</div>
							<div className={classnames('marketing-section', { 'active': isStep3 })}>
								<p className="">STEP 2: Match the columns in the CSV file to the corresponding record field.</p>
								{ this.customer(csvfields) }
							</div>
							<div className={classnames('marketing-section', { 'active': isStepLast })}>
								<p className="">Validation check results <br />{ validatemsg } </p>
								<div className="record-contact">
									<div className="border-head d-flex">
										<p className="step-head">Contact 1</p>
										<p className="step-head">Validation</p>
									</div>
									<div className="common-form m-0">
										{ custData.map(con =>
											<FormGroup className="d-flex align-items-center">
												<Label for="client1">{ con.title +' '+ con.first_name +' '+ con.last_name}</Label>
												<Input type="text" value={ con.exist == false ? 'Passed' : 'Failed (Existing contact match)' } />
											</FormGroup>
										)}									
										
									</div>
								</div>
							</div>
							<div className={classnames('marketing-section', { 'active': isStep5 })}>
								<p className="">Record Imported Sucessfully. </p>
							</div>
						</div>
					</section>
				</main>
			</Fragment>
		)
	}
}

export default CSV