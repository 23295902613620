import React, { Component, Fragment } from 'react'
import {
  Form,Nav, TabContent, TabPane, NavItem, Label, Button, FormGroup, Input, CustomInput, Modal, ModalBody, CardHeader, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, UncontrolledCollapse, Card
} from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import Loading from '../../../components/Loading'
import config from '../../../config.json';
import { ToastContainer,toast } from 'react-toastify';
import http from '../../../httpService/httpService';
import autosize from 'autosize';

class ExtraFeatureCondition extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    toggleEFCPayment: false,
    toggleBtn: '',  
    conditionTitle: 'Further contract features',
    conditionItem : [],
    type:'extra_feature_payment_conditions',
    loader:false,
    cData:{ title:'',event_description:'',gender:'',status:'',id:''},
    errors :  { errTitle : ''},
    totalCon:0,
    token:localStorage.getItem('cccAuthToken'),
    checkedBtn : [],
    extra_feature_condition : [],
    eventcategorytitle: 'Select Category',
    eventCategoryItem: []
  }

   componentWillReceiveProps(nextProps){ 
    if(nextProps.conditions && nextProps.conditions.type == 'FURTHER_LIST'){
      const cc = nextProps.conditions.data;
      const conditionItem = cc.sort((a, b) => a.title.localeCompare(b.title))
      this.setState({ conditionItem });
      autosize(document.querySelectorAll('textarea'));        
    }

    if(nextProps.resetFrm == true ){
         this.setState({
            checkedBtn : [],
            totalCon:0,
            extra_feature_condition : []
         })

        Array.from(document.getElementsByClassName('dev-txt')).forEach(el => el.value = '');
        var inputs = document.querySelectorAll('.dev-check .custom-control-input');
        for (var i = 0; i < inputs.length; i++) {
          inputs[i].checked = false;
        }
    }
  }

  handleEFCClick = (e,id,field) =>{
    let extra_feature_condition  = [];
    if(field == 'efc'){
     
      if(e.target.checked == true){
        extra_feature_condition = this.state.extra_feature_condition.concat({ fid : id});
        this.setState({ extra_feature_condition });
      }else{
        const ofl = this.state.extra_feature_condition;
        extra_feature_condition = ofl.filter(fl => fl.fid != id);
        this.setState({ extra_feature_condition });
      }
      
    }

    if(field == 'prc'){
      extra_feature_condition = this.state.extra_feature_condition;
      const apc = extra_feature_condition.find(fl => fl.fid == id); 

      if(apc){
        const index = extra_feature_condition.indexOf(apc);
        extra_feature_condition[index]={...apc,benefit_reduced : e.target.checked == true ? 'y' :'n' };
        this.setState({ extra_feature_condition });
      }    
      
    }

    this.props.setExtraFeatureData(extra_feature_condition);
  }

   handleEFCChange = (e,id) =>{

    const { name , value  } = e.target;
    const extra_feature_condition = this.state.extra_feature_condition;
    const fpc = extra_feature_condition.find(fl => fl.fid == id);  

    if(fpc){

      const index = extra_feature_condition.indexOf(fpc);
      extra_feature_condition[index][name] = value;
      this.setState({extra_feature_condition });
      this.props.setExtraFeatureData(extra_feature_condition);

    }
    

  }

  handleChange = (e) => {
    const { name , value  } = e.target;
    const cData = {...this.state.cData};
    cData[name]=value;
    this.setState({cData });
  }

  toggleAddConditionModal = () => {
    this.setState({
      addConditionModal: !this.state.addConditionModal
    });
    this.setState({ errors : { errTitle : ''} });
    this.setState({ 
      cData:{ title:'',id:''},
      eventcategorytitle: 'Select Category',
      gendertitle : 'Select Gender'
    });
  }

  async componentDidMount(){
    autosize(document.querySelectorAll('textarea'));    
  }

  validateCreateCondition = () => {
    const { cData  } = this.state;    
    const errors = {};

    
    if (!cData.title) {
      errors.errTitle = 'The condition needs a name';
    }
    
    if (!cData.gender) {
      errors.errGender = 'Please select gender Specific';
    }
    if (!cData.event_description) {
      errors.errEventDescription = 'Please enter event description';
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0 ? null : errors;
  }

  handleCreateCondition = e => {
    e.preventDefault();
    const { cData } = this.state;

    let title = cData.title;
    let gender = cData.gender;
    let event_description = cData.event_description;
    let type = this.state.type;

    if(this.validateCreateCondition() === null){
      this.setState({ loader:true });      
      http.post(process.env.REACT_APP_API_URL+'/create-condition', { title,gender,event_description, type },{
        headers: {
          'Authorization': `Bearer ${this.state.token}`
        }
      })
      .then((response) => { 
        this.setState({ loader:false });       
        toast.success(response.data.message);
         const cc = [...this.state.conditionItem,{
            title: response.data.data.title,
            event_description: response.data.data.event_description,
            gender: response.data.data.gender,
            id: response.data.data.id,
            status: response.data.data.status
         }];

        const conditionItem = cc.sort((a, b) => a.title.localeCompare(b.title))
        this.toggleAddConditionModal();
        this.setState({ conditionItem });
        this.props.setCondtionData(conditionItem)
        autosize(document.querySelectorAll('textarea'));
      })
      .catch((error) => {
        this.setState({ loader:false });   
        if(error.response && error.response.status != 200){
           toast.error(error.response.data.message);
        }
      });
    }   
  }

  toggleDiv = (id) => {
    const checkedBtn = this.state.checkedBtn;
    if(this.state.toggleBtn == id){
      this.setState({ toggleBtn: ''})
    }else{
      if(checkedBtn.includes(id)){
         this.setState({ toggleBtn: id})
       } 
    }
      
  }

  handleChecked = (id) => {
    const checkedBtn = this.state.checkedBtn;
    if(checkedBtn.includes(id)){
      const filteredBtn= checkedBtn.filter(item => item !== id)
      this.setState({ checkedBtn: filteredBtn })

      const totalCon = this.state.totalCon - 1;
      this.setState({ totalCon });

    }else{
      const filteredBtn = [...this.state.checkedBtn,id];
      this.setState({ checkedBtn: filteredBtn })

      const totalCon = this.state.totalCon + 1;
      this.setState({ totalCon });
    }    
  }

  handleChangeCategory = (e) => {
    const { value } = e.target;
    const cData = { ...this.state.cData };
    cData['event_category_id'] = value;
    this.setState({ cData });
    this.setState({ 'eventcategorytitle' : e.target.textContent })
  }

   handleChangeGender = (e) => {
    const { value } = e.target;
    const cData = { ...this.state.cData };
    cData['gender'] = value;
    this.setState({ cData });
    this.setState({ 'gendertitle' : value })
  }


  render() {    
    const { eventCategoryItem,eventcategorytitle,checkedBtn,conditionItem,totalCon,loader,errors,toggleEFCPayment,toggleBtn } = this.state
    return (
      <Fragment>
         {loader && <Loading />}
         <Card>
          <CardHeader>
            <Button id="toggleEFCPayment" className={classnames('card-link', { 'active': toggleEFCPayment })} onClick={() => { this.setState({ toggleEFCPayment: !toggleEFCPayment }) }}>{ this.state.conditionTitle} ({ totalCon })</Button>
          </CardHeader>
          <UncontrolledCollapse toggler="#toggleEFCPayment" className="inner-collapse">
            <div className="sub-title d-flex justify-content-end align-items-center">
              <Button onClick={this.toggleAddConditionModal}>Add contract features to database</Button>
            </div>
            <Scrollbars className={classnames('scrollable-inner-accordion', { 'long-scroll': false })}
              renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
              renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
              renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
              renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
              <div id="newChildSpecificAccordion1" className="inner-accordion">
                { conditionItem.map(cl =>
                <Card key={cl.id}  className={classnames({ 'active': (toggleBtn === cl.id ? true :false) })}>
                  <CardHeader>
                    <CustomInput type="checkbox" onClick={ (e) => { this.handleChecked(cl.id); this.handleEFCClick(e,cl.id,'efc') } }  checked = { (checkedBtn.includes(cl.id)? true :false) } id={`newEFCHead1-`+cl.id} />
                    <Button id={`toggleEFCPayment-`+cl.id} className={classnames('card-link', { 'active': (toggleBtn === cl.id ? true :false) })} onClick={() => this.toggleDiv(cl.id)}>{ cl.title }</Button>
                  </CardHeader>
                  <UncontrolledCollapse isOpen={ (toggleBtn === cl.id ? true :false) }  toggler={`#toggleEFCPayment-`+cl.id}>
                    <ul className="common-input-list mb-0">                      
                      
                      <li className="d-flex justify-content-between align-items-center">
                        <Label>MAXIMUM CLAIM AMOUNT(£)</Label>
                        <Input type="text" className='dev-txt' name='max_claim_amount_pound' onChange={ (e) => this.handleEFCChange(e,cl.id) }/>
                      </li>                      
                      <li className="d-flex justify-content-between align-items-center">
                        <Label className="form-check-label" htmlFor={`newEfchk2-`+cl.id} >
                          Plan benefit reduced
                          </Label>
                        <CustomInput className='dev-check' type="checkbox" name='benefit_reduced' onClick={ (e) => this.handleEFCClick(e,cl.id,'prc') } id={`newEfchk2-`+cl.id}  />
                      </li>
                      <li className="definition-input">
                        <Label>condition definition</Label>
                        <Scrollbars className="scrollable-textarea"
                          renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                          renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                          renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                          renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                          <Input type="textarea" className='dev-txt' name='defination' onChange={ (e) => this.handleEFCChange(e,cl.id) } placeholder="You can write condition definition here...." />
                        </Scrollbars>
                      </li>
                    </ul>
                  </UncontrolledCollapse>
                </Card> 
                )}                      
              </div>
            </Scrollbars>
          </UncontrolledCollapse>
        </Card>            
        <Modal isOpen={this.state.addConditionModal} toggle={this.toggleAddConditionModal}>
          <ModalBody>
            {loader && <Loading />}
            <Form method="post" onSubmit= { this.handleCreateCondition } id='createCondition'>
              <div className="action-button d-flex justify-content-end">
                <Button type="button" onClick={this.toggleAddConditionModal }>Abandon</Button>
                <Button type="submit">SAVE & CLOSE</Button>
              </div>
              <div className="modal-body">
                <FormGroup>
                  <Label for="conditionName"><em>Condition name</em></Label>
                  <Input type="text" id="title" name='title' onChange={(e) => { this.handleChange(e) }}/>
                  { errors.errTitle && <p className="error-text"> <em> { errors.errTitle } </em></p> }
                </FormGroup>
                  <FormGroup>
                    <Label for="eventCat"><em>Gender Specific</em></Label>
                    <UncontrolledDropdown setActiveFromChild className="custom-dropdown">
                      <DropdownToggle className="dropdown-toggle d-flex justify-content-between align-items-center">{ this.state.gendertitle ?? 'Select Gender'}</DropdownToggle>
                      <DropdownMenu>
                        <Scrollbars className="scrollable-dropdown"
                          renderTrackHorizontal={props => <div {...props} className="scroll-track track-horizontal" />}
                          renderTrackVertical={props => <div {...props} className="scroll-track track-vertical" />}
                          renderThumbHorizontal={props => <div {...props} className="scroll-thumb thumb-horizontal" />}
                          renderThumbVertical={props => <div {...props} className="scroll-thumb thumb-vertical" />} >
                        
                          <DropdownItem value='Male' onClick={(e) => { this.handleChangeGender(e) }}>Male</DropdownItem>
                          <DropdownItem value='Female' onClick={(e) => { this.handleChangeGender(e) }}>Female</DropdownItem>
                          <DropdownItem value='All' onClick={(e) => { this.handleChangeGender(e) }}>All</DropdownItem>
                          
                        </Scrollbars>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {errors.errGender && <p className="error-text"> <em> {errors.errGender} </em></p>}
                  </FormGroup>

                  <FormGroup>
                    <Label for="eventDesc"><em>Event description</em></Label>
                    <Input type="textarea" name="event_description" onChange={(e) => { this.handleChange(e) }} id="EventDescription" />
                    {errors.errEventDescription && <p className="error-text"> <em> {errors.errEventDescription} </em></p>}
                  </FormGroup>

              </div>
            </Form>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    setExtraFeatureData:(data) => { dispatch({ type: 'SET_EXTRAFEATURE_DATA', payload:{ data : data } }) },
    setCondtionData:(data) => {dispatch({ type: 'FURTHER_LIST', payload:{ data : data } }) }
  }
}
const mapStateToProps = state => ({
  conditions: state.conditions
})
export default connect(mapStateToProps,mapDispatchToProps)(ExtraFeatureCondition)