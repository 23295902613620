import axios from 'axios';
import { toast } from 'react-toastify';
import { history } from '../index'

axios.interceptors.response.use(null, error=>{

	if(error.response && error.response.status == 401 ){
		toast.error('Token Expire! Please login again');  
		localStorage.removeItem('cccAuthToken')
		window.location.href = process.env.PUBLIC_URL+'/login'
		// history.push(process.env.PUBLIC_URL+'/login');
	}

	if(error.response && error.response.status != 200){
		return Promise.reject(error);	
	}
	
	if (!error.response) {
    	toast.error('Error: Network Error'); 
    	return Promise.reject(error); 
    } 
	
	toast.error('server error');  
});

export default {
	get: axios.get,
	post: axios.post,
	put: axios.put,
	delete: axios.delete,
}

